<section>
    <div class="wrapper">
        <app-header [screenNameValue]="ScreenName" (addClassEvent)="addClassInDashboard($event)"></app-header>
        <main class="main-content p-4" id="maintoggledrop" #maintoggledrop>
            <!-- Tabs -->
            <section [hidden]="norole">
                <ngx-spinner size="medium" [type]="typeSelected">
                    <p style="color: white">{{percentageUploadMessage}} {{percentageMessage}}</p>
                </ngx-spinner>
                <div class="tab">
                    <ul class="nav nav-pills mb-3 mb-lg-4" id="pills-tab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button id="mylettertoggle" #mylettertoggle class="nav-link active" data-bs-toggle="pill"
                                data-bs-target="#pills-my-letter" (click)="getLetterList('myLetter')" type="button"
                                role="tab" aria-controls="pills-my-letter" aria-selected="true">My Letters</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button id="alllettertoggle" #alllettertoggle (click)="getLetterList('allLetter')"
                                class="nav-link rounded-top-start-0" data-bs-toggle="pill"
                                data-bs-target="#pills-all-letter" type="button" role="tab"
                                aria-controls="pills-all-letter" aria-selected="false">All Letters</button>
                        </li>
                        <button type="button" [hidden]="IsBulkLetter" [routerLink]="['/bulkletterdashboard']"
                            class="btn p-btn mt-3 mt-md-0 ms-md-auto">Bulk
                            Letter Generation</button>

                    </ul>
                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="pills-my-letter" role="tabpanel"
                            aria-labelledby="pills-all-letter" tabindex="0">
                            <div class="my-letter mb-4 px-4 py-3 bg-white rounded">
                                <div class="accordion accordion-flush" id="search-field">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header">
                                            <button id="collapseButton" #collapseButton
                                                class="accordion-button collapsed shadow-none p-0" type="button"
                                                data-toggle="collapse" data-target="#collapseOne" aria-expanded="false"
                                                aria-controls="collapseOne" (click)="collapseFilter()">
                                                <span class="fs-16 fw-semibold highlight">Search or Filter</span>
                                            </button>
                                        </h2>
                                        <div id="collapseOne" #collapseOne class="accordion-collapse collapse"
                                            data-parent="#search-field">
                                            <div class="accordion-body px-0 pb-0">
                                                <form class="form-horizontal" [formGroup]="SearchFilterForm">
                                                    <!-- flex box  -->
                                                    <div class="search-fields row">
                                                        <div class="col-12 col-md-6 col-lg-3 mb-2">
                                                            <div class="gird-item">
                                                                <label for="" class="label-color mb-1">Engagement Letter
                                                                    ID</label>
                                                                <ng-select [searchable]="true"
                                                                    formControlName="EngagementLetterId"
                                                                    [clearable]="false" [items]="EngagementLetterIds"
                                                                    bindValue="EngagementLetterId"
                                                                    [virtualScroll]="true"
                                                                    bindLabel="EngagementLetterId" placeholder="Select Engagement Letter
                                                                ID">
                                                                </ng-select>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-3 mb-2">
                                                            <div class="gird-item">
                                                                <label for="" class="label-color mb-1">Engagement Letter
                                                                    Name</label>
                                                                <ng-select [searchable]="true"
                                                                    formControlName="EngagementLetterName"
                                                                    [clearable]="false" [items]="engageLetterName"
                                                                    bindValue="EngagementLetterName"
                                                                    [virtualScroll]="true"
                                                                    bindLabel="EngagementLetterName" placeholder="Select Engagement Letter
                                                                Name" focusOnce>
                                                                </ng-select>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-3 mb-2">
                                                            <div class="gird-item">
                                                                <label for="" class="label-color mb-1">Office</label>
                                                                <ng-select [searchable]="true" formControlName="Office"
                                                                    [clearable]="false" [items]="officeName"
                                                                    bindValue="OfficeName" [virtualScroll]="true"
                                                                    bindLabel="OfficeName" placeholder="Select Office"
                                                                    focusOnce>
                                                                </ng-select>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-3 mb-2">
                                                            <div class="gird-item">
                                                                <label for="" class="label-color mb-1">Tax Year</label>
                                                                <ng-select [searchable]="true" formControlName="TaxYear"
                                                                    [clearable]="false" [items]="yearsList"
                                                                    bindValue="TaxYear" [virtualScroll]="true"
                                                                    bindLabel="TaxYear" placeholder="Select Tax Year">
                                                                </ng-select>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-3 mb-2">
                                                            <div class="gird-item">
                                                                <label for="" class="label-color mb-1">Template
                                                                    Name</label>
                                                                <ng-select [searchable]="true"
                                                                    formControlName="TemplateName" [clearable]="false"
                                                                    [items]="templateName" bindValue="TemplateName"
                                                                    [virtualScroll]="true" bindLabel="TemplateName"
                                                                    placeholder="Select Template
                                                                Name">
                                                                </ng-select>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-3 mb-2">
                                                            <div class="gird-item">
                                                                <label for="" class="label-color mb-1">Client
                                                                    ID/Name</label>
                                                                <ng-select [searchable]="true"
                                                                    formControlName="ClientName" [clearable]="false"
                                                                    [items]="clientNames" bindValue="ClientId"
                                                                    [virtualScroll]="true" bindLabel="ClientName"
                                                                    placeholder="Select Client
                                                            ID/Name">
                                                                </ng-select>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-3 mb-2">
                                                            <div class="gird-item">
                                                                <label for="" class="label-color mb-1">Partner
                                                                    (PIC)</label>
                                                                <ng-select [searchable]="true" formControlName="Partner"
                                                                    [clearable]="false" [items]="engagementPartner"
                                                                    [virtualScroll]="true" bindLabel="PartnerName"
                                                                    bindValue="PartnerName" placeholder="Select Partner"
                                                                    focusOnce>
                                                                </ng-select>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-3 mb-2">
                                                            <div class="gird-item">
                                                                <label for=""
                                                                    class="label-color mb-1">Department</label>
                                                                <ng-select [searchable]="true"
                                                                    formControlName="DepartmentId" [clearable]="false"
                                                                    bindValue="DepartmentId" [items]="departmentList"
                                                                    [virtualScroll]="true" bindLabel="Department"
                                                                    placeholder="Select Department">
                                                                </ng-select>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-3 mb-2">
                                                            <div class="gird-item">
                                                                <label for="" class="label-color mb-1">Type</label>
                                                                <ng-select [searchable]="true" formControlName="TypeId"
                                                                    [clearable]="false" bindValue="EngageTypeId"
                                                                    [items]="typeList" [virtualScroll]="true"
                                                                    bindLabel="EngageTypeName"
                                                                    placeholder="Select Type">
                                                                </ng-select>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-3 mb-2">
                                                            <div class="gird-item">
                                                                <label for="" class="label-color mb-1">Admin</label>
                                                                <ng-select [searchable]="true" formControlName="Admin"
                                                                    [clearable]="false" bindValue="AdminName"
                                                                    [items]="adminList" [virtualScroll]="true"
                                                                    bindLabel="AdminName" placeholder="Select Admin">
                                                                </ng-select>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-3 mb-2">
                                                            <div class="gird-item">
                                                                <label for="" class="label-color mb-1">Status</label>
                                                                <ng-select [searchable]="true" [clearable]="false"
                                                                    formControlName="Status" [items]="StatusList"
                                                                    bindValue="DocumentStatusId" [virtualScroll]="true"
                                                                    bindLabel="Status" placeholder="Select Status">
                                                                </ng-select>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <!-- Grid -->
                                                    <div class="search-fields">












                                                    </div>
                                                    <!--  -->
                                                    <div class="text-md-end mt-2 mt-md-4">
                                                        <button (click)="onClear()" type="button"
                                                            class="btn p-btn-outline ">Clear</button>
                                                        <button (click)="Search()" type="button"
                                                            class="btn p-btn ms-md-3"
                                                            [disabled]="isSearchBtnDisabled">Search</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="engagement-letter bg-white px-4 py-3 rounded">
                                <h5 class="highlight mb-lg-3 fs-16 fw-semibold">Engagement Letters</h5>
                                <!--data table -->
                                <div class="table-responsive position-relative engagedash-table">
                                    <p-table #dt [value]="this.myLetterList" styleClass="p-datatable-gridlines"
                                        dataKey="id" [rows]="10" [resizableColumns]="true" sortMode="multiple"
                                        [scrollable]="true" scrollHeight="1000px" [showCurrentPageReport]="true"
                                        [paginator]="true" [(first)]="firstpage"
                                        currentPageReportTemplate="{first} to {last} of {totalRecords}">
                                        <ng-template pTemplate="colgroup">
                                            <colgroup>
                                                <col style="width:48%">

                                                <col style="width:47%">
                                                <col style="width:61%">
                                                <col style="width:38%">
                                                <col style="width:35%">
                                                <col style="width:70%">
                                                <col style="width:57%">
                                                <col style="width:44%">
                                                <col style="width:40%">
                                                <col style="width:40%">
                                                <col style="width:30%">

                                            </colgroup>
                                        </ng-template>
                                        <ng-template pTemplate="header">
                                            <tr>


                                                <th pResizableColumn pSortableColumn="BatchId">Batch ID<p-sortIcon
                                                        field="BatchId">
                                                    </p-sortIcon>
                                                </th>
                                                <th pResizableColumn pSortableColumn="EngagementLetterId">Letter
                                                    ID<p-sortIcon field="EngagementLetterId">
                                                    </p-sortIcon>
                                                </th>
                                                <th pResizableColumn pSortableColumn="EngagementLetterName">Letter
                                                    Name<p-sortIcon field="EngagementLetterName">
                                                    </p-sortIcon>
                                                </th>
                                                <th pResizableColumn pSortableColumn="OfficeName">Office<p-sortIcon
                                                        field="OfficeName">
                                                    </p-sortIcon>
                                                </th>
                                                <th pResizableColumn pSortableColumn="TaxYear">Year<p-sortIcon
                                                        field="TaxYear">
                                                    </p-sortIcon>
                                                </th>
                                                <th pResizableColumn pSortableColumn="TemplateName">Template
                                                    Name<p-sortIcon field="TemplateName">
                                                    </p-sortIcon>
                                                </th>

                                                <th pResizableColumn pSortableColumn="ClientName">Client Name<p-sortIcon
                                                        field="ClientName">
                                                    </p-sortIcon>
                                                </th>


                                                <th pResizableColumn pSortableColumn="PartnerName">Partner
                                                    (PIC)<p-sortIcon field="PartnerName"></p-sortIcon>
                                                </th>

                                                <th pResizableColumn pSortableColumn="AdminName">Admin<p-sortIcon
                                                        field="AdminName">
                                                    </p-sortIcon>
                                                </th>

                                                <th pSortableColumn="DocumentDescription">Status<p-sortIcon
                                                        field="DocumentDescription">
                                                    </p-sortIcon>
                                                </th>
                                                <th>Action</th>


                                            </tr>

                                        </ng-template>
                                        <ng-template pTemplate="body" let-myLetterData let-i="rowIndex">
                                            <tr>
                                                <td>{{myLetterData.BatchId}}</td>
                                                <td>{{myLetterData.EngagementLetterId}}</td>
                                                <td>{{myLetterData.EngagementLetterName}}</td>
                                                <td>{{myLetterData.OfficeName}}
                                                </td>
                                                <td>{{myLetterData.TaxYear}}
                                                </td>
                                                <td>{{myLetterData.TemplateName
                                                    }}</td>
                                                <td>{{myLetterData.ClientName}}</td>
                                                <td>{{myLetterData.PartnerName}}</td>
                                                <td>{{myLetterData.AdminName}}</td>
                                                <td>

                                                    <span class="status invalid" [pTooltip]="tooltipText"
                                                        tooltipPosition="left"
                                                        *ngIf="myLetterData.DocumentDescription === 'Invalid Email Id'"
                                                        style="color:red">{{myLetterData.DocumentDescription}}</span>
                                                    <span class="status invalid" [pTooltip]="tooltipEsigningFailedText"
                                                        tooltipPosition="left"
                                                        *ngIf="myLetterData.DocumentDescription === 'E-Signing Failed, Retrying'"
                                                        style="color:red">{{myLetterData.DocumentDescription}}</span>
                                                    <span
                                                        *ngIf="myLetterData.DocumentDescription !== 'Invalid Email Id' && myLetterData.DocumentDescription !== 'E-Signing Failed, Retrying'"
                                                        class="status DocumentDescription">{{myLetterData.DocumentDescription}}</span>

                                                </td>

                                                <td class="text-center align-middle">
                                                    <div class="custom-icon"
                                                        (click)="showMenu($event, menu, myLetterData.DocumentDescription, myLetterData.EngagementLetterId, myLetterData.ClientName, myLetterData.ClientId, myLetterData.BulkLettersId, myLetterData.SignatoryEmailId, myLetterData.TemplateName)">
                                                        <i class="pi pi-ellipsis-v"></i>
                                                    </div>
                                                    <p-menu #menu [popup]="true"
                                                        [class]="menuClicked ? 'custom-menu' : ''" [model]="items">
                                                    </p-menu>
                                                </td>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="emptymessage">
                                            <tr>
                                                <td colspan="12" style="text-align: center;">No
                                                    Records Found</td>
                                            </tr>
                                        </ng-template>

                                    </p-table>
                                    <div class="d-flex align-items-center justify-content-between flex-wrap excel-btn">
                                        <div>
                                            <button type="button" class="link-color me-3 d-flex align-items-center"
                                                (click)="exportExcel(myletterExportData, 'Engagement Letter Details.xlsx')"><img
                                                    class="me-2" src="./assets/images/document-download.png"
                                                    alt="Download-icon">Export above table
                                            </button>
                                        </div>
                                    </div>

                                </div>


                            </div>

                        </div>
                        <div class="tab-pane fade" id="pills-all-letter" role="tabpanel"
                            aria-labelledby="pills-all-letter" tabindex="0">All letters</div>

                    </div>
                </div>

            </section>
            <!-- Edit field Modal -->
            <div class="modal" tabindex="-1" id="bulk-letter-edit-field" aria-modal="true" role="dialog"
                [ngStyle]="{'display':displayEditField}" [class.shadow-popup]="secondPopupOpen">
                <div class="modal-dialog  modal-dialog-centered modal-dialog-scrollable modal-xl">
                    <div class="modal-content p-4">
                        <div class="modal-header border-0 p-0">
                            <h5 class="modal-title fs-16 highlight fw-semibold m-0">Edit Fields </h5>
                            <button type="button" class="btn-close shadow-none" (click)="editFieldscloseModal()"
                                data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <p><span class="err-color">Note: </span>All the fields are mandatory</p>
                        <div class="modal-body p-0">
                            <form id="staticfieldsform" [formGroup]="StaticFieldsForm">
                                <div class="p-3 border rounded" id="ClientIfovalues">
                                    <div class="row mb-2">
                                        <span class="d-block mb-2 fw-bold">1.Client Details:</span>
                                        <div class="col-12 col-md-6 col-lg-3">
                                            <div class="mb-2">
                                                <label for="exampleFormControlInput1" class="form-label mb-1">Client ID
                                                    <span class="err-color">*</span></label>
                                                <input type="text" autocomplete="off" class="form-control" id="ClientID"
                                                    placeholder="Client ID" name="ClientID" readonly
                                                    [(ngModel)]="this.PartnerValues.ClientID"
                                                    [formControl]="StaticFieldsForm.controls['ClientID']">
                                            </div>
                                        </div>
                                        <div id="mddformbody" #mddformbody class="row">

                                        </div>
                                        <div class="col-12 col-md-6 col-lg-3">
                                            <div class="mb-2">
                                                <label for="exampleFormControlInput1" class="form-label mb-1">Signatory
                                                    Email ID
                                                    <span class="err-color">*</span></label>
                                                <input type="text" autocomplete="off" class="form-control"
                                                    id="SignatoryEmailId" placeholder="Signatory Email ID"
                                                    name="SignatoryEmailId"
                                                    [(ngModel)]="this.PartnerValues.SignatoryEmailId"
                                                    [formControl]="StaticFieldsForm.controls['SignatoryEmailId']">
                                                <ng-container
                                                    *ngFor="let validation of validation_messages.SignatoryEmailId">
                                                    <div class="errorvl"
                                                        *ngIf="StaticFieldsForm.get('SignatoryEmailId').hasError(validation.type) && (StaticFieldsForm.get('SignatoryEmailId').dirty || StaticFieldsForm.get('SignatoryEmailId').touched)">
                                                        <label class="errorcolor" *ngIf="!condition"> {{
                                                            validation.message
                                                            }}</label>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-3" [hidden]="isspouseemailid">
                                            <div class="mb-2">
                                                <label for="exampleFormControlInput1" class="form-label mb-1">Spouse
                                                    Email ID
                                                    <span class="err-color">*</span></label>
                                                <input type="text" autocomplete="off" class="form-control"
                                                    id="SpouseEmailId" placeholder="Spouse Email ID"
                                                    name="SpouseEmailId" [(ngModel)]="this.PartnerValues.SpouseEmailId"
                                                    [formControl]="StaticFieldsForm.controls['SpouseEmailId']">
                                                <!-- <ng-container *ngFor="let validation of validation_messages.OfficeName">
                            <div class="err-color"
                                *ngIf="StaticFieldsForm.get('OfficeName').hasError(validation.type) && (StaticFieldsForm.get('OfficeName').dirty || StaticFieldsForm.get('OfficeName').touched)">
                                <label class="errorcolor "> {{ validation.message }}</label>
                            </div>
                        </ng-container> -->
                                                <ng-container
                                                    *ngFor="let validation of validation_messages.SpouseEmailId">
                                                    <div class="errorvl"
                                                        *ngIf="StaticFieldsForm.get('SpouseEmailId').hasError(validation.type) && (StaticFieldsForm.get('SpouseEmailId').dirty || StaticFieldsForm.get('SpouseEmailId').touched)">
                                                        <label class="errorcolor" *ngIf="!condition"> {{
                                                            validation.message
                                                            }}</label>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <!-- Add title -->
                                        <span class="d-block mb-2 fw-bold">2.Child Entities:</span>
                                        <div class="col-lg-6">
                                            <div class="row">
                                                <div class="col-md-6 col-lg-6">
                                                    <div class="mb-2 mb-md-3 mb-lg-3">
                                                        <label for="exampleFormControlInput1"
                                                            class="form-label mb-1">Child Entities
                                                        </label>
                                                        <input type="text" class="form-control" id="ChildEntities"
                                                            name="ChildEntities"
                                                            [formControl]="StaticFieldsForm.controls['ChildEntities']"
                                                            placeholder="Child Entities">
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-4 d-flex align-items-center">
                                                    <button type="button" #addclientbtn class="btn p-btn mb-2 mb-md-0"
                                                        (click)=" onAddchildEntityOptions()">Add</button>
                                                </div>
                                                <div class="col-lg-8">
                                                    <div class="mb-2 mb-md-3 mb-lg-4">
                                                        <div class="response_tag_wrapper" *ngIf="addEntityOptions!= ''">
                                                            <p *ngFor="let addRes of addEntityOptions; let i=index;"
                                                                class="response_tag">{{addRes}}<a><i class="fa fa-times"
                                                                        (click)=" onRemoveEntityOptions(addRes)"
                                                                        aria-hidden="true"></i></a></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row" id="officedetails">
                                        <span class="d-block mb-2 fw-bold">3.Office Details:</span>

                                        <div class="col-12 col-md-6 col-lg-3">
                                            <div class="mb-2">
                                                <label for="exampleFormControlInput1" class="form-label mb-1">Partner ID
                                                    <span class="err-color">*</span></label>
                                                <input type="text" autocomplete="off" name="PartnerID" id="PartnerID"
                                                    required class="form-control" id="{{this.PartnerFieldId}}"
                                                    placeholder="Partner ID" [(ngModel)]="this.PartnerValues.PartnerId"
                                                    readonly [formControl]="StaticFieldsForm.controls['PartnerID']">
                                                <!-- <ng-container *ngFor="let validation of validation_messages.PartnerID">
                                    <div class="err-color"
                                        *ngIf="StaticFieldsForm.get('PartnerID').hasError(validation.type) && (StaticFieldsForm.get('PartnerID').dirty || StaticFieldsForm.get('PartnerID').touched)">
                                        <label class="errorcolor "> {{ validation.message }}</label>
                                    </div>
                                </ng-container> -->
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-3">
                                            <div class="mb-2 ">
                                                <label for="exampleFormControlInput1" class="form-label mb-1">Partner
                                                    Name
                                                    <span class="err-color">*</span></label>
                                                <input type="text" autocomplete="off" class="form-control"
                                                    name="PartnerName" [(ngModel)]="this.PartnerValues.PartnerName"
                                                    id="{{this.PartnerNameFieldId}}" placeholder="Partner Name"
                                                    [formControl]="StaticFieldsForm.controls['PartnerName']">
                                                <!-- <ng-container *ngFor="let validation of validation_messages.PartnerName">
                                    <div class="err-color"
                                        *ngIf="StaticFieldsForm.get('PartnerName').hasError(validation.type) && (StaticFieldsForm.get('PartnerName').dirty || StaticFieldsForm.get('PartnerName').touched)">
                                        <label class="errorcolor "> {{ validation.message }}</label>
                                    </div>
                                </ng-container> -->
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-3">
                                            <div class="mb-2">
                                                <label for="exampleFormControlInput1" class="form-label mb-1">Office ID
                                                    <span class="err-color">*</span></label>
                                                <input type="text" autocomplete="off" class="form-control"
                                                    id="{{this.OfficeFieldId}}" placeholder="Office ID" name="OfficeID"
                                                    [(ngModel)]="this.PartnerValues.OfficeId" readonly
                                                    [formControl]="StaticFieldsForm.controls['OfficeID']">
                                                <!-- <ng-container *ngFor="let validation of validation_messages.OfficeID">
                                    <div class="err-color"
                                        *ngIf="StaticFieldsForm.get('OfficeID').hasError(validation.type) && (StaticFieldsForm.get('OfficeID').dirty || StaticFieldsForm.get('OfficeID').touched)">
                                        <label class="errorcolor "> {{ validation.message }}</label>
                                    </div>
                                </ng-container> -->
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-3">
                                            <div class="mb-2">
                                                <label for="exampleFormControlInput1" class="form-label mb-1">Office
                                                    Name
                                                    <span class="err-color">*</span></label>
                                                <input type="text" autocomplete="off" class="form-control"
                                                    id="{{this.OfficeNameFieldId}}" placeholder="Office Name"
                                                    name="OfficeName" [(ngModel)]="this.PartnerValues.OfficeName"
                                                    [formControl]="StaticFieldsForm.controls['OfficeName']">
                                                <!-- <ng-container *ngFor="let validation of validation_messages.OfficeName">
                                    <div class="err-color"
                                        *ngIf="StaticFieldsForm.get('OfficeName').hasError(validation.type) && (StaticFieldsForm.get('OfficeName').dirty || StaticFieldsForm.get('OfficeName').touched)">
                                        <label class="errorcolor "> {{ validation.message }}</label>
                                    </div>
                                </ng-container> -->
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-3">
                                            <div class="mb-2">
                                                <label for="exampleFormControlInput1" class="form-label mb-1">Office
                                                    Address
                                                    <span class="err-color">*</span></label>
                                                <input type="text" autocomplete="off" class="form-control"
                                                    id="{{this.OfficeAddressFieldId}}" placeholder="Office Address"
                                                    name="OfficeAddress" [(ngModel)]="this.PartnerValues.Address"
                                                    [formControl]="StaticFieldsForm.controls['OfficeAddress']">
                                                <!-- <ng-container *ngFor="let validation of validation_messages.OfficeAddress">
                                    <div class="err-color"
                                        *ngIf="StaticFieldsForm.get('OfficeAddress').hasError(validation.type) && (StaticFieldsForm.get('OfficeAddress').dirty || StaticFieldsForm.get('OfficeAddress').touched)">
                                        <label class="errorcolor "> {{ validation.message }}</label>
                                    </div>
                                </ng-container> -->
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-3">
                                            <div class="mb-2">
                                                <label for="exampleFormControlInput1" class="form-label mb-1">Office
                                                    City
                                                    <span class="err-color">*</span></label>
                                                <input type="text" autocomplete="off" class="form-control"
                                                    id="{{this.OfficeCityFieldId}}" placeholder="Office City"
                                                    name="OfficeCity" [(ngModel)]="this.PartnerValues.City"
                                                    [formControl]="StaticFieldsForm.controls['OfficeCity']">
                                                <!-- <ng-container *ngFor="let validation of validation_messages.OfficeCity">
                                    <div class="err-color"
                                        *ngIf="StaticFieldsForm.get('OfficeCity').hasError(validation.type) && (StaticFieldsForm.get('OfficeCity').dirty || StaticFieldsForm.get('OfficeCity').touched)">
                                        <label class="errorcolor "> {{ validation.message }}</label>
                                    </div>
                                </ng-container> -->
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-3">
                                            <div class="mb-2">
                                                <label for="exampleFormControlInput1" class="form-label mb-1">Office
                                                    State
                                                    <span class="err-color">*</span></label>
                                                <input type="text" autocomplete="off" class="form-control"
                                                    id="{{this.OfficeStateFieldId}}" placeholder="Office State"
                                                    name="OfficeState" [(ngModel)]="this.PartnerValues.State"
                                                    [formControl]="StaticFieldsForm.controls['OfficeState']">
                                                <!-- <ng-container *ngFor="let validation of validation_messages.OfficeState">
                                    <div class="err-color"
                                        *ngIf="StaticFieldsForm.get('OfficeState').hasError(validation.type) && (StaticFieldsForm.get('OfficeState').dirty || StaticFieldsForm.get('OfficeState').touched)">
                                        <label class="errorcolor "> {{ validation.message }}</label>
                                    </div>
                                </ng-container> -->
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-3">
                                            <div class="mb-2">
                                                <label for="exampleFormControlInput1" class="form-label mb-1">Office Zip
                                                    <span class="err-color">*</span></label>
                                                <input type="text" autocomplete="off" class="form-control"
                                                    id="{{this.OfficeZipCodeFieldId}}" placeholder="Office Zip"
                                                    name="OfficeZip" [(ngModel)]="this.PartnerValues.ZipCode"
                                                    [formControl]="StaticFieldsForm.controls['OfficeZip']">
                                                <!-- <ng-container *ngFor="let validation of validation_messages.OfficeZip">
                                    <div class="err-color"
                                        *ngIf="StaticFieldsForm.get('OfficeZip').hasError(validation.type) && (StaticFieldsForm.get('OfficeZip').dirty || StaticFieldsForm.get('OfficeZip').touched)">
                                        <label class="errorcolor "> {{ validation.message }}</label>
                                    </div>
                                </ng-container> -->
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-3">
                                            <div class="mb-2">
                                                <label for="exampleFormControlInput1" class="form-label mb-1">Office
                                                    Phone
                                                    <span class="err-color">*</span></label>
                                                <input type="text" autocomplete="off" class="form-control"
                                                    id="{{this.OfficePhoneNumberFieldId}}" placeholder="Office Phone"
                                                    name="OfficePhone" [(ngModel)]="this.PartnerValues.PhoneNumber"
                                                    [formControl]="StaticFieldsForm.controls['OfficePhone']">
                                                <!-- <ng-container *ngFor="let validation of validation_messages.OfficePhone">
                                    <div class="err-color"
                                        *ngIf="StaticFieldsForm.get('OfficePhone').hasError(validation.type) && (StaticFieldsForm.get('OfficePhone').dirty || StaticFieldsForm.get('OfficePhone').touched)">
                                        <label class="errorcolor "> {{ validation.message }}</label>
                                    </div>
                                </ng-container> -->
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-3">
                                            <div class="mb-2">
                                                <label for="exampleFormControlInput1"
                                                    class="form-label mb-1">Jurisdiction
                                                    <span class="err-color">*</span></label>
                                                <input type="text" autocomplete="off" class="form-control"
                                                    id="{{this.jurisdictionFieldId}}" placeholder="Jurisdiction"
                                                    name="Jurisdiction" [(ngModel)]="this.PartnerValues.jurisdiction"
                                                    [formControl]="StaticFieldsForm.controls['Jurisdiction']">
                                                <!-- <ng-container *ngFor="let validation of validation_messages.OfficePhone">
                                    <div class="err-color"
                                        *ngIf="StaticFieldsForm.get('OfficePhone').hasError(validation.type) && (StaticFieldsForm.get('OfficePhone').dirty || StaticFieldsForm.get('OfficePhone').touched)">
                                        <label class="errorcolor "> {{ validation.message }}</label>
                                    </div>
                                </ng-container> -->
                                            </div>
                                        </div>
                                        <!-- <div class="col-12 col-md-6 col-lg-4">
                            <div class="mb-2">
                                <label for="exampleFormControlInput1" class="form-label mb-1">Citrin Name
                                    <span class="err-color">*</span></label>
                                <input type="text" autocomplete="off" class="form-control"
                                    id="exampleFormControlInput1" placeholder="Citrin Name" name="CitrinName"
                                    [formControl]="StaticFieldsForm.controls['CitrinName']">
                                <ng-container *ngFor="let validation of validation_messages.CitrinName">
                                    <div class="err-color"
                                        *ngIf="StaticFieldsForm.get('CitrinName').hasError(validation.type) && (StaticFieldsForm.get('CitrinName').dirty || StaticFieldsForm.get('CitrinName').touched)">
                                        <label class="errorcolor "> {{ validation.message }}</label>
                                    </div>
                                </ng-container>
                            </div>
                        </div> -->
                                        <!-- <div class="col-12 col-md-6 col-lg-4">
                            <div class="mb-2">
                                <label for="exampleFormControlInput1" class="form-label mb-1">Citrin Website
                                    <span class="err-color">*</span></label>
                                <input type="text" autocomplete="off" class="form-control"
                                    id="exampleFormControlInput1" placeholder="Citrin Website" name="CitrinWebsite"
                                    [formControl]="StaticFieldsForm.controls['CitrinWebsite']">
                                <ng-container *ngFor="let validation of validation_messages.CitrinWebsite">
                                    <div class="err-color"
                                        *ngIf="StaticFieldsForm.get('CitrinWebsite').hasError(validation.type) && (StaticFieldsForm.get('CitrinWebsite').dirty || StaticFieldsForm.get('CitrinWebsite').touched)">
                                        <label class="errorcolor "> {{ validation.message }}</label>
                                    </div>
                                </ng-container>
                            </div>
                        </div> -->
                                    </div>
                                    <hr>
                                    <div>
                                        <span class="d-block mb-2 fw-bold">4.Other Details:</span>
                                        <div class="row">
                                            <!-- <div class="col-md-6 col-lg-3 mb-2">
                                <label for="" class="control-label mb-1">1.7216 Available?<span
                                        class="err-color">*</span></label>
                                <div class="response_question">
                                    <div class="form-check form-check-inline">
                                        <input type="radio" name="Details7216" formControlName="Details7216"
                                            value="Yes" id="inlineRadio1" class="form-check-input">
                                        <label for="inlineRadio1" class="form-check-label">Yes</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input type="radio" name="Details7216" formControlName="Details7216"
                                            value="No" id="inlineRadio2" class="form-check-input"><label
                                            for="inlineRadio2" class="form-check-label">No</label>
                                    </div>
                                </div>
                            </div> -->
                                            <!-- <div class="col-md-6 col-lg-6 mb-2">
                                <label for="" class="control-label mb-1">2.How Many Client Signatures?<span
                                        class="err-color">*</span></label>
                                <div class="response_question">
                                    <div class="form-check form-check-inline">
                                        <input type="radio" name="ClientSignatures"
                                            formControlName="ClientSignatures" value="1 Client Signatures"
                                            id="inlineRadio3" class="form-check-input">
                                        <label for="inlineRadio3" class="form-check-label">1 Client
                                            Signatures</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input type="radio" name="ClientSignatures"
                                            formControlName="ClientSignatures" value="2 Client Signatures"
                                            id="inlineRadio4" class="form-check-input"><label for="inlineRadio4"
                                            class="form-check-label">2
                                            Client Signatures</label>
                                    </div>
                                </div>
                            </div> -->
                                            <div class="col-12 col-md-6 col-lg-3">
                                                <label for="" class="control-label mb-1">E-Signing?<span
                                                        class="err-color">*</span></label>
                                                <div class="response_question ">
                                                    <div class="form-check form-check-inline">
                                                        <input type="radio" id="inlineRadio5" name="Esigning"
                                                            (change)="onItemChange($event.target.value)"
                                                            [checked]="isEsigningval" value="option1"
                                                            class="form-check-input">
                                                        <label for="inlineRadio5" class="form-check-label">Yes</label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input type="radio" name="Esigning" id="inlineRadio6"
                                                            (change)="onItemChange($event.target.value)"
                                                            [checked]="isEsigning" value="option2"
                                                            class="form-check-input"><label for="inlineRadio6"
                                                            class="form-check-label">No</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 col-lg-3">
                                                <div class="mb-2 ">
                                                    <label for="exampleFormControlInput1" class="form-label mb-1">Return
                                                        Type Code
                                                        <span class="err-color">*</span></label>
                                                    <input type="text" autocomplete="off" class="form-control" id="3"
                                                        placeholder="ReturnTypeCode" name="ReturnTypeCode" readonly
                                                        [formControl]="StaticFieldsForm.controls['ReturnTypeCode']"
                                                        [(ngModel)]="this.returntypecodebind">
                                                    <!-- <ng-container *ngFor="let validation of validation_messages.PartnerName">
                                                        <div class="err-color"
                                                            *ngIf="StaticFieldsForm.get('PartnerName').hasError(validation.type) && (StaticFieldsForm.get('PartnerName').dirty || StaticFieldsForm.get('PartnerName').touched)">
                                                            <label class="errorcolor "> {{ validation.message }}</label>
                                                        </div>
                                                    </ng-container> -->
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="mt-3 mt-md-3 text-end sticky-bottom bg-white">
                                        <button type="button" (click)="editFieldscloseModal()"
                                            class="btn p-btn-outline">Close</button>
                                        <button type="button" (click)="updateEngagementLetterFields()"
                                            class="btn p-btn ms-3">Update</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Delete Modal -->
            <div class="modal" tabindex="-1" id="deleteModal" [ngStyle]="{'display':displayDeleteModal}">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content" id="deleteModal">
                        <div class="modal-header">
                            <h5 class="modal-title fw-semibold highlight">Confirm Delete</h5>
                            <button type="button" (click)="onCloseDeleteModal()" class="btn-close shadow-none"
                                data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <p class="s-text fw-medium mb-0">{{deletePopupMessage}}
                            </p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" (click)="onCloseDeleteModal()" class="btn p-btn-outline"
                                data-bs-dismiss="modal">No</button>
                            <button type="button" (click)="deleteEngagementletterById()"
                                class="btn p-btn ms-2">Yes</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- cancel signing Modal -->
            <div class="modal" tabindex="-1" id="cancelSigningModal" [ngStyle]="{'display':displayCancelSigningModal}">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content" id="cancelSigningModal">
                        <div class="modal-header">
                            <h5 class="modal-title fw-semibold highlight">Confirm Cancel</h5>
                            <button type="button" (click)="onCloseSigningModal()" class="btn-close shadow-none"
                                data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <p class="s-text fw-medium mb-0">{{cancelPopupMessage}}
                            </p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" (click)="onCloseSigningModal()" class="btn p-btn-outline"
                                data-bs-dismiss="modal">No</button>
                            <button type="button" (click)="cancelSigningById()" class="btn p-btn ms-2">Yes</button>
                        </div>
                    </div>
                </div>
            </div>
            <!--upload Modal start here -->
            <!-- <div class="modal" id="upload_doc" [ngStyle]="{'display':displayupload}"
                [class.shadow-popup]="secondPopupOpen">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-body">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="form-horizontal" [formGroup]="uploadForm">
                                        <div class="col-12 col-sm-12 col-sm-12">
                                            <h6 class=""><b>Upload Document</b></h6>

                                            <div class="form-group">
                                                <label class="control-label" for="fieldname">Attachment file</label>
                                                <div>
                                                    <input type="file" #fileInput formControlName="uploadDocument"
                                                        accept=".pdf" (change)="fileChange($event)" class="" /><br>
                                                    <div class="drop-area" (dragover)="dragOver($event)"
                                                        (dragleave)="dragLeave($event)" (drop)="fileDropped($event)"
                                                        accept=".pdf">
                                                        <p>Drag & Drop PDF file here or</p>
                                                        <p>Browse to upload</p>
                                                    </div>
                                                    <div class="image_file_notes"
                                                        style="margin-bottom:21px;padding-top: 10px">
                                                        <p><strong>Note: </strong> PDF file should be less than 10MB in
                                                            file size</p>
                                                        <p>Please contact Helpdesk to upload files more than 10MB</p>
                                                    </div>
                                                    <div *ngIf="selectedFileName">{{ selectedFileName }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <div class="col-sm-12 text-right">
                                        <a (click)="cancelupload()" class="btn btn-third mx-2"
                                            data-dismiss="modal">Cancel</a>
                                        <button (click)="UploadPdfDownload()" [disabled]="pdfSelected"
                                            class="btn btn-secondary mx-2" data-dismiss="modal">Submit</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="modal" id="upload_doc" [ngStyle]="{'display':displayupload}"
                [class.shadow-popup]="secondPopupOpen">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content">
                        <div class="modal-header border-0 pb-0">
                            <h5 class="modal-title fw-semibold highlight">Upload Engagement Letter</h5>
                            <button type=" button" class="btn-close shadow-none" data-bs-dismiss="modal"
                                aria-label="Close" (click)="cancelupload()"></button>
                        </div>
                        <div class="modal-body">
                            <form class="form-horizontal" [formGroup]="uploadForm">
                                <div>
                                    <!-- <h6 class=""><b>Upload Document</b></h6> -->
                                    <div class="form-group">
                                        <!-- <label class="control-label" for="fieldname">Attachment file</label> -->
                                        <div>
                                            <div class="drop-area" (dragover)="dragOver($event)"
                                                (dragleave)="dragLeave($event)" (drop)="fileDropped($event)"
                                                accept=".pdf">
                                                <!-- <p>Drag & Drop PDF file here or</p>
                                                    <p>Browse to upload</p> -->
                                                <img class="mb-2" src="./assets/images/upload_file.png"
                                                    alt="Upload-file">
                                                <p class="fw-semibold fs-5 mb-2">Drag and Drop Letter.</p>
                                                <p class="mb-2" style="color:#939393;font-size: 16px;">or</p>
                                                <div class="file-input-container">
                                                    <button class="btn p-btn">Browse</button>
                                                    <input type="file" #fileInput formControlName="uploadDocument"
                                                        accept=".pdf" (change)="fileChange($event)" id="file-input"
                                                        class="hidden-input" /><br>
                                                    <!-- <input type="file" id="file-input" class="hidden-input"> -->
                                                </div>
                                            </div>
                                            <!-- <div class="image_file_notes"
                                                    style="margin-bottom:21px;padding-top: 10px">
                                                    <p><strong>Note: </strong> PDF file should be less than 10MB
                                                        in
                                                        file size</p>
                                                    <p>Please contact Helpdesk to upload files more than 10MB
                                                    </p>
                                                </div> -->

                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div class="d-flex align-items-center p-3 mt-3 rounded" style="box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10), 0px 0px 2px 0px rgba(0, 0, 0, 0.20);
                        " *ngIf="selectedFileName">
                                <div style="flex-grow: 1;">{{ selectedFileName }}</div>
                                <a (click)="cancelUploadFile()" class="btn p-btn-outline mx-3"
                                    data-dismiss="modal">Cancel</a>
                                <button (click)="UploadPdfDownload()" [disabled]="pdfSelected" class="btn p-btn "
                                    data-dismiss="modal">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- warning Modal -->
            <div class="modal" tabindex="-1" id="warning" [ngStyle]="{'display':displayWarningModal}">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content" id="warning">
                        <div class="modal-header warning-modal">
                            <h5 class="modal-title fw-semibold highlight fs-18 text-white">Warning</h5>
                            <button type="button" (click)="closeWarningModal()" class="btn-close shadow-none"
                                data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body text-center">
                            <img src="./assets/images/warning.png" class="mb-3" alt="warning">
                            <p class="fs-16">{{warningMessage}}</p>
                            <button type="button" (click)="closeWarningModal()" class="warning-btn"
                                data-bs-dismiss="modal">Ok</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Error Modal -->
            <div class="modal" tabindex="-1" id="error" [ngStyle]="{'display':displayErrorModal}">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content" id="error">
                        <div class="modal-header error-modal">
                            <h5 class="modal-title fw-semibold highlight fs-18 text-white">Error</h5>
                            <button type="button" (click)="closeErrorModal()" class="btn-close shadow-none"
                                data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body text-center">
                            <img src="./assets/images/error-icon.png" class="mb-3" alt="warning">
                            <p class="fs-16">{{errorMessage}}</p>
                            <button type="button" (click)="closeErrorModal()" class="error-btn"
                                data-bs-dismiss="modal">Ok</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Preview Error Modal-->
            <div class="modal" tabindex="-1" id="previewerror" [ngStyle]="{'display':displayPreviewErrorModal}">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content" id="previewerror">
                        <div class="modal-header error-modal">
                            <h5 class="modal-title fw-semibold highlight fs-18 text-white">Error</h5>
                            <button type="button" (click)="previewCloseErrorModal()" class="btn-close shadow-none"
                                data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body text-center">
                            <img src="./assets/images/error-icon.png" class="mb-3" alt="warning">
                            <p class="fs-16">{{errorMessage}}</p>
                            <p class="fs-15">{{errorInfo}}</p>
                            <button type="button" (click)="previewCloseErrorModal()" class="error-btn"
                                data-bs-dismiss="modal">Ok</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Edit field Error Modal -->
            <div class="modal" tabindex="-1" id="fielderror" [ngStyle]="{'display':displayFieldErrorModal}">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content" id="fielderror">
                        <div class="modal-header error-modal">
                            <h5 class="modal-title fw-semibold highlight fs-18 text-white">Error</h5>
                            <button type="button" (click)="closeFieldErrorModal()" class="btn-close shadow-none"
                                data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body text-center">
                            <img src="./assets/images/error-icon.png" class="mb-3" alt="warning">
                            <p class="fs-16" style="word-wrap:break-word;text-align: center;">
                                <span *ngIf="this.emptyFieldarr!=''" class="d-block text-center">Please provide input to
                                    mandatory
                                    field(s) - <span style="word-wrap:break-word;">{{emptyFieldarr}}</span>
                                </span><br>
                                <span class="d-block">{{signatorybotherrormessage}}</span>
                                <span class="d-block">{{signatoryspouseerrormessage}}</span>
                                <span class="d-block">{{signatorymailerrormessage}}</span>
                                <span class="d-block">{{feesErrorMessage}}</span>
                            </p>
                            <button type="button" (click)="closeFieldErrorModal()" class="error-btn"
                                data-bs-dismiss="modal">Ok</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Success Modal -->
            <div class="modal" tabindex="-1" id="success" [ngStyle]="{'display':displaySuccessModal}">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content" id="success">
                        <div class="modal-header success-modal">
                            <h5 class="modal-title fw-semibold highlight fs-18 text-white">Success</h5>
                            <button type="button" (click)="closeSuccessModal()" class="btn-close shadow-none"
                                data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body text-center">
                            <img src="./assets/images/success-icon.png" class="mb-3" alt="warning">
                            <p class="fs-16">{{successMessage}}</p>
                            <button type="button" (click)="closeSuccessModal()" class="success-btn"
                                data-bs-dismiss="modal">Ok</button>
                        </div>
                    </div>
                </div>
            </div>
            <!--  Console log error Modal-->
            <div class="modal" tabindex="-1" id="consolelogerror" [ngStyle]="{'display':displayConsoleLogerror}">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content" id="consolelogerror">
                        <div class="modal-header error-modal">
                            <h5 class="modal-title fw-semibold highlight fs-18 text-white">Error</h5>
                            <button type="button" (click)="ondefaultLogError()" class="btn-close shadow-none"
                                data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body text-center">
                            <img src="./assets/images/error-icon.png" class="mb-3" alt="warning">
                            <div class="fs-16">
                                <h5>Sorry, something went wrong.</h5><br>
                                <h5>To continue please logout, close the browser, login again and resume the work on
                                    this letter.</h5>
                                <h5>If any problem occurs again in this letter, please take a full screen screenshot
                                    showing complete browser along with date time and share with IT Support.</h5>
                            </div>
                            <button type="button" (click)="ondefaultLogError()" class="error-btn"
                                data-bs-dismiss="modal">Ok</button>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>

    <!-- Engage history pop up 20/10/2023 -->
    <div class="modal" tabindex="-1" id="engage-history" [ngStyle]="{'display':displayHistorylog}"
        [class.shadow-popup]="secondPopupOpen">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
            <div class="modal-content px-4 py-3">
                <div class="modal-header pt-0 px-0 pb-2">
                    <h5 class="modal-title fs-18 highlight fw-semibold m-0">
                        Engagement Letter History
                    </h5>
                    <button type="button" (click)="historylogClose()" class="btn-close shadow-none"
                        data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body p-0">
                    <div class="pt-3">
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <p class="pe-2 fw-semibold s-text">Batch ID</p>
                                    </td>
                                    <td>
                                        <p class="s-text fw-medium">: {{ historylogBatchId }}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p class="pe-2 fw-semibold s-text">Engagement Letter ID</p>
                                    </td>
                                    <td>
                                        <p class="s-text fw-medium">: {{ historylogEngagementLetterId }}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p class="pe-2 fw-semibold s-text">Engagement Letter Name</p>
                                    </td>
                                    <td>
                                        <p class="s-text fw-medium">: {{ historylogEngagementLetterName }}</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="table-responsive history-log">
                            <p-table #dt [value]="historylogData" dataKey="id" styleClass="p-datatable-gridlines">

                                <!-- #dt [rowHover]="false" [value]="historylogData" dataKey="id"
                                styleClass="p-datatable-gridlines" [resizableColumns]="true" sortMode="multiple"
                                [scrollable]="false" scrollHeight="1000px" [paginator]="true" [rows]="10"
                                [showCurrentPageReport]="true" [paginator]="true"
                                currentPageReportTemplate="{first} to {last} of {totalRecords}"> -->

                                <ng-template pTemplate="colgroup">
                                    <colgroup>
                                        <col style="width:6%">
                                        <col style="width:21%">
                                        <col style="width:15%">
                                        <col style="width:22%">
                                        <col style="width:25%">

                                    </colgroup>
                                </ng-template>


                                <ng-template pTemplate="header">
                                    <tr>
                                        <th pResizableColumn>Ver #
                                        </th>
                                        <th pResizableColumn>Status
                                        </th>
                                        <th pResizableColumn>Actionee Name
                                        </th>

                                        <th pResizableColumn>Datetime
                                        </th>
                                        <th pResizableColumn><span>Action</span>
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-history>
                                    <tr>

                                        <td>{{history.Version}}</td>
                                        <td>{{history.Status}}</td>
                                        <td>{{history.EditedBy}}</td>
                                        <td>{{history.ModifiedOn}}</td>
                                        <td>
                                            <ng-container *ngIf="history.Version === lastVersion">
                                                <a style="color: inherit;" class="text-decoration-none mx-2"
                                                    (click)="openPreviewDownload(history,'preview')"
                                                    *ngIf="history.Status== 'With Client for E-Signing'||'With Client for Manual Signing'||'Completed'||'Fields Edited'||'Cancelled the Signing Request'||'With Admin for Review'||'Client declined the Signing'">Preview</a>
                                                <a style="color: inherit;" class="text-decoration-none mx-2"
                                                    (click)="openPreviewDownload(history,'download')"
                                                    *ngIf="history.Status== 'With Client for E-Signing'||'With Client for Manual Signing'||'Completed'||'Fields Edited'||'Cancelled the Signing Request'||'With Admin for Review'||'Client declined the Signing'">Download</a>
                                                <!-- <a style="color: inherit;" class="text-decoration-none mx-2"
                                                    (click)="InFosigningStatusmodal()"
                                                    *ngIf="history.Status=='With Client For E-Signing'">Info</a> -->
                                            </ng-container>
                                            <a style="color: inherit;" class="text-decoration-none mx-2"
                                                (click)="InFosigningStatusmodal()"
                                                *ngIf="history.Status=='With Client For E-Signing'&&history.Version == latestversioncheck">Info</a>
                                            <ng-container *ngIf="history.Version !== lastVersion">
                                                <a style="color: inherit;" class="text-decoration-none mx-2"
                                                    *ngIf="history.Status=='Client declined the Signing'"
                                                    (click)="declinePopup(history?.ReasonforDecline, history?.DeclineTimestamp, history?.ClientEmailId)">Reason</a>
                                                <!-- <a style="color: inherit;" class="text-decoration-none mx-2"
                                                    *ngIf="history.Status=='With Client for E-Signing'">Info</a> -->
                                            </ng-container>
                                        </td>
                                    </tr>

                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="12" style="text-align: center;">No
                                            fields Found</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </div>
                <div class="modal-footer border-0 px-0 pb-0">
                    <div>
                        <button type="button" (click)="historylogClose()" class="btn p-btn" data-bs-dismiss="close">
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Decline for a Reason-->
    <div class="modal" id="declineReason" [ngStyle]="{'display':displayDeclineReason}">
        <div class="modal-dialog  modal-dialog-centered new_popup">
            <div class="modal-content" id="declineReason">
                <div class="modal-header">
                    <h5 class="modal-title highlight fw-semibold m-0">Reason for Declined
                    </h5>
                    <!-- <button type=" button" class="btn-close shadow-none" data-bs-dismiss="modal"
                        aria-label="Close"></button> -->
                </div>
                <div class="modal-body">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-sm-4"><span class="s-text fw-semibold">Email Id</span></div>
                            <div class="col-sm-8 new_label_structure mb-2">
                                <p><span class="s-text fw-medium">: {{ this.clientEmailId }}</span></p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4"><span class="s-text fw-semibold">Timestamp</span></div>
                            <div class="col-sm-8 new_label_structure mb-2">
                                <p><span class="s-text fw-medium">: {{ this.DeclineTimestamp }}</span></p>
                            </div>
                        </div>
                        <div class="form-outline">
                            <textarea name="ans" [(ngModel)]="this.reasonForDecline" class="form-control"
                                id="textAreaExample1" rows="4" style="border: 1px solid #ced4da;" readonly></textarea>
                            <div class="col-sm-12 text-end mt-3">
                                <a (click)="closeDeclinePopup()" class="btn p-btn" data-toggle="modal"
                                    data-target="#clearletter">Close</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div [innerHTML]="trustedHtml"></div>
</section>

<!-- Resend -->
<div class="modal" tabindex="-1" id="{{resendDynamicId}}" [ngStyle]="{'display':displayResendModal}"
    [class.shadow-popup]="secondPopupOpen">
    <div class="modal-dialog modal-lg modal-dialog-centered upload-attachment">
        <div class="modal-content p-4">
            <div class="modal-header border-0 p-0">
                <h5 class="modal-title fs-16 highlight fw-semibold m-0 mb-md-2">Resend the letter for signing</h5>
                <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"
                    (click)="closeResendPopup()"></button>
            </div>

            <div class="modal-body p-0">
                <form [formGroup]="ResendForm">
                    <div class="p-3 border rounded" id="ClientIfovalues">
                        <div class="col-md-6 col-lg-6 mb-2">
                            <!-- <label for="" class="control-label mb-1">E-Signing?<span class="err-color">*</span></label>
                            <div class="response_question ">
                                <div class="form-check form-check-inline">
                                    <input type="radio" id="inlineRadio5" name="Esigning"
                                        (change)="onItemChange($event.target.value)" value="true"
                                        class="form-check-input" formControlName="eSigning">
                                    <label for="inlineRadio5" class="form-check-label">Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input type="radio" name="Esigning" id="inlineRadio6"
                                        (change)="onItemChange($event.target.value)" value="false"
                                        class="form-check-input"><label for="inlineRadio6" class="form-check-label"
                                        formControlName="eSigning">No</label>
                                </div>
                            </div> -->
                            <div class="col-12 col-md-6 col-lg-4">
                                <div class="mb-2 mb-md-3 mb-lg-4">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">E-Signing?
                                        <span class="err-color">*</span></label>
                                    <div class="d-inline-flex gap-3">
                                        <input id="yes" type="radio" class="custom-control-input" [value]="true"
                                            name="eSigning" formControlName="eSigning"
                                            (change)="onItemChangeResend($event.target.value)">
                                        <label class="custom-control-label" for="yes">Yes</label>
                                        <input id="yes" type="radio" class="custom-control-input" [value]="false"
                                            name="eSigning" formControlName="eSigning"
                                            (change)="onItemChangeResend($event.target.value)">
                                        <label class="custom-control-label" for="yes">No</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <!-- Add title -->
                            <span class="d-block mb-2 fw-bold">Client Details:</span>

                            <div class="row" id="ContentDisable">
                                <div class="col-md-6 col-lg-6">
                                    <div class="mb-2 mb-md-3 mb-lg-4">
                                        <label for="exampleFormControlInput1" class="form-label mb-1">Signatory First
                                            Name<span class="err-color">*</span>
                                        </label>
                                        <input type="text" formControlName="SignatoryFirstName" class="form-control"
                                            id="SignatoryFirstName" name="SignatoryFirstName"
                                            placeholder="Signatory First Name">
                                        <ng-container *ngFor="let validation of validationMessages.SignatoryFirstName">
                                            <div class="errorvl"
                                                *ngIf="ResendForm.get('SignatoryFirstName').hasError(validation.type) && (ResendForm.get('SignatoryFirstName').dirty || ResendForm.get('SignatoryFirstName').touched)">
                                                <label class="errorcolor"> {{ validation.message }}</label>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6">
                                    <div class="mb-2 mb-md-3 mb-lg-4">
                                        <label for="exampleFormControlInput1" class="form-label mb-1">Signatory Email
                                            ID<span class="err-color">*</span>
                                        </label>
                                        <input type="text" formControlName="SignatoryEmailID" class="form-control"
                                            id="SignatoryEmailID" name="SignatoryEmailID"
                                            placeholder="Signatory Email ID">
                                        <ng-container *ngFor="let validation of validationMessages.SignatoryEmailID">
                                            <div class="errorvl"
                                                *ngIf="ResendForm.get('SignatoryEmailID').hasError(validation.type) && (ResendForm.get('SignatoryEmailID').dirty || ResendForm.get('SignatoryEmailID').touched)">
                                                <label class="errorcolor" *ngIf="!condition"> {{ validation.message
                                                    }}</label>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6" [hidden]="IsSpouse">
                                    <div class="mb-2 mb-md-3 mb-lg-4">
                                        <label for="exampleFormControlInput1" class="form-label mb-1">Spouse First
                                            Name<span class="err-color">*</span>
                                        </label>
                                        <input type="text" formControlName="SpouseFirstName" class="form-control"
                                            id="SpouseFirstName" name="SpouseFirstName" placeholder="Spouse First Name">
                                        <ng-container *ngFor="let validation of validationMessages.SpouseFirstName">
                                            <div class="errorvl"
                                                *ngIf="ResendForm.get('SpouseFirstName').hasError(validation.type) && (ResendForm.get('SpouseFirstName').dirty || ResendForm.get('SpouseFirstName').touched)">
                                                <label class="errorcolor"> {{ validation.message }}</label>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6" [hidden]="IsSpouse">
                                    <div class="mb-2 mb-md-3 mb-lg-4">
                                        <label for="exampleFormControlInput1" class="form-label mb-1">Spouse Email
                                            ID<span class="err-color">*</span>
                                        </label>
                                        <input type="text" formControlName="SpouseEmailID" class="form-control"
                                            id="SpouseEmailID" name="SpouseEmailID" placeholder="Spouse Email ID">
                                        <ng-container *ngFor="let validation of validationMessages.SpouseEmailID">
                                            <div class="errorvl"
                                                *ngIf="ResendForm.get('SpouseEmailID').hasError(validation.type) && (ResendForm.get('SpouseEmailID').dirty || ResendForm.get('SpouseEmailID').touched)">
                                                <label class="errorcolor" *ngIf="!condition"> {{ validation.message
                                                    }}</label>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="modal-footer border-0 pb-0 pt-3 px-0">
                            <div>
                                <button type="button" class="btn p-btn-outline me-3" data-bs-dismiss="modal"
                                    (click)="closeResendPopup()">Close</button>
                                <button type="button" class="btn p-btn" id="clientSignature1" [hidden]="!IsSpouse"
                                    [disabled]="(ResendForm.get('eSigning').value === true && (!ResendForm.get('SignatoryFirstName').value || !ResendForm.get('SignatoryEmailID').value || ResendForm.get('SignatoryFirstName').invalid || ResendForm.get('SignatoryEmailID').invalid))"
                                    (click)="submitResendLetter()">Submit</button>
                                <button type="button" class="btn p-btn" id="clientSignature2"
                                    [disabled]="!ResendForm.valid && ResendForm.get('eSigning').value"
                                    [hidden]="IsSpouse" (click)="submitResendLetter()">Submit</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- Error Modal -->
<div class="modal" tabindex="-1" id="resenderror" [ngStyle]="{'display':displayResendErrorModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="resenderror">
            <div class="modal-header error-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Error</h5>
                <button type="button" (click)="closeResendErrorModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/error-icon.png" class="mb-3" alt="warning">
                <p style="word-wrap: break-word;text-align: center;"><span class="d-block text-center">Please provide
                        input to mandatory
                        field(s)-</span>
                    {{getEmptyFields}}
                </p>
                <button type="button" (click)="closeResendErrorModal()" class="error-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>

<!-- PreviewSurvey Modal -->
<div class="modal" id="pagetemplate" [ngStyle]="{'display':displayhide}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="model-action">

                <a class=" action_icon ml-3" data-dismiss="modal"><img src="assets/images/close.svg" class="img-fluid"
                        alt="close" /></a>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12">
                            <h5 class="model_title">Preview Template</h5>
                        </div>
                    </div>
                    <div class="row">

                        <div class="col-12 ">
                            <div class="template_preview_panel docpreview">
                                <div class="preview_header">
                                    <h5> Preview Panel</h5>

                                </div>
                                <div class="preview_body h-100">

                                    <div id="content" #surveyContent>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 text-center mt-3">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Preview Modal -->
<div class="modal" id="preview_doc" [ngStyle]="{'display':displayPreview}">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header border-0 justify-content-end">
                <button type="button" class="btn-close mx-3 mt-2 shadow-none" data-bs-dismiss="modal"
                    (click)="closePreview()"></button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12">
                            <div class="template_preview_panel doc_preview">
                                <div class="preview_body h-100">
                                    <div *ngIf="this.pdfSrc" style="height: 100%;">
                                        <pdf-viewer #pdfViewer class="customtaxlayerremove" [src]="pdfSrc"
                                            [stick-to-page]="true" [show-borders]="true" [render-text]="true"
                                            style="display: block;width:100%;height:calc(100vh - 180px);position: relative;">
                                        </pdf-viewer>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Info List Modal -->
<div class="modal" tabindex="-1" id="bulk-letter-edit-field" aria-modal="true" role="dialog"
    [ngStyle]="{'display':displayInfoEsigningList}">
    <div class="modal-dialog  modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div class="modal-content p-4">
            <div class="modal-header border-0 p-0">
                <h5 class="modal-title fs-16 highlight fw-semibold m-0">Letter Signing Status</h5>
                <button type="button" class="btn-close shadow-none" (click)="closeInfoEsigningList()"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <!-- <p><span class="err-color">Note: </span>All the fields are mandatory</p> -->
            <div class="modal-body p-0">
                <p-table #dt1 styleClass="p-datatable-gridlines" dataKey="id" [rows]="10" [value]="this.InfosigningData"
                    [resizableColumns]="true" sortMode="multiple" [scrollable]="true" scrollHeight="1000px"
                    [showCurrentPageReport]="true" [(first)]="firstpage"
                    currentPageReportTemplate="{first} to {last} of {totalRecords}"
                    [globalFilterFields]="['SignerName','SignerEmailId','SigningStatus']">
                    <ng-template pTemplate="colgroup">
                        <colgroup>
                            <col style="width:25%">
                            <col style="width:30%">
                            <col style="width:30%">

                        </colgroup>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th pResizableColumn>Signer Name </th>
                            <th pResizableColumn> Signer Email ID</th>
                            <th pResizableColumn>Signing Status</th>

                        </tr>
                        <!-- grid searchbox -->
                        <!-- <tr>
    
                            <th> <input pInputText type="text"
                                    (input)="dt.filter($event.target.value, 'BatchId', 'contains')"
                                    class="p-column-filter"></th>
    
                            <th> <input pInputText type="text"
                                    (input)="dt.filter($event.target.value, 'PartnerName', 'contains')"
                                    class="p-column-filter"></th>
                            <th> <input pInputText type="text"
                                    (input)="dt.filter($event.target.value, 'Office', 'contains')"
                                    class="p-column-filter"></th>
    
                        </tr> -->
                    </ng-template>
                    <ng-template pTemplate="body" let-InfoSigning>
                        <tr>
                            <td>{{InfoSigning.SignerName}}</td>
                            <td>{{InfoSigning.SignerEmailId}}</td>
                            <td>{{InfoSigning.SigningStatus}}</td>

                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="12" style="text-align: center;">No E-Signing Status found</td>
                        </tr>
                    </ng-template>

                </p-table>
            </div>
            <div class="modal-footer border-0 pb-0 pt-3 px-0">
                <div>
                    <button type="button" (click)="closeInfoEsigningList()" class="btn p-btn-outline me-3"
                        data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>
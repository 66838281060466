export const configData = {
  msalConfig: {
    auth: {
      clientId: "d4d06b51-2831-4b5c-bc13-7136ed05992b",
      authority: "https://login.microsoftonline.com/2e57aae2-4ecf-4f09-944e-33958c26aa7b",
      //redirectUri: "http://localhost:4200/",
    }
  }
}
export const AppConfig = {
  CommitTag: "28e5ce24"
  // ... other configuration values
};

export const TimeConfig = {
  IdleTimePeriod: 3600,    //3600
  IdleTimeOut: 600      //600
}

import { Component, ComponentFactoryResolver, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, Validators, FormControl, ReactiveFormsModule, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import * as Msal from "msal";
import { LoginService } from '../../services/login.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { configData } from 'src/configData';

const msalConfig = configData.msalConfig;
const clientId = msalConfig.auth.clientId;
const authId = msalConfig.auth.authority;

let clientApplication = new Msal.UserAgentApplication(msalConfig);
let accessToken;
let AccountId;
let GroupNamess;
let GroupIds;
let listOfInstance: string[] = ['https://engage3-webui-appservice.azurewebsites.net/'];
clientApplication.handleRedirectCallback(authRedirectCallBack);

function authRedirectCallBack(error: any, response: any) {
  let endpoint = "https://graph.microsoft.com/v1.0/me/transitiveMemberOf/";
  let MSRedirectMessage;
  if (error) {
    console.log(error);
  }
  else {
    if (response.tokenType === "id_token") {
      MSRedirectMessage = "Redirecting to Microsoft to authenticate...";
      if (clientApplication.getAccount()) {
        let accountid = response.account.accountIdentifier;
        let username = response.account.name;
        let token = response.idToken.rawIdToken;
        localStorage.setItem("AccountId", accountid);
        localStorage.setItem("UserName", username);
        localStorage.setItem("AccessToken", token);
        localStorage.setItem("MSRedirectMessage", MSRedirectMessage)
      }

    } else if (response.tokenType === "access_token") {
      this.percentageMessage = "Redirecting to Microsoft to authenticate...";
      accessToken = response.accessToken;

      try {
        //this.callMSGraphForGroups(endpoint, response.accessToken, this.getGroupNames)
        //this.callMSGraph("https://graph.microsoft.com/beta/me", accessToken);
      } catch (err) {
        console.log(err)
      } finally {
        //   profileButton.classList.add('d-none');
        //   mailButton.classList.remove('d-none');
      }
    } else {
      console.log("token type is:" + response.tokenType);
    }
  }
}

const loginRequest = {
  scopes: ["User.Read", "Group.Read.All"]

};


// function getTokenRedirect(loginRequest, endpoint) {
//   return clientApplication.acquireTokenSilent(loginRequest)
//       .then((response) => {
//         console.log(response);
//         if (response.accessToken) {
//             console.log("access_token acquired at: " + new Date().toString());
//             accessToken = response.accessToken;

//             if (accessToken) {
//               try {
//                 callMSGraphForGroups(endpoint, response.accessToken, getGroupNames) 
//               } catch(err) {
//                 console.log(err)
//               } 
//             }
//         }
//       })
//       .catch(error => {
//           console.log("silent token acquisition fails. acquiring token using redirect");
//           // fallback to interaction when silent call fails
//           return clientApplication.acquireTokenRedirect(loginRequest);
//       });
// }

// let endpoint= "https://graph.microsoft.com/v1.0/me/transitiveMemberOf/";
//     clientApplication.acquireTokenSilent(loginRequest).then(response => {
//       //callMSGraphForGroups("https://graph.microsoft.com/v1.0/me/transitiveMemberOf/microsoft.graph.group?$count=true", response.accessToken, getGroupNames);
//         console.log(clientApplication.getAccount(), "AccountTestNew")
//        // localStorage.setItem('SeesionUser',this.user3) 
//         AccountId = response.account.accountIdentifier;
//         callMSGraphForGroups(endpoint, response.accessToken, getGroupNames)  
//         //return false;

//         }).catch(error => {
//             //console.log(error);
//         });

//         function callMSGraph(endpoint, token, getGroupNames) {
//           const headers = new Headers();
//           const bearer = `Bearer ${token}`;

//           headers.append("Authorization", bearer);

//           const options = {
//               method: "GET",
//               headers: headers
//           };

//           console.log('request made to Graph API at: ' + new Date().toString());

//           fetch(endpoint, options)
//             .then(response => response.json())
//             .then(response => getGroupNames(response, endpoint))
//             .catch(error => console.log(error))
//         }

//         function callMSGraphForGroups(endpoint, token, getGroupNames) {
//             const headers = new Headers();
//             const bearer = `Bearer ${token}`;

//             headers.append("Authorization", bearer);

//             const options = {
//                 method: "GET",
//                 headers: headers
//             };

//             console.log('request made to Graph API for group names at: ' + new Date().toString());

//             fetch(endpoint, options)
//                 .then(response => response.json())
//                 .then(response => getGroupNames(response, endpoint))
//                 .then(response => callMSGraph("https://graph.microsoft.com/beta/me", token, getGroupNames))
//                 .catch(error => console.log(error))
//           }

//           function getGroupNames(data, endpoint){

//             console.log('Graph API responded at: ' + new Date().toString());

//               if (endpoint === "https://graph.microsoft.com/v1.0/me/transitiveMemberOf/") {
//                   if (data.value.length > 0) {
//                       var arr = data.value;
//                       var displayNameArr = [];
//                       var groupIds = [];
//                       for (var i = 0; i < arr.length; i++) {
//                           displayNameArr.push(arr[i].displayName);
//                           groupIds.push(arr[i].id);
//                       }
//                       GroupNamess  = displayNameArr.join();
//                       GroupIds = groupIds.join();
//                       localStorage.setItem("ADGroupNames", GroupNamess );
//                       localStorage.setItem("GroupIds", GroupIds);
//                   }
//               }
//             }



// function signIn() {
//   clientApplication.loginRedirect(loginRequest);
// }

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  user = '1';
  user2: any;
  user3: any;
  msalConfig: any;
  loginRequest: any;
  AccountId: any;
  GroupNamess: any;
  GroupIds: any;
  tokenRequest: any;
  getscreenaccesspermissions: any;
  screennames: any;
  username: any;
  radioselected?: string;
  loginForm: FormGroup;
  submitted = false;
  buttonDisabled?: boolean;
  isloginbtn: boolean = true;
  displayLogPopup = "none"
  public clientApplication?: Msal.UserAgentApplication;//= null;    
  trustedHtml?: SafeHtml;
  public baseapiurl;
  public getbaseapiurl;
  public LoggedInInstance?: string;
  public LoggedInInstance_check: any;
  loggedInstanceName: any;
  getLoginDetails: any;
  updatelogin: any;
  typeSelected: string;
  percentageMessage: any = "Checking your authentication status...";
  constructor(private route: ActivatedRoute, public loginservice: LoginService, private formbld: FormBuilder, private router: Router, private toastr: ToastrService,
    private renderer: Renderer2, public s: DomSanitizer, private SpinnerService: NgxSpinnerService) {
    this.typeSelected = 'timer';
    this.baseapiurl = environment.baseApiUrl;
    this.getbaseapiurl = this.baseapiurl.split('/Api')[0];

    this.msalConfig = msalConfig;

  }

  ngOnInit(): void {
    console.log = function () { };
    localStorage.setItem("IsLogOf", 'false');
    this.SpinnerService.show();
    // setTimeout(() => {
    //   this.onLoginNew();         
    // }, 5000);

    if (sessionStorage.length == 0) {
      this.onLoginNew();
    }

    // else {
    //   setTimeout(() => {
    //     this.percentageMessage = "Redirecting to Dashboard...";
    //   }, 3000);
    // }

    // var InfoLogin =this;
    window.onunload = function () {
      localStorage.removeItem("MSRedirectMessage");
    };
    // console.log=function(){};

    this.msalConfig = {
      auth: {
        clientId: clientId,
        authority: authId,
        //redirectUri: "http://localhost:4200/",
      },
      cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
      }
    };

    this.loginRequest = {
      scopes: ["User.Read", "Group.Read.All"]

    };

    // Add here scopes for access token to be used at MS Graph API endpoints.
    this.tokenRequest = {
      scopes: ["Mail.Read", "User.Read", "Group.Read.All"]
    };
    if (clientApplication.getAccount()) {
      this.callAccessToken();
    }
  }

  async callAccessToken() {
    //if (clientApplication.getAccount()) {
    setTimeout(() => {
      this.percentageMessage = "Authentication successful, redirecting to your dashboard.";
    }),
      this.SpinnerService.show();
    clientApplication.handleRedirectCallback(authRedirectCallBack);
    this.accquiretoken();
    // let token = localStorage.getItem("AccessToken");
    // let endpoint= "https://graph.microsoft.com/v1.0/me/transitiveMemberOf/";
    // clientApplication.acquireTokenSilent(this.loginRequest).then(response => {
    //   //callMSGraphForGroups("https://graph.microsoft.com/v1.0/me/transitiveMemberOf/microsoft.graph.group?$count=true", response.accessToken, getGroupNames);
    //     console.log(clientApplication.getAccount())
    //     localStorage.setItem('SeesionUser',this.user3) 
    //     this.AccountId = response.account.accountIdentifier;
    //     this.callMSGraphForGroups(endpoint, response.accessToken, this.getGroupNames)  
    //     //return false;

    //     }).catch(error => {
    //         //console.log(error);
    //     }); 
    //}
  }
  accquiretoken() {
   // let endpoint = "https://graph.microsoft.com/v1.0/me/transitiveMemberOf/";
    return clientApplication.acquireTokenSilent(this.loginRequest)
      .then((response) => {
        if (response.accessToken) {
          accessToken = response.accessToken;
          this.AccountId = response.account.accountIdentifier;
          let endpoint = "https://graph.microsoft.com/v1.0/users/{id}/transitiveMemberOf/microsoft.graph.group?$count=true&$orderby=displayName&$filter=startswith(displayName, 'engage')";
          endpoint = endpoint.replace('{id}', this.AccountId);
          if (accessToken) {
            try {
              this.callMSGraphForGroups(endpoint, response.accessToken, this.getGroupNames)
            } catch (err) {
              console.log(err)
            }
          }
        }
      })
      .catch(error => {
        console.log("silent token acquisition fails. acquiring token using redirect");
        // fallback to interaction when silent call fails
        return clientApplication.acquireTokenRedirect(this.loginRequest);
      });
  }



  async onLoginNew() {
    //signIn();
    localStorage.removeItem("Logouttrigered");
    setTimeout(() => {
      let MSRedirectMessage = localStorage.getItem("MSRedirectMessage");
      if (MSRedirectMessage == null) {
        this.percentageMessage = "Redirecting to Microsoft to authenticate...";
      }
      clientApplication.loginRedirect(loginRequest);
    }, 1000);

    //const msalInstance = new Msal.UserAgentApplication(this.msalConfig);
    //this.clientApplication.loginRedirect(this.loginRequest)
  }

  onLogin() {
    //this.SpinnerService.show();
    const myMSALObj = new Msal.UserAgentApplication(this.msalConfig);

    myMSALObj.loginPopup(this.loginRequest).then(loginResponse => {
      this.AccountId = loginResponse.uniqueId;
      this.username = loginResponse.account.name;
      localStorage.setItem("UserName", this.username);
      let endpoint = "https://graph.microsoft.com/v1.0/me/transitiveMemberOf/";
      if (myMSALObj.getAccount()) {
        myMSALObj.acquireTokenSilent(this.loginRequest).then(response => {
          //callMSGraphForGroups("https://graph.microsoft.com/v1.0/me/transitiveMemberOf/microsoft.graph.group?$count=true", response.accessToken, getGroupNames);
          localStorage.setItem('SeesionUser', this.user3)

          this.callMSGraphForGroups(endpoint, response.accessToken, this.getGroupNames)
          //return false;

        }).catch(error => {
          //console.log(error);
        });
      }
      else {
        this.toastr.error("Login Failed");
        return false;
      }
      return true;
    }).catch(error => {
      console.log(error);
    });
  }



  callMSGraph(endpoint: any, token: any, getGroupNames: any) {
    const headers = new Headers();
    const bearer = `Bearer ${token}`;
  
    headers.append("Authorization", bearer);

    const options = {
      method: "GET",
      headers: headers
    };


    fetch(endpoint, options)
      .then(response => response.json())
      .then(response => this.getGroupNames(response, endpoint))
      .catch(error => console.log(error))
  }

  callMSGraphForGroups(endpoint: any, token: any, getGroupNames: any) {
    const headers = new Headers();
    const bearer = `Bearer ${token}`;
    const evt = "eventual";

    headers.append("Authorization", bearer);
    headers.append("ConsistencyLevel", evt);

    const options = {
      method: "GET",
      headers: headers
    };


    fetch(endpoint, options)
      .then(response => response.json())
      .then(response => this.getGroupNames(response, endpoint))
      //.then(response => this.callMSGraph("https://graph.microsoft.com/beta/me", token, getGroupNames))
      .then(response => this.getloggedInInstances())
      .catch(error => console.log(error))
  }

  async getloggedInInstances() {
    if (this.GroupIds == undefined) {
      this.GroupIds = '';
    }

    let userName = localStorage.getItem("UserName");
    this.getscreenaccesspermissions = {
      "ClientId": this.AccountId,
      "GroupIds": this.GroupIds,
      // "LoggedInUserName": userName,
      // "LoggedInInstance": this.getbaseapiurl
    }

    this.loginservice.getScreenPermissions(this.getscreenaccesspermissions).then((res) => {
      if (res.StatusCode == 200) {
        localStorage.setItem('ScreenNames', JSON.stringify(res.Data.ScreenNames));
        localStorage.setItem('SPL', JSON.stringify(res.Data.ScreenPermissionList));
        localStorage.setItem("UserId", res.Data.UserId);
        let screenname;

        if (res.Data.ScreenNames == null || res.Data.ScreenNames == '') {
          screenname = "Dashboard";
        }
        else {
          screenname = res.Data.ScreenNames;
        }
        var roles;
        if (res.Data.Roles == null || res.Data.Roles == '') {
          let newRoles = res.Data.Roles;
          roles = res.Data.Roles[0];
          let newroles1 = JSON.stringify(newRoles);
          localStorage.setItem("ScreenAcessPermission", screenname);
          localStorage.setItem("NewRoles", newroles1);
          localStorage.setItem("IniRoles", newRoles);
          this.router.navigate(['/engagementletterdashboard']);
        }
        else {
          let newRoles = res.Data.Roles;
          roles = res.Data.Roles[0];

          let newroles1 = JSON.stringify(newRoles);
          localStorage.setItem("NewRoles", newroles1);
          localStorage.setItem("IniRoles", newRoles);
          localStorage.setItem("roles", roles);
          localStorage.setItem("ScreenAcessPermission", screenname);
          this.router.navigate(['/engagementletterdashboard']);
        }
      }
    });


  }




  getGroupNames(data: any, endpoint: any) {

    //if (endpoint === "https://graph.microsoft.com/v1.0/me/transitiveMemberOf/") {
      if (data.value.length > 0) {
        var arr = data.value;
        var displayNameArr = [];
        var groupIds = [];
        for (var i = 0; i < arr.length; i++) {
          displayNameArr.push(arr[i].displayName);
          groupIds.push(arr[i].id);
        }
        this.GroupNamess = displayNameArr.join();
        this.GroupIds = groupIds.join();
        console.log(this.GroupNamess, "GroupNamess");
        localStorage.setItem("ADGroupNames", this.GroupNamess);
      }
   // }
    let userName = localStorage.getItem("UserName");
    // var loggedInURL=window.location.href;
    //   this.getscreenaccesspermissions = {
    //       "ClientId":this.AccountId,
    //       "GroupIds":this.GroupIds,
    //       "LoggedInUserName":userName,
    //       "LoggedInInstance": this.getbaseapiurl
    //     }

    //    console.log(this.getscreenaccesspermissions, "screenpermAPIJSON");

    //           this.loginservice.getScreenPermissions(this.getscreenaccesspermissions).then((res) => {
    //             //debugger
    //                 if(res.StatusCode == 200){
    //                   if (res.Data.LoginDetailsModel != null && res.Data.LoginDetailsModel.Id > 0 && 
    //                     res.Data.LoginDetailsModel.LoggedOff != null && res.Data.LoginDetailsModel.LoggedOff == false ){
    //                       this.displayLogPopup='block';
    //                       this.renderer.addClass(document.body, 'modal-open') ;
    //                       this.addDiv();
    //                      // //alert("logged successfully");
    //                       this.SpinnerService.hide();
    //                      // //this.router.navigateByUrl("");
    //                       return false; 
    //                   }else{
    //                     console.log(res);
    //                     localStorage.setItem('ScreenNames',JSON.stringify(res.Data.ScreenNames));
    //                     localStorage.setItem('SPL',JSON.stringify(res.Data.ScreenPermissionList));
    //                     localStorage.setItem("UserId",res.Data.UserId);
    //                     let screenname;

    // if (res.Data.ScreenNames == null || res.Data.ScreenNames == ''){
    // screenname = "Letter Generation";
    // }
    // else{
    // screenname = res.Data.ScreenNames;
    // }
    // var roles;
    //                     if(res.Data.Roles == null || res.Data.Roles == ''){
    //                        roles = "Initiator";
    //                        let newrolarr =[];
    //                        newrolarr.push(roles);
    //                       localStorage.setItem("roles",roles);
    //                       localStorage.setItem("ScreenAcessPermission", screenname);
    //                       this.SpinnerService.hide();
    //                       this.router.navigate(['/initiator']);
    //                       localStorage.setItem("IniRoles",roles);
    //                       let newroles1=JSON.stringify(newrolarr);                    
    //                       localStorage.setItem("NewRoles",newroles1);


    //                     }
    //                     else{
    //                       let newRoles=res.Data.Roles;     
    //                       roles = res.Data.Roles[0];

    //                       let newroles1=JSON.stringify(newRoles);                    
    //                       localStorage.setItem("NewRoles",newroles1);
    //                       localStorage.setItem("IniRoles",newRoles);
    //                       console.log((newroles1));
    //                       console.log(JSON.parse(newroles1));
    //                     console.log(roles);
    //                     console.log(screenname);
    //                     localStorage.setItem("roles",roles);
    //                     localStorage.setItem("ScreenAcessPermission", screenname);
    //                     this.SpinnerService.hide();
    //                     this.router.navigate(['/initiator']);
    //                     }
    //                     // this.displayLogPopup='block';
    //                     // this.renderer.addClass(document.body, 'modal-open') ;
    //                     // this.addDiv(); 
    //                   }


    //                 }
    //           });

  }

  public addDiv() {
    this.trustedHtml = this.s.bypassSecurityTrustHtml("<div id='demo' class='modal-backdrop show'></div>")
  }

  redirectToLoginPage() {
    this.displayLogPopup = 'none';
    this.renderer.removeClass(document.body, 'modal-open');
    let myobj = document.getElementById("demo");
    myobj?.remove();
  }

  checkTestAccount(testAccountId: any) {
    switch (testAccountId) {
      case "514d3365-3940-4d7c-8667-3bb029698845": {
        return true;
      }
      case "d88de36d-0503-43d0-993d-21b8d3135269": {
        return true;
      }
      case "3a219020-bf2b-4073-b554-5a9e457e0d96": {
        return true;
      }
      case "a0550b22-2b19-4002-88f6-2a38c18218cc": {
        return true;
      }
      case "89183611-0c14-4f68-86ac-e800c409fe09": {
        return true;
      }
      case "d46de031-d626-415c-863a-ae692e512a73": {
        return true;
      }
      case "aa98e5cc-60f1-4928-a31c-b07ba1b425b1": {
        return true;
      }
      case "b1eae6ed-91bc-4618-a17d-d2ea17fefac0": {
        return true;
      }
      case "e8d05b27-6d98-4fcb-9074-5c4a22f9142c": {
        return true;
      }
      case "eec22f31-babc-4c94-aeb3-ca9990c78ef5": {
        return true;
      }
      case "fdfaf417-ae94-4530-9300-54595ad58a16": {
        return true;
      }
      case "cd9cf041-0055-47e3-a2ee-443c1fe38e88": {
        return true;
      }
      case "4b8352e6-3ad3-44d2-ac89-a982371a3bcb": {
        return true;
      }
      case "0bc35d4c-36f8-4722-a3f8-845ee1dd03e6": {
        return true;
      }
      case "0f4ef81e-4a55-4b2e-9d12-59d7f52f03e7": {
        return true;
      }
      case "c1438a50-9b5d-4a49-9df3-86f206cde50a": {
        return true;
      }
      case "4d212de5-20f7-44e1-9bbf-be7a354b072e": {
        return true;
      }
      case "fc31f17d-7410-45dc-8ffd-0833c31faf26": {
        return true;
      }
      case "088ff6b9-2663-4e55-bdff-e23762bdea99": {
        return true;
      }
      case "82974fdc-eddc-4f37-8c9f-e54173701d2b": {
        return true;
      }
      case "4b3de45b-3207-45c4-927d-7a1a763bae6d": {
        return true;
      }
      case "61e52daa-7e15-463a-999b-0417281e78ef": {
        return true;
      }
      default: {
        return false;
      }
    }


  }
}

import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { HttpClientModule } from '@angular/common/http';
import { NgSelectModule } from '@ng-select/ng-select';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { RippleModule } from 'primeng/ripple';
import { InputTextModule } from 'primeng/inputtext';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { AccordionModule } from 'primeng/accordion';
import { TableModule } from 'primeng/table';
import { CheckboxModule } from 'primeng/checkbox';
import { MultiSelectModule } from 'primeng/multiselect';
import { TooltipModule } from 'primeng/tooltip';
import { CKEditorModule } from '../ckeditor/ckeditor.module';

import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { AppRoutingModule, routes } from './app-routing.module';
import { RouterModule } from '@angular/router';
import { InitiatorComponent } from './components/initiator/initiator.component';
import { FieldsComponent } from './components/fields/fields.component';
import { BlocksComponent } from './components/blocks/blocks.component';
import { TemplatesComponent } from './components/templates/templates.component';
import { BulkletterdashboardComponent } from './components/bulkletterdashboard/bulkletterdashboard.component';
import { HeaderComponent } from './components/header/header.component';
import { EngagementletterdashboardComponent } from './components/engagementletterdashboard/engagementletterdashboard.component';
import { CKEditorComponent } from './components/ckeditor/ckeditor.component';
import { TemplatecreateComponent } from './components/templates/templatecreate/templatecreate.component';
import { AccessrightssettingComponent } from './components/accessrightssetting/accessrightssetting.component';
import { CanDeactivateGuard } from './can-deactivate-guard.service';
import { AuthGuard } from './core/auth.guard';
import { AuthguardServiceService } from './services/authguard-service.service';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { EdittemplateComponent } from './components/templates/edittemplate/edittemplate.component';

import { CreatebulkletterComponent } from './components/bulkletterdashboard/createbulkletter/createbulkletter.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { BulkletterbatcheditComponent } from './components/bulkletterdashboard/bulkletterbatchedit/bulkletterbatchedit.component'
import { NgIdleModule } from '@ng-idle/core';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    InitiatorComponent,
    FieldsComponent,
    BlocksComponent,
    TemplatesComponent,
    BulkletterdashboardComponent,
    HeaderComponent,
    EngagementletterdashboardComponent,
    CKEditorComponent,
    TemplatecreateComponent,
    AccessrightssettingComponent,
    EdittemplateComponent,
    CreatebulkletterComponent,
    BulkletterbatcheditComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    AppRoutingModule,
    RouterModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    ToastrModule,
    HttpClientModule,
    NgSelectModule,
    ButtonModule,
    MenuModule,
    RippleModule,
    InputTextModule,
    CalendarModule,
    DropdownModule,
    TableModule,
    CheckboxModule,
    AccordionModule,
    MultiSelectModule,
    CKEditorModule,
    PdfViewerModule,
    TooltipModule,
    NgMultiSelectDropDownModule.forRoot(),
    RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' }),
    NgIdleModule.forRoot()
  ],
  providers: [{ provide: ToastrService, useValue: ToastrService }, CanDeactivateGuard, AuthGuard, AuthguardServiceService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ServicelinksService {

  constructor() { }

  // Engagementletterservice
  public allEngagementLettersUrl = environment.baseApiUrl + "api/EngagementLetter/GetEngagementLetters";
  public allLetterDropdownUrl = environment.baseApiUrl + "api/EngagementLetter/GetEngagementLetterFilterFields";
  public searchLetterUrl = environment.baseApiUrl + "api/EngagementLetter/GetFilteredSearchLetters";
  public getroles = environment.baseApiUrl + "api/Login/GetRolesandScreenList";
  public manualRefreshStatusUrl = environment.baseApiUrl + "api/Signing/RefreshedSignedLetterStatus";
  public autoRefreshStatusUrl = environment.baseApiUrl + "api/Signing/AutoRefreshStatus";
  public deleteEngagementLetterUrl = environment.baseApiUrl + "api/EngagementLetter/DeleteEngagementLetter";
  public cancelsigningurl = environment.baseApiUrl + "api/Signing/CancelSigning";
  public uploadpdfurl = environment.baseApiUrl + "api/Signing/UploadFinalVersionPDF";
  public engagementletterUpdateeditFields = environment.baseApiUrl + "api/EngagementLetter/SaveEngagementLetterEditFields";
  public getDetailsForResendEsigning = environment.baseApiUrl + "api/EngagementLetter/GetEngagementLettersById?bulklettersId=";
  public resendForEsigning = environment.BulkSignApiUrl + "api/BulkSigning/ResendforESigning";


  //Login
  public getScreenPermissionsurl = environment.baseApiUrl + "api/Login/GetRolesandScreenList";

  //field
  public fieldListActiveUrl = environment.baseApiUrl + "api/Fields/GetActiveFields";
  public listUrl = environment.baseApiUrl + "api/Fields/GetFields";
  public fieldstatusUrl = environment.baseApiUrl + "api/Fields/LoadStatus";
  public fieldidlistUrl = environment.baseApiUrl + "api/Fields/GetFieldsIdList";
  public fieldmddUrl = environment.baseApiUrl + "api/Fields/GetColumnNames";
  public saveFieldUrl = environment.baseApiUrl + "api/Fields/AddField";
  public fieldidUrl = environment.baseApiUrl + "api/Fields/GetFieldsById";
  public updateUrl = environment.baseApiUrl + "api/Fields/UpdateField";
  public deleteUrl = environment.baseApiUrl + "api/Fields/DeleteFields";

  // Block
  public blocklistUrl = environment.baseApiUrl + "api/Blocks/GetBlocks";
  public blockByIdUrl = environment.baseApiUrl + "api/Blocks/GetBlocksById";
  public blockIdListUrl = environment.baseApiUrl + "api/Blocks/GetBlocksIdList";
  public saveBlockUrl = environment.baseApiUrl + "api/Blocks/AddBlocks";
  public blockstatusUrl = environment.baseApiUrl + "api/Blocks/Load";
  public updateblockUrl = environment.baseApiUrl + "api/Blocks/UpdateBlocks";
  public deleteBlockUrl = environment.baseApiUrl + "api/Blocks/DeleteBlock";
  public getactiveblocks = environment.baseApiUrl + "api/Blocks/GetActiveBlocks";

  //BulkLetter
  public returnTypecodeUrl = environment.baseApiUrl + "api/Client/GetReturnType?";
  public partnersUrl = environment.baseApiUrl + "api/Client/PartnersName";
  public fetchClientdetails = environment.baseApiUrl + "api/Client/FetchingClient";
  public exportdata = environment.baseApiUrl + "api/Client/ExportData";
  public importdata = environment.baseApiUrl + "api/Client/ImportData";
  public gettemplatesurl = environment.baseApiUrl + "api/Template/GetTemplates";
  public getbatchidlisturl = environment.baseApiUrl + "api/Client/BatchIds";
  public deleteBulkLetterUrl = environment.baseApiUrl + "api/BulkLetters/DeleteByBulkLetterId";
  public downloadletterurl = environment.baseApiUrl + "api/Template/UploadHTMLLetterPdf";
  public updateAttachmentsBulkLetter = environment.baseApiUrl + "api/BulkLetters/InsertAttachmentsBulkLetter";
  public getAttachmentByID = environment.baseApiUrl + "api/BulkLetters/GetBulkLetterAttachmentById?"
  //public geteditFieldsBulklettersbyid = environment.baseApiUrl + "api/BulkLetters/GetBulkLetterFieldsById?bulkLetterId";
  public getCombinedPDFURLBulkLetter = environment.baseApiUrl + "api/BulkLetters/GetCombinedPDFURL"
  public deleteBulkClientLetterUrl = environment.baseApiUrl + "api/BulkLetters/RemoveClientData";

  public geteditFieldsBulklettersbyID = environment.baseApiUrl + "api/BulkLetters/GetBulkLetterFieldsById?bulkLetterId="
  public addbatchrequest = environment.baseApiUrl + "api/BulkLetters/AddBatchRequestDetails";
  //BatchLetter
  public getbatchlisturl = environment.baseApiUrl + "api/Client/BatchLetters";
  public getbatchlistbyidurl = environment.baseApiUrl + "api/Client/BatchLettersById?batchId";
  public deleteBatchUrl = environment.baseApiUrl + "api/BulkLetters/DeleteByBatchId";
  public getPartnerOfficeListUrl = environment.baseApiUrl + "api/BulkLetters/PartnerOfficeByBatchId?batchId";
  public updateBulkGenIsBatchActive = environment.baseApiUrl + "api/Client/UpdateIsBatchActive";
  public getbatchletterAsync = environment.baseApiUrl + "api/Client/EditBatchLettersById?";
  public UpdateExistingPartnersRecord = environment.baseApiUrl + "api/Client/UpdateExistingPartnersRecords";
  //BatchLetter Edit Fields
  public getActiveclientFields = environment.baseApiUrl + "api/Fields/ActiveClientFields?clientId=";
  public addnewclient = environment.baseApiUrl + "api/Client/AddClientBulkDetails";
  public geteditFieldsBulklettersbyid = environment.baseApiUrl + "api/BulkLetters/GetBulkLetterFieldsById?bulkLetterId";
  public UpdateeditFields = environment.baseApiUrl + "api/BulkLetters/SaveEditFields";

  //History Log
  public getHistorylogbyid = environment.baseApiUrl + "api/EngagementLetter/GetHistoryLogById?engagementLetterId";
  public postInfosigningstatus = environment.baseApiUrl + "api/Signing/SignerStatus?engLetterId";




  public questionlistUrl = environment.baseApiUrl + "api/Questions/GetQuestions";
  public savequestionUrl = environment.baseApiUrl + "api/Questions/AddQuestion";
  public deletequestionUrl = environment.baseApiUrl + "api/Questions/DeleteQuestions?Id=";
  public questionidUrl = environment.baseApiUrl + "api/Questions/GetQuestionsIdList";
  public questionviewUrl = environment.baseApiUrl + "api/Questions/GetQuestionsById";
  public questionupdateUrl = environment.baseApiUrl + "api/Questions/UpdateQuestions";
  public templatelistUrl = environment.baseApiUrl + "api/Template/GetTemplates";
  public saveTemplateUrl = environment.baseApiUrl + "api/Template/AddTemplate";
  public getDepartmentIdEngageGroup = environment.baseApiUrl + "api/Template/GetEngageGroups/";
  public getengageGroupToTypeUrl = environment.baseApiUrl + "api/Template/GetEngageTypes/";
  public getengageBasicUrl = environment.baseApiUrl + "api/Template/GetEngageBasis/";
  public deletetemplateUrl = environment.baseApiUrl + "api/Template/DeleteTemplate?Id=";
  public ddepartmentlistUrl = environment.baseApiUrl + "api/Template/GetDepartments";
  public templateidUrl = environment.baseApiUrl + "api/Template/GetTemplatesIdList";
  public detailstemplateUrl = environment.baseApiUrl + "api/Template/GetTemplateById";
  public templateupdateUrl = environment.baseApiUrl + "api/Template/UpdateTemplate";
  public approveUrl = environment.baseApiUrl + "api/Template/SaveTemplateApproval";
  public getactivequestions = environment.baseApiUrl + "api/Questions/GetActiveQuestions";
  public pa1gelistUrl = environment.baseApiUrl + "api/Pagesettings/GetPagesettings";
  public savePageSettingUrl = environment.baseApiUrl + "api/Pagesettings/AddPagesettings";
  public PagestatusUrl = environment.baseApiUrl + "api/Pagesettings/Load";
  public getPageSettingId = environment.baseApiUrl + "api/Pagesettings/GetDefaultvalues";
  public detailsPagesettingUrl = environment.baseApiUrl + "api/Pagesettings/GetPagesettingsById";
  public pageSettingupdateUrl = environment.baseApiUrl + "api/Pagesettings/UpdatePagesettings";
  public pagedeleteUrl = environment.baseApiUrl + "api/Pagesettings/DeletePagesettings?Id=";

  public autoincrmentPagesettingUrl = environment.baseApiUrl + "api/Pagesettings/autoIncrmentPagenationID";
  public defaultUrl = environment.baseApiUrl + "api/Pagesettings/GetDefaultId";
  public defaultpagesettingsurl = environment.baseApiUrl + "api/Template/GetDefaultpagesettingvalues";
  public gettemplatelogic = environment.baseApiUrl + "api/EngagementLetter/GetTemplateLogic";
  public getjsonlogic = environment.baseApiUrl + "api/EngagementLetter/GetFieldValuesLogic";


  //watremark and letterhead
  public watermarkdataUrl = environment.baseApiUrl + "api/Watermark/Load";
  public watermarklistUrl = environment.baseApiUrl + "api/Watermark/GetWatermarksettings";
  public saveWatermarkddata = environment.baseApiUrl + "api/Watermark/AddWatermark";
  public wateridUrl = environment.baseApiUrl + "api/Watermark/GetWatermarkById";
  public waterDeleteUrl = environment.baseApiUrl + "api/Watermark/DeleteWatermark?Id=";
  public waterupdateUrl = environment.baseApiUrl + "api/Watermark/UpdateWatermark";
  public waterdlistUrl = environment.baseApiUrl + "api/Watermark/GetAutoGenerateIdWatermark";
  public isdefaultUrl = environment.baseApiUrl + "api/Watermark/GetDefaultId";
  public letterlistUrl = environment.baseApiUrl + "api/LetterHead/GetLetterheadsettings";
  public letterDeleteUrl = environment.baseApiUrl + "api/LetterHead/DeleteLetterhead?Id=";
  public saveletterUrl = environment.baseApiUrl + "api/LetterHead/AddLetterhead";
  public letterdataUrl = environment.baseApiUrl + "api/LetterHead/Load";
  public updateletterUrl = environment.baseApiUrl + "api/LetterHead/UpdateLetterhead";
  public letteridUrl = environment.baseApiUrl + "api/LetterHead/GetLetterHeadSettingsById";
  public letterdGeneratelistUrl = environment.baseApiUrl + "api/LetterHead/GetAutoGenerateIdLetterHead";
  public isdefaultletterUrl = environment.baseApiUrl + "api/LetterHead/GetDefaultId";

  /*Initiator*/
  public initiatorlistUrl = environment.baseApiUrl + "api/EngagementLetter/GetEngagementLetters";
  public initiatordropdown = environment.baseApiUrl + "api/EngagementLetter/GetEngagementLetterFilterFields";
  public getSavedFilters = environment.baseApiUrl + "api/EngagementLetter/GetEngagementLetterFilterFields";
  public initiatordeleteUrl = environment.baseApiUrl + "api/EngagementLetter/DeleteEngagementLetter";
  public GetEngagementLetterFilterFields = environment.baseApiUrl + "api/EngagementLetter/GetSavedFilters";
  public saveInitiatorUrl = environment.baseApiUrl + "api/EngagementLetter/AddEngagementLetterFilter";
  public searchInitiatorUrl = environment.baseApiUrl + "api/EngagementLetter/FilteredEngagementLetters";
  public getSavedFilterById = environment.baseApiUrl + "api/EngagementLetter/GetSavedFilterById";
  public editlettername = environment.baseApiUrl + "api/EngagementLetter/GetEditFreeBlockList";
  public sendinfovaluejson = environment.baseApiUrl + "api/EngagementLetter/AddValuesReplaceJSON";
  public getinfovaluejson = environment.baseApiUrl + "api/EngagementLetter/GetFieldValuesReplaceJSON";
  public getcontentblock = environment.baseApiUrl + "api/EngagementLetter/GetBlockContentByName";
  public getmulticontentblock = environment.baseApiUrl + "api/EngagementLetter/GetBlockContentByAllName";
  public updateblockcontent = environment.baseApiUrl + "api/EngagementLetter/UpdateBlockContent";
  public submitcontent = environment.baseApiUrl + "api/EngagementLetter/AddLetterGenerationThirdStage";
  public saveDraftInitiatorUrl = environment.baseApiUrl + "api/EngagementLetter/AddLetterGenerationThirdStage";
  public detailsinitiatorDropdownUrl = environment.baseApiUrl + "api/EngagementLetter/GetEngagementLettersById";
  public saveDraftchooseInitiatorUrl = environment.baseApiUrl + "api/EngagementLetter/AddLetterGenerationFirstStage";
  public addLetterGenerationSecondStage = environment.baseApiUrl + "api/EngagementLetter/AddLetterGenerationSecondStage";
  public rolloverurl = environment.baseApiUrl + "api/Rollover/Rolloverupdate";
  public rollovermethodurl = environment.baseApiUrl + "api/Rollover/RollOverDate";
  public findollovermethodurl = environment.baseApiUrl + "api/Rollover/ForceRollover";
  public gettypesbyid = environment.baseApiUrl + "api/EngagementLetter/GetTypesById";
  public getbasisbyid = environment.baseApiUrl + "api/EngagementLetter/GetBasisById";
  public cancelsigning = environment.baseApiUrl + "api/EngagementLetter/CancelSigning";
  public getsignedstatus = environment.baseApiUrl + "api/EngagementLetter/GetSignedStatusforEversign";
  public reassignedhistorylog = environment.baseApiUrl + "api/EngagementLetter/GetEngagementLettersReassigned";
  public consolelogcapture = environment.baseApiUrl + "api/EngagementLetter/ServerUiLog";
  public updateLogin = environment.baseApiUrl + "api/Login/UpdateLogin";
  public SubmitAdobeSigningData = environment.baseApiUrl + "api/Signing/SubmitAdobeSigning";
  public HtmltoURLConvertData = environment.baseApiUrl + "api/Signing/HtmltoURLConvert";
  public AdobePathURLData = environment.baseApiUrl + "api/Signing/AdobePathURL";
  public PreviewPathURLData = environment.baseApiUrl + "api/Signing/PreviewPathURL";

  /*Login*/
  public getclientnames = environment.baseApiUrl + "api/EngagementLetter/GetClientName";
  public getAddInfoFields = environment.baseApiUrl + "api/EngagementLetter/GetADDInfoFields";
  public chooseactionUrl = environment.baseApiUrl + "api/EngagementLetter/GetActionsForReview";
  public applychanges = environment.baseApiUrl + "api/EngagementLetter/AddLetterGenerationThirdStage";
  public submitreview = environment.baseApiUrl + "api/EngagementLetter/SubmitLetterForReview";
  public EditvalHistory = environment.baseApiUrl + "api/EngagementLetter/EditBlocksEngagementLettersReviews";
  public getLetterwatermarksettings = environment.baseApiUrl + "api/EngagementLetter/GetELDefaultWatermarksettingvalues";
  public getLetterlettersettings = environment.baseApiUrl + "api/EngagementLetter/GetELDefaultletterheadsettingvalues";
  public saveOverRide = environment.baseApiUrl + "api/EngagementLetter/EngagementLetterDAOverride";
  public pdfdownload = environment.baseApiUrl + "api/EngagementLetter/updatePDFdocument";
  public historyLog = environment.baseApiUrl + "api/EngagementLetter/GetHistoryLogById";
  public engagementPdfDownload = environment.baseApiUrl + "api/EngagementLetter/GetPdf";
  public getsignedname = environment.baseApiUrl + "api/Signing/GetSigningDetailsList";
  public eversigntokenurl = environment.baseApiUrl + "api/Signing/GetEversignToken";
  public proceedforsigningurl = environment.baseApiUrl + "api/Signing/ProceedforSigning";
  public proceedforsigninghiddentagsurl = environment.baseApiUrl + "api/Signing/ProceedForSigningUsingHiddenTags";
  public proceedforManualsigninghiddentags = environment.baseApiUrl + "api/Signing/ProceedForManualSigningUsingHiddenTags";
  public attachement1Api = environment.baseApiUrl + "api/Signing/AttachementFile1";
  public attachement2Api = environment.baseApiUrl + "api/Signing/AttachementFile2";
  public addPopupContactPersonName1 = environment.baseApiUrl + "api/EngagementLetter/GetBillContactFullNameById";
  public getStatussigning = environment.baseApiUrl + "api/Signing/GetIframeStatus";
  public signingstatuschange = environment.baseApiUrl + "api/Signing/UpdateEngagmentLetterStatus";
  public refreshStatus = environment.baseApiUrl + "api/EngagementLetter/GetRefreshedSignedLetterStatus";
  public uploadpdf = environment.baseApiUrl + "api/Signing/UpdateFinalversionPDF";
  public getclientdetailsbyid = environment.baseApiUrl + "api/EngagementLetter/GetClientDetailsById";
  public ManualSigningPdfDownload = environment.baseApiUrl + "api/Signing/DownloadManualSigningPDFContent";
  /*Report*/
  public getReportData = environment.baseApiUrl + "api/Reports/Load";
  public downloadClientReport = environment.baseApiUrl + "api/Reports/DownloadCSCR";
  public downloadRollOver = environment.baseApiUrl + "api/Reports/DownloadRollOver";
  /*UploadSignature*/
  public getsignaturelistUrl = environment.baseApiUrl + "api/ManageSignature/GetSignaturedetails";
  public addsignatureUrl = environment.baseApiUrl + "api/ManageSignature/AddSignatureDetails";
  public getsignatureById = environment.baseApiUrl + "api/ManageSignature/GetSignaturedetailsById"
  public updatesigantureUrl = environment.baseApiUrl + "api/ManageSignature/UpdateSignatureDetails";
  public deletesignatureUrl = environment.baseApiUrl + "api/ManageSignature/DeleteSignature?Id=";
  public getSignatureIdList = environment.baseApiUrl + "api/ManageSignature/GetSignatureIdList";
  public getclientdetails = environment.baseApiUrl + "api/EngagementLetter/GetAllClientAsync";
  public insertbulkgen = environment.baseApiUrl + "api/EngagementLetter/InsertBulkRequestAsync";
  public getbulkletterslist = environment.baseApiUrl + "api/EngagementLetter/GetAllEngagementLettersAsync";

  //#region Template
  public getTemplateListUrl = environment.baseApiUrl + "api/Template/GetTemplates";
  public getTemplateIdList = environment.baseApiUrl + "api/Template/GetTemplatesIdList";
  public saveTemplate = environment.baseApiUrl + "api/Template/AddTemplate";
  public getTemplateByID = environment.baseApiUrl + "api/Template/GetTemplateById";
  public updateTemplate = environment.baseApiUrl + "api/Template/UpdateTemplate";
  public getDepartment = environment.baseApiUrl + "api/Template/GetDepartments";
  public getEngageType = environment.baseApiUrl + "api/Template/GetEngageTypes";
  public getStatus = environment.baseApiUrl + "api/Template/GetStatus";
  public getPreview = environment.baseApiUrl + "api/Template/GetPreview";
  public deleteTemplate = environment.baseApiUrl + "api/Template/DeleteTemplate"
  public getCombinedPDFURL = environment.baseApiUrl + "api/Template/GetCombinedPDFURL";
  //#endregion


}
<section>
    <div class="wrapper">
        <app-header [screenNameValue]="ScreenName" (addClassEvent)="addClassInDashboard($event)"></app-header>
        <main class="main-content p-4" id="maintoggledrop" #maintoggledrop>
            <div class="text-md-end mb-3 mb-lg-4">
                <button type="button" class="btn p-btn" data-bs-toggle="modal" data-bs-target="#add-fields"
                    (click)="addFieldModal()">Add</button>
                <button #deletebutton (click)="deleteFieldModal()" type="button" class="btn p-btn ms-2 ms-lg-3"
                    [disabled]="isButtonEnabled">Delete</button>
            </div>
            <section>
                <ngx-spinner size="medium" [type]="typeSelected">
                </ngx-spinner>
                <!-- data table -->
                <div class=" bg-white px-4 py-3 rounded">
                    <h5 class="highlight mb-lg-3 fs-18 fw-semibold">List of Fields</h5>
                    <!--data table -->
                    <div class="table-responsive">

                        <p-table #dt [rowHover]="false" [value]="fieldValues" dataKey="id"
                            styleClass="p-datatable-gridlines" [resizableColumns]="true" sortMode="multiple"
                            [scrollable]="true" scrollHeight="1000px" [paginator]="true" [rows]="10"
                            [showCurrentPageReport]="true" [paginator]="true"
                            currentPageReportTemplate="{first} to {last} of {totalRecords}">

                            <ng-template pTemplate="colgroup">
                                <colgroup>
                                    <col style="width:4%">
                                    <col style="width:7%">
                                    <col style="width:20%">
                                    <col style="width:18%">
                                    <col style="width:16%">
                                    <col style="width:17%">
                                    <col style="width:15%">
                                    <col style="width:8%">
                                </colgroup>
                            </ng-template>


                            <ng-template pTemplate="header">
                                <tr>
                                    <th></th>
                                    <th pResizableColumn pSortableColumn="FieldId">ID <p-sortIcon field="FieldId">
                                        </p-sortIcon>
                                    </th>
                                    <th pResizableColumn pSortableColumn="FieldName">Field Name <p-sortIcon
                                            field="FieldName">
                                        </p-sortIcon>
                                    </th>
                                    <th pResizableColumn pSortableColumn="DisplayName">Display Name <p-sortIcon
                                            field="DisplayName"></p-sortIcon>
                                    </th>
                                    <th pResizableColumn pSortableColumn="DirectInput">Input Source<p-sortIcon
                                            field="DirectInput"></p-sortIcon>
                                    </th>
                                    <th pResizableColumn pSortableColumn="ChangeNotes">Change Notes <p-sortIcon
                                            field="ChangeNotes"></p-sortIcon>
                                    </th>
                                    <th pResizableColumn pSortableColumn="StatusName">Status <p-sortIcon
                                            field="StatusName">
                                        </p-sortIcon>
                                    </th>
                                    <th pResizableColumn><span>Action</span></th>
                                </tr>
                                <tr>

                                    <th class="text-center"><input type="checkbox" (change)="checkAllCheckBox($event)"
                                            [checked]="isAllCheckBoxChecked()" [disabled]="selectAllCheckBoxDisabled"
                                            style="width: 13px;" />
                                    </th>
                                    <th>
                                        <input pInputText type="text"
                                            (input)="dt.filter($event.target.value, 'FieldId', 'contains')"
                                            class="p-column-filter">
                                    </th>
                                    <th>
                                        <input pInputText type="text"
                                            (input)="dt.filter($event.target.value, 'FieldName', 'contains')"
                                            class="p-column-filter">
                                    </th>
                                    <th>
                                        <input pInputText type="text"
                                            (input)="dt.filter($event.target.value, 'DisplayName', 'contains')"
                                            class="p-column-filter">
                                    </th>
                                    <th>
                                        <input pInputText type="text"
                                            (input)="dt.filter($event.target.value, 'InputType', 'contains')"
                                            class="p-column-filter">
                                    </th>

                                    <th>
                                        <input pInputText type="text"
                                            (input)="dt.filter($event.target.value, 'ChangeNotes', 'contains')"
                                            class="p-column-filter">
                                    </th>
                                    <th>
                                        <input pInputText type="text"
                                            (input)="dt.filter($event.target.value, 'StatusName', 'contains')"
                                            class="p-column-filter">
                                    </th>


                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-field>
                                <tr>
                                    <td class="text-center">
                                        <input type="checkbox" value="{{field.FieldId}}" [(ngModel)]="field.checked"
                                            (change)="toggleSingleCheckbox(field.FieldId,field.checked)">
                                    </td>
                                    <td>{{field.FieldId }}</td>
                                    <td>{{field.FieldName}}</td>
                                    <td>{{field.DisplayName}}</td>
                                    <td>{{field.InputType}}</td>
                                    <td>{{field.ChangeNotes}}</td>
                                    <td>{{field.StatusName}}</td>

                                    <td class="icon_image">
                                        <button class="bg-transparent mx-1" pTooltip="Edit" data-bs-toggle="modal"
                                            data-bs-target="#edit-field"><img src="./assets/images/edit.png"
                                                alt="Edit-icon" (click)="editFieldModal(field.FieldId)">
                                        </button>
                                        <a (click)="editFieldModal(field.FieldId)" for="upload-btn"
                                            class="link-color uploadLink text-decoration-none">
                                            Edit
                                        </a>
                                    </td>
                                </tr>

                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="12" style="text-align: center;">No
                                        fields Found</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>

                <!-- Logout Modal -->
                <div class="modal" tabindex="-1" id="logOutModal">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title fw-semibold highlight">Confirm Logout</h5>
                                <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <p class="s-text fw-medium mb-0">Are you Sure want to Logout</p>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn p-btn-outline" data-bs-dismiss="modal">No</button>
                                <button type="button" class="btn p-btn ms-2">Yes</button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Add field Modal start here -->
                <div class="modal" tabindex="-1" id="add-fields" [ngStyle]="{'display':displayAddField}"
                    [class.shadow-popup]="secondPopupOpen">
                    <div class="modal-dialog  modal-dialog-centered modal-lg">
                        <div class="modal-content p-4">
                            <div class="modal-header border-0 p-0">
                                <h5 class="modal-title fs-16 highlight fw-semibold m-0">Add New Field </h5>
                                <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal"
                                    aria-label="Close" (click)="Closeaddfield()"></button>
                            </div>
                            <p><span class="err-color">Note: </span>All the fields are mandatory.</p>
                            <div class="modal-body p-0">
                                <div class="p-4 border rounded">
                                    <form class="form-horizontal" [formGroup]="createFieldForm">
                                        <div class="row">
                                            <div class="col-12 col-md-6 col-lg-4">
                                                <div class="mb-2 mb-md-3 mb-lg-4">
                                                    <label for="fieldid" class="form-label mb-1">ID
                                                        <span class="err-color">*</span></label>
                                                    <input type="text" id="fieldid" placeholder="ID"
                                                        [formControl]="createFieldForm.controls['FieldID']" disabled
                                                        value="{{this.newFeildId}}" formControlName="FieldID"
                                                        autocomplete="off" class="form-control input-md">
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 col-lg-4">
                                                <div class="mb-2 mb-md-3 mb-lg-4">
                                                    <label for="fieldname" class="form-label mb-1">Field
                                                        Name
                                                        <span class="err-color">*</span></label>
                                                    <input type="text" class="form-control"
                                                        (keypress)=" AvoidSpace($event)" name="FieldName"
                                                        autocomplete="off" #nameit id="FieldName"
                                                        placeholder="Enter Field Name"
                                                        [formControl]="createFieldForm.controls['FieldName']" focusOnce
                                                        required>
                                                    <ng-container
                                                        *ngFor="let validation of validation_messages.FieldName">
                                                        <div class="errorvl errorvlfname "
                                                            *ngIf="createFieldForm.get('FieldName').hasError(validation.type) && (createFieldForm.get('FieldName').dirty || createFieldForm.get('FieldName').touched)">
                                                            <label class="errorcolor hide"> {{ validation.message
                                                                }}</label>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 col-lg-4">
                                                <div class="mb-2 mb-md-3 mb-lg-4">
                                                    <label for="displayname" class="form-label mb-1">Display
                                                        Name
                                                        <span class="err-color">*</span></label>
                                                    <input type="email" class="form-control input-md" #displayName
                                                        name="DisplayName" formControlName="DisplayName"
                                                        autocomplete="off" placeholder="Enter Display Name" required>
                                                    <ng-container
                                                        *ngFor="let validation of validation_messages.DisplayName">
                                                        <div class="errorvl"
                                                            *ngIf="createFieldForm.get('DisplayName').hasError(validation.type) && (createFieldForm.get('DisplayName').dirty || createFieldForm.get('DisplayName').touched)">
                                                            <label class="errorcolor"> {{ validation.message }}</label>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 col-lg-4">
                                                <div class="mb-2 mb-md-3 mb-lg-4">
                                                    <label for="InputSources" class="form-label mb-1">Input
                                                        Source <span class="err-color">*</span></label>
                                                    <div class="d-flex gap-3">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="radio" name="Direct"
                                                                formControlName="Direct" id="inlineRadio1"
                                                                value="option1" [checked]="isCheckedDirectInput"
                                                                (change)="onItemChange($event.target.value)">
                                                            <label class="form-check-label" for="inlineRadio1">Direct
                                                                Input</label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="radio" name="Direct"
                                                                formControlName="Direct" id="inlineRadio2"
                                                                value="option2" [checked]="isCheckedClientInfo"
                                                                (change)="onItemChange($event.target.value)">
                                                            <label class="form-check-label" for="inlineRadio2">
                                                                Client Info
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <!-- Data Type -->
                                            <div class="col-12 col-md-6 col-lg-4">
                                                <div class="mb-2 mb-md-3 mb-lg-4">
                                                    <label for="InputSource" class="form-label mb-1">Data
                                                        Type
                                                        <span class="err-color">*</span></label>
                                                    <ng-select [items]="this.DatatypeValue" bindLabel="FieldType"
                                                        placeholder="Select"
                                                        [formControl]="createFieldForm.controls['DataType']"
                                                        [(ngModel)]="model.selectedDatatype" [virtualScroll]="true"
                                                        [searchable]="false" [clearable]="false"
                                                        [readonly]="isReadOnlyDataType">
                                                    </ng-select>
                                                    <ng-container
                                                        *ngFor="let validation of validation_messages.DataType">
                                                        <div class="errorvl"
                                                            *ngIf="(isCheckedDirectInput) && createFieldForm.get('DataType').hasError(validation.type) && (createFieldForm.get('DataType').dirty || createFieldForm.get('DataType').touched)">
                                                            <label class="errorcolor "> {{ validation.message }}</label>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <!-- Add Client Info -->
                                            <div class="col-12 col-md-6 col-lg-4">
                                                <div class="mb-2 mb-md-3 mb-lg-4">
                                                    <label for="InputSource" class="form-label mb-1">Client
                                                        Info
                                                        <span class="err-color">*</span></label>
                                                    <ng-select [items]="this.MDDtypeValue" bindLabel="ColumnNames"
                                                        placeholder="Select" name="ClientInfo"
                                                        [formControl]="createFieldForm.controls['ClientInfo']"
                                                        [searchable]="false" [(ngModel)]="model.selectedMDDtype"
                                                        [readonly]="isReadOnlyClientInfo" [clearable]="false"
                                                        [virtualScroll]="true" (change)="updateFieldName($event)">
                                                    </ng-select>
                                                    <ng-container
                                                        *ngFor="let validation of validation_messages.ClientInfo">
                                                        <div class="errorvl"
                                                            *ngIf="(isCheckedClientInfo) && createFieldForm.get('ClientInfo').hasError(validation.type) && (createFieldForm.get('ClientInfo').dirty || createFieldForm.get('ClientInfo').touched)">
                                                            <label class="errorcolor "> {{ validation.message }}</label>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 col-lg-4">
                                                <div>
                                                    <label for="status" class="form-label mb-1">Status
                                                        <span class="err-color">*</span></label>
                                                    <ng-select [items]="this.StatusValue" bindLabel="StatusName"
                                                        placeholder="Select" [searchable]="false" [clearable]="false"
                                                        [(ngModel)]="model.selectedStatus" formControlName="Status"
                                                        name="Status" required>
                                                    </ng-select>
                                                    <ng-container *ngFor="let validation of validation_messages.Status">
                                                        <div class="errorvl"
                                                            *ngIf="createFieldForm.get('Status').hasError(validation.type) && (createFieldForm.get('Status').dirty || createFieldForm.get('Status').touched)">
                                                            <label class="errorcolor"> {{ validation.message }}</label>
                                                        </div>
                                                    </ng-container>

                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 col-lg-4">
                                                <div>
                                                    <label for="changenotes" class="form-label mb-1">Change
                                                        Notes
                                                        <span class="err-color">*</span></label>
                                                    <textarea placeholder="Enter Change Notes" #changeNotes
                                                        formControlName="ChangeNotes"
                                                        class="form-control input-md no-resize"></textarea>
                                                    <ng-container
                                                        *ngFor="let validation of validation_messages.ChangeNotes">
                                                        <div class="errorvl"
                                                            *ngIf="createFieldForm.get('ChangeNotes').hasError(validation.type) && (createFieldForm.get('ChangeNotes').dirty || createFieldForm.get('ChangeNotes').touched)">
                                                            <label class="errorcolor"> {{ validation.message }}</label>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <div
                                                class="col-12 col-md-12 col-lg-4 d-flex justify-content-end align-items-end">
                                                <div class="mt-3 mt-md-4">
                                                    <button type="button" class="btn p-btn-outline"
                                                        (click)=" onClearField()">Clear</button>
                                                    <button type="button" class="btn p-btn ms-3"
                                                        [disabled]="!createFieldForm.valid"
                                                        (click)=" saveField()">Save</button>
                                                </div>
                                            </div>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Add New field Modal End here -->

                <!-- Edit or View an update field Modal -->
                <div class="modal" tabindex="-1" id="edit-field" [ngStyle]="{'display':displayEditField}"
                    [class.shadow-popup]="secondPopupOpen">
                    <div class="modal-dialog  modal-dialog-centered modal-lg">
                        <div class="modal-content p-4">
                            <div class="modal-header border-0 p-0">
                                <h5 class="modal-title fs-18 highlight fw-semibold m-0">Edit Fields </h5>
                                <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal"
                                    aria-label="Close" (click)="CloseEditField()"></button>
                            </div>
                            <p><span class="err-color">Note: </span>All the fields are mandatory</p>
                            <div class="modal-body p-0">
                                <div class="p-4 border rounded">
                                    <form class="form-horizontal" [formGroup]="fieldEditForm">
                                        <div class="row">
                                            <div class="col-12 col-md-6 col-lg-4">
                                                <div class="mb-2 mb-md-3 mb-lg-4">
                                                    <label for="FieldID" class="form-label mb-1">ID
                                                        <span class="err-color">*</span></label>
                                                    <input type="text" class="form-control" id="FieldID"
                                                        [formControl]="fieldEditForm.controls['FieldID']"
                                                        [(ngModel)]="this.editjsonvalue.Id" name="FieldID"
                                                        autocomplete="off" placeholder="" disabled>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 col-lg-4">
                                                <div class="mb-2 mb-md-3 mb-lg-4">
                                                    <label for="fieldname" class="form-label mb-1">Field
                                                        Name
                                                        <span class="err-color">*</span></label>
                                                    <input name="editFieldName" type="text"
                                                        (keypress)=" AvoidSpace($event)" class="form-control"
                                                        id="editFieldName" [disabled]="this.isFieldEditable"
                                                        [(ngModel)]="this.editjsonvalue.FieldName"
                                                        [formControl]="fieldEditForm.controls['FieldName']"
                                                        autocomplete="off" placeholder="Enter Field Name">
                                                    <ng-container
                                                        *ngFor="let validation of validation_messages.FieldName">
                                                        <div class="errorvl errorvlfname "
                                                            *ngIf="fieldEditForm.get('FieldName').hasError(validation.type) && (fieldEditForm.get('FieldName').dirty || fieldEditForm.get('FieldName').touched)">
                                                            <label class="errorcolor hide"> {{ validation.message
                                                                }}</label>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 col-lg-4">
                                                <div class="mb-2 mb-md-3 mb-lg-4">
                                                    <label for="DisplayName" class="form-label mb-1">Display
                                                        Name
                                                        <span class="err-color">*</span></label>
                                                    <input type="text" class="form-control" id="DisplayName"
                                                        [(ngModel)]="this.editjsonvalue.DisplayName"
                                                        [formControl]="fieldEditForm.controls['DisplayName']"
                                                        name="DisplayName" autocomplete="off" minlength="3"
                                                        placeholder="Enter Display Name" required
                                                        placeholder="Enter Display Name">
                                                    <ng-container
                                                        *ngFor="let validation of validation_messages.DisplayName">
                                                        <div class="errorvl"
                                                            *ngIf="fieldEditForm.get('DisplayName').hasError(validation.type) && (fieldEditForm.get('DisplayName').dirty || fieldEditForm.get('DisplayName').touched)">
                                                            <label class="errorcolor"> {{ validation.message }}</label>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 col-lg-4">
                                                <div class="mb-2 mb-md-3 mb-lg-4">
                                                    <label for="InputSource" class="form-label mb-1">Input
                                                        Source
                                                        <span class="err-color">*</span></label>
                                                    <div class="d-flex gap-3">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="radio"
                                                                name="inlineRadioOptions1" value="option1"
                                                                id="inlineRadio3" [checked]="isCheckedDirectInput"
                                                                [disabled]="isDirectInputDisabled"
                                                                (change)="onEditItemChange($event.target.value)">
                                                            <label class="form-check-label" for="inlineRadio3">
                                                                Direct Input
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="radio"
                                                                name="inlineRadioOptions1" id="inlineRadio4"
                                                                value="option2" [checked]="isCheckedClientInfo"
                                                                [disabled]="isClientInfoDisabled"
                                                                (change)="onEditItemChange($event.target.value)">
                                                            <label class="form-check-label" for="inlineRadio4">
                                                                Client Info
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 col-lg-4">
                                                <div class="mb-2 mb-md-3 mb-lg-4">
                                                    <label for="datatype" class="form-label mb-1">Data
                                                        Type
                                                        <span class="err-color">*</span></label>
                                                    <ng-select [items]="this.DatatypeValue" class=""
                                                        bindLabel="FieldType" placeholder="Select"
                                                        [formControl]="fieldEditForm.controls['DataType']"
                                                        [(ngModel)]="model.selectedDatatype"
                                                        [(ngModel)]="this.viewDatatype" [virtualScroll]="true"
                                                        [searchable]="false" [clearable]="false"
                                                        [readonly]="isReadOnlyDataType">
                                                    </ng-select>
                                                    <ng-container
                                                        *ngFor="let validation of validation_messages.DataType">
                                                        <div class="errorvl"
                                                            *ngIf="(isCheckedDirectInput) && fieldEditForm.get('DataType').hasError(validation.type) && (fieldEditForm.get('DataType').dirty || fieldEditForm.get('DataType').touched)">
                                                            <label class="errorcolor "> {{ validation.message }}</label>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <!-- Add Client Info -->
                                            <div class="col-12 col-md-6 col-lg-4">
                                                <div class="mb-2 mb-md-3 mb-lg-4">
                                                    <label for="clientInfo" class="form-label mb-1">Client
                                                        Info
                                                        <span class="err-color">*</span></label>
                                                    <ng-select [items]="this.MDDtypeValue" bindLabel="ColumnNames"
                                                        placeholder="Select" name="ClientInfo"
                                                        [formControl]="fieldEditForm.controls['ClientInfo']"
                                                        [(ngModel)]="this.viewClientinfo"
                                                        [(ngModel)]="model.selectedMDDtype"
                                                        [readonly]="isReadOnlyClientInfo" [searchable]="false"
                                                        [clearable]="false" [virtualScroll]="true"
                                                        (change)="updateFieldName($event)">

                                                    </ng-select>
                                                    <ng-container
                                                        *ngFor="let validation of validation_messages.ClientInfo">
                                                        <div class="errorvl"
                                                            *ngIf="(isCheckedClientInfo) && fieldEditForm.get('ClientInfo').hasError(validation.type) && (fieldEditForm.get('ClientInfo').dirty || fieldEditForm.get('ClientInfo').touched)">
                                                            <label class="errorcolor "> {{ validation.message }}</label>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 col-lg-4">
                                                <div>
                                                    <label for="Status" class="form-label mb-1">Status
                                                        <span class="err-color">*</span></label>
                                                    <ng-select [items]="this.StatusValue" bindLabel="StatusName"
                                                        placeholder="Select" [readonly]="isReadstatusOnly"
                                                        [searchable]="false" [clearable]="false"
                                                        [(ngModel)]="this.statusdata" [(ngModel)]="model.selectedStatus"
                                                        [formControl]="fieldEditForm.controls['Status']" name="Status"
                                                        required>
                                                    </ng-select>
                                                    <ng-container *ngFor="let validation of validation_messages.Status">
                                                        <div class="errorvl"
                                                            *ngIf="fieldEditForm.get('Status').hasError(validation.type) && (fieldEditForm.get('Status').dirty || fieldEditForm.get('Status').touched)">
                                                            <label class="errorcolor"> {{ validation.message }}</label>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>

                                            <div class="col-12 col-md-6 col-lg-4">
                                                <div>
                                                    <label for="changenotes" class="form-label mb-1">Change
                                                        Notes
                                                        <span class="err-color">*</span></label>
                                                    <textarea placeholder="Enter Notes"
                                                        [(ngModel)]="this.editjsonvalue.ChangeNotes" #changeNotes
                                                        [formControl]="fieldEditForm.controls['ChangeNotes']"
                                                        class="form-control input-md no-resize"></textarea>
                                                    <ng-container
                                                        *ngFor="let validation of validation_messages.ChangeNotes">
                                                        <div class="errorvl"
                                                            *ngIf="fieldEditForm.get('ChangeNotes').hasError(validation.type) && (fieldEditForm.get('ChangeNotes').dirty || fieldEditForm.get('ChangeNotes').touched)">
                                                            <label class="errorcolor"> {{ validation.message }}</label>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>

                                            <div class="col-12 col-md-6 col-lg-4">
                                                <div class="mb-2 mb-md-3 mb-lg-4">
                                                    <label for="ConnectedBlocks" class="form-label mb-1">Connected
                                                        Blocks
                                                        <span class="err-color">*</span></label>
                                                    <div class="">
                                                        <textarea disabled class="form-control input-md no-resize"
                                                            *ngIf="this.editjsonvalue.ConnectedBlocks != ''">{{this.editjsonvalue.ConnectedBlocks}}</textarea>
                                                        <textarea disabled class="form-control input-md no-resize"
                                                            *ngIf="this.editjsonvalue.ConnectedBlocks == ''">None</textarea>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="d-flex justify-content-end align-items-end">
                                            <div class="mt-3 mt-md-4">
                                                <button type="button" class="btn p-btn-outline"
                                                    (click)="CloseEditField()">Cancel</button>
                                                <button type=" button" class="btn p-btn ms-3"
                                                    (click)="UpdateFieldForm()"
                                                    [disabled]="!fieldEditForm.valid">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- warning Modal -->
                <div class="modal" tabindex="-1" id="warning" [ngStyle]="{'display':displayWarningModal}">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header warning-modal">
                                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Warning</h5>
                                <button type="button" (click)="closeWarningModal()" class="btn-close shadow-none"
                                    data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body text-center">
                                <img src="./assets/images/warning.png" class="mb-3" alt="warning">
                                <p class="fs-16">{{warningMessage}}</p>
                                <button type="button" (click)="closeWarningModal()" class="warning-btn"
                                    data-bs-dismiss="modal">Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Error Modal -->
                <div class="modal" tabindex="-1" id="error" [ngStyle]="{'display':displayErrorModal}">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header error-modal">
                                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Error</h5>
                                <button type="button" (click)="closeErrorModal()" class="btn-close shadow-none"
                                    data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body text-center">
                                <img src="./assets/images/error-icon.png" class="mb-3" alt="warning">
                                <p class="fs-16">{{errorMessage}}</p>
                                <button type="button" (click)="closeErrorModal()" class="error-btn"
                                    data-bs-dismiss="modal">Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Success Modal -->
                <div class="modal" tabindex="-1" id="success" [ngStyle]="{'display':displaySuccessModal}">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header success-modal">
                                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Success</h5>
                                <button type="button" (click)="closeSuccessModal()" class="btn-close shadow-none"
                                    data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body text-center">
                                <img src="./assets/images/success-icon.png" class="mb-3" alt="warning">
                                <p class="fs-16">{{successMessage}}</p>
                                <button type="button" (click)="closeSuccessModal()" class="success-btn"
                                    data-bs-dismiss="modal">Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Delete Modal start here -->
                <div class="modal" tabindex="-1" id="deleteModal" [ngStyle]="{'display':displayDeleteModal}">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title fw-semibold highlight">Confirm Delete</h5>
                                <button type="button" (click)="onCloseDeleteModal()" class="btn-close shadow-none"
                                    data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <p class="s-text fw-medium mb-0">Are you sure want to delete the Field(s)?</p>
                            </div>
                            <div class="modal-footer">
                                <button type="button" (click)="onCloseDeleteModal()" class="btn p-btn-outline"
                                    data-bs-dismiss="modal">No</button>
                                <button type="button" (click)="onDeletefield()" class="btn p-btn ms-2">Yes</button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Delete cancel Modal End here -->
            </section>
        </main>
    </div>
    <div [innerHTML]="trustedHtml"></div>
</section>
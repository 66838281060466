import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Table } from 'primeng/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { CKEditor4 } from '../ckeditor/ckeditor';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { BlockService } from 'src/app/services/block.service';
import { FieldService } from '../../services/field.service';
import { ToastService } from '../../shared/toast.service';
import { LocationStrategy } from '@angular/common';

@Component({
  selector: 'app-blocks',
  templateUrl: './blocks.component.html',
  styleUrls: ['./blocks.component.css']
})
export class BlocksComponent implements OnInit {

  //common variables
  datatarget: any;
  ckeditiorContetnt: any;
  ScreenName: string = 'Blocks';
  public displayaddblock = "none";
  displayeditblock = "none";
  trustedHtml: SafeHtml;
  editors = ['Classic'];
  validation_messages: any;
  Username: any;
  model: any = {};
  typeSelected: string;
  selectedStatus: any;
  templateForm: FormGroup;

  //list(grid) block variables
  blockValues = [];
  fieldValues = [];
  isButtonEnabled = true;
  totalDeleteId: any;
  deleteId: any;
  anyCheckboxSelected: boolean;
  isChecked: boolean;

  //block preview variables
  blockIdModel: any;
  viewJsonArray: any;
  ckEditViewContent: any;
  previewEditorcontent: SafeHtml;
  toggleFullScreen: boolean = false;

  //Add blocks & edit block & delete
  blockCreateForm: FormGroup;
  blockEditForm: FormGroup;
  blockIdCount: number;
  newBlockId: number;
  blockStatusValue: any;
  formControlsToReset: any;
  editjsonvalue: any = {};
  isReadstatusOnly: boolean = false;
  isReadBlockName: boolean = false;
  viewStatus: any;
  statusData: any;
  addBlockJsonValue: any;
  blockUpdateJsonValue: any;
  displayDeleteModal: any;
  selectedDeleteProducts: any;
  selectAllCheckBoxDisabled: boolean = false;


  //Modal variables
  displayLogout = "none";
  displayWarningModal = "none";
  warningMessage: string;
  displayErrorModal = "none";
  errorMessage: string;
  displaySuccessModal = "none";
  successMessage: string;
  secondPopupOpen: boolean = false;
  tooltipText = 'Full Screen';

  //view child
  @ViewChild('dt') table: Table;
  @ViewChild('dt1') table1: Table;
  @ViewChild('dt2') table2: Table;
  @ViewChild('maintoggledrop', { static: false }) maintoggledrop: ElementRef;
  @ViewChild("addbutton", { static: false }) add: ElementRef;
  @ViewChild('deletebutton', { static: false }) deletebutton: ElementRef;

  public config = {
    uiColor: '#eeeeee',
    toolbarGroups: [{ name: 'clipboard', groups: ['clipboard', 'undo'] },
    { name: 'editing', groups: ['find', 'selection', 'spellchecker'] },
    { name: 'insert' },
    { name: 'document', groups: ['mode', 'document', 'doctools'] },
    { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
    { name: 'paragraph', groups: ['blocks', 'align', 'list', 'indent'] },
    { name: 'styles' },
    { name: 'tools' },
    { name: 'colors' }],
    resize_enabled: false,
    removePlugins: 'elementspath,save,magicline',
    extraPlugins: 'justify,colordialog,maximize,card,indentblock',
    colorButton_foreStyle: {
      element: 'font',
      attributes: { 'color': '#(color)' }
    },
    font_names: "Arial/Arial, Helvetica, sans-serif;Arial Narrow/Arial Narrow, Helvetica, sans-serif; Comic Sans MS/Comic Sans MS, cursive;Courier New/Courier New, Courier, monospace;Georgia/Georgia, serif;Lucida Sans Unicode/Lucida Sans Unicode, Lucida Grande, sans-serif;Tahoma/Tahoma, Geneva, sans-serif;Times New Roman/Times New Roman, Times, serif;Trebuchet MS/Trebuchet MS, Helvetica, sans-serif;Verdana/Verdana, Geneva, sans-serif; Ubuntu/Ubuntu, Geneva, sans-serif",

    height: 188,
    removeDialogTabs: 'image:advanced;link:advanced',
    removeButtons: 'PageBreak,PasteText,PasteFromWord,Scayt,HorizontalRule,CopyFormatting,Subscript,Superscript,Cut,Copy,Paste,ExportPdf,Smiley,Iframe,Flash,NewPage,Preview,Print,ShowBlocks,Templates,CreateDiv,About,Image,Table,Anchor,RemoveFormat',
    fullPage: 'true',

  }
  constructor(private Location: LocationStrategy, public _sanitizer: DomSanitizer, private renderer: Renderer2, public blockservice: BlockService, public SpinnerService: NgxSpinnerService, private formbld: FormBuilder, public fieldservice: FieldService, private toast: ToastService) {
    history.pushState(null, null, window.location.href);

    this.Location.onPopState(() => {

      history.pushState(null, null, window.location.href);

    });
    this.typeSelected = 'timer';
    this.blockCreateForm = this.formbld.group({
      ID: new FormControl([
        Validators.required,
      ]),
      BlockName: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(100), this.minLengthWithoutLeadingSpaces(5)]],
      Content: ['', [Validators.required]],
      BlockDescription: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(255), this.minLengthWithoutLeadingSpaces(5)]],
      Status: [null, Validators.required],
      ChangeNotes: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(255), this.minLengthWithoutLeadingSpaces(5)]],
    });

    this.blockEditForm = this.formbld.group({
      ID: new FormControl([
        Validators.required,
      ]),
      BlockName: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(100), this.minLengthWithoutLeadingSpaces(5)]],
      Content: ['', [Validators.required]],
      BlockDescription: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(255), this.minLengthWithoutLeadingSpaces(5)]],
      Status: [null, Validators.required],
      ChangeNotes: ['', [Validators.required, Validators.minLength(5), , Validators.maxLength(255), this.minLengthWithoutLeadingSpaces(5)]],
      ConnectedTemplate: ['', [Validators.required]],

    });

    this.validation_messages = {
      'ID': [
      ],

      'BlockName': [
        { type: 'required', message: this.toast.blockNameError },
        { type: 'maxlength', message: this.toast.blockNameCharactersOnly },
        { type: 'minLengthWithoutLeadingSpaces', message: this.toast.blockMinCharactersOnly },
      ],

      'BlockDescription': [
        { type: 'required', message: this.toast.blockdescriptionError },
        { type: 'maxlength', message: this.toast.blockdescriptionCharactersOnly },
        //{ type: 'minlength', message: this.toast.blockdescriptionMinCharactersOnly },
        { type: 'minLengthWithoutLeadingSpaces', message: this.toast.blockdescriptionMinCharactersOnly },
      ],

      'Status': [
        { type: 'required', message: this.toast.toastError },

      ],

      'ChangeNotes': [
        { type: 'required', message: this.toast.ChangeNotesError },
        //{ type: 'minlength', message: this.toast.changenotesminCharactersOnly },
        { type: 'maxlength', message: this.toast.changenotesCharactersOnly },
        { type: 'minLengthWithoutLeadingSpaces', message: this.toast.changenotesminCharactersOnly },
      ],

      'Content': [
        { type: 'required', message: this.toast.contentError },

      ],
    }
  }

  ngOnInit(): void {
    console.log = function () { };
    this.Username = localStorage.getItem("UserName");
    this.SpinnerService.show();
    this.fieldservice.getListActiveFieldData()?.then((res) => {
      if (res.StatusCode == 200) {
        this.fieldValues = res.Data;
        this.getListBlock();
      }
      else if (res.StatusCode == 401 || res.StatusCode == 400) {
        this.SpinnerService.hide();
      }
      else {
        this.SpinnerService.hide();
        this.errorMessage = res.ErrorMessage ? res.ErrorMessage : "Connection Error : The database seems to be offline. Please try again later.";
        this.displayErrorModal = "block";
        this.renderer.addClass(document.body, 'modal-open');
        this.addDiv();
      }
    });
  }
  public addDiv() {
    this.trustedHtml = this._sanitizer.bypassSecurityTrustHtml("<div id='demo' class='modal-backdrop show'></div>")
  }

  addBlockModal() {
    this.previewEditorcontent = "";
    this.ckeditiorContetnt = "<html>\n<head>\n\t<title></title>\n</head>\n<body></body>\n</html>\n";
    this.displayaddblock = "block";
    this.renderer.addClass(document.body, 'modal-open');
    this.addDiv();
    this.SpinnerService.show()
    this.blockservice.getListBlockIdData()?.then((res) => {
      this.SpinnerService.hide();
      if (res.StatusCode == 200) {
        if (res.Data != '') {
          this.blockIdCount = res.Data[0].BlockId;
          this.newBlockId = this.blockIdCount + 1;
          this.GetAllBlockStatus();
        }
        else {
          this.newBlockId = 1;
          this.GetAllBlockStatus();
        }
      }
      else if (res.StatusCode == 401 || res.StatusCode == 400) {
        this.SpinnerService.hide();
      }
      else {
        this.secondPopupOpen = true;
        this.errorMessage = res.ErrorMessage ? res.ErrorMessage : "Connection Error : The database seems to be offline. Please try again later.";
        this.displayErrorModal = "block";
        this.renderer.addClass(document.body, 'modal-open');
        this.addDiv();
      }

    });
  }

  onReady(event: CKEditor4.EventInfo): void {
    this.ckeditiorContetnt = event.editor.getData();
  }

  onChange(event: CKEditor4.EventInfo): void {
    this.ckeditiorContetnt = event.editor.getData();
  }

  onFocus(event: CKEditor4.EventInfo): void {
    this.ckeditiorContetnt = event.editor.getData();
  }

  onPaste(event): void {
    let dataa = event.data.dataTransfer.getData("hashval");
    if (!dataa) {
      return;
    }
    event.data.dataValue = '<span class="card"  style="background: #A9B533; padding-top:10px;font-family: font-family:Courier New,Courier,monospace; ">' + dataa + '</span> ';
  }

  onDragStart(event): void {
    let target = event.target;
    let dataTransfer = event.dataTransfer;
    let targetname = target.textContent;
    this.datatarget = targetname;
    let hash = this.datatarget.replace(/ /g, "");
    let colorevent = '##' + hash + '##';
    dataTransfer.setData("hashval", colorevent);
    dataTransfer.setData('text/html', colorevent);
  }

  clearBlockData() {
    this.formControlsToReset = [
      'BlockName',
      'BlockDescription',
      'Status',
      'Content',
      'ChangeNotes',
    ];

    this.formControlsToReset.forEach(controlName => {
      this.blockCreateForm.controls[controlName].reset();
    });
    (document.getElementById("addlistfield") as HTMLTextAreaElement).value = "";
    (document.getElementById("editlistfield") as HTMLTextAreaElement).value = "";
    this.table1.reset();
    this.table2.reset();
  }

  closeAddBlock() {
    this.displayaddblock = "none";
    this.clearBlockData();
    this.renderer.removeClass(document.body, 'modal-open');
    let myobj = document.getElementById("demo");
    myobj?.remove();
  }

  editBlockModal(blockid: number) {
    this.table2.reset();
    this.previewEditorcontent = "";
    this.displayeditblock = "block";
    this.renderer.addClass(document.body, 'modal-open');
    this.addDiv();
    this.blockIdModel = {
      "BlockId": blockid
    }
    this.SpinnerService.show();
    this.blockservice.postBlockById(this.blockIdModel)?.then((res) => {
      this.SpinnerService.hide();
      if (res.StatusCode == 200) {
        this.viewJsonArray = res;
        this.editjsonvalue.Id = this.viewJsonArray.Data[0].BlockId;
        this.editjsonvalue.Name = this.viewJsonArray.Data[0].BlockName;
        this.editjsonvalue.Description = this.viewJsonArray.Data[0].Description;
        this.editjsonvalue.Status = this.viewJsonArray.Data[0].StatusName;
        this.editjsonvalue.StatusId = this.viewJsonArray.Data[0].StatusName;
        this.viewStatus = [{ "StatusId": this.editjsonvalue.StatusId, "StatusName": this.editjsonvalue.Status }];
        this.statusData = this.viewStatus[0];
        //this.blockEditForm.controls['Status'].setValue(this.editjsonvalue.Status);
        this.editjsonvalue.ChangeNotes = this.viewJsonArray.Data[0].ChangeNotes;
        this.editjsonvalue.Content = this.viewJsonArray.Data[0].Content;
        this.editjsonvalue.ConnectedTemplates = this.viewJsonArray.Data[0].ConnectedTemplates || 'None';
        if (this.editjsonvalue.ConnectedTemplates !== 'None') {
          this.isReadstatusOnly = true;
          // this.isReadBlockName = true;
        }
        else {
          this.isReadstatusOnly = false;
          //  this.isReadBlockName = false;
        }
        this.isReadBlockName = true;
        this.GetAllBlockStatus();
      }
      else if (res.StatusCode == 401 || res.StatusCode == 400) {
        this.SpinnerService.hide();
      }
      else {
        this.secondPopupOpen = true;
        this.errorMessage = res.ErrorMessage ? res.ErrorMessage : "Connection Error : The database seems to be offline. Please try again later.";
        this.displayErrorModal = "block";
        this.renderer.addClass(document.body, 'modal-open');
        this.addDiv();
      }
    });
  }
  closeEditblockModal() {
    (document.getElementById("editlistfield") as HTMLTextAreaElement).value = "";
    this.table2.reset();
    this.displayeditblock = "none";
    this.renderer.removeClass(document.body, 'modal-open');
    let myobj = document.getElementById("demo");
    myobj?.remove();
  }
  addClassInDashboard(content: string) {
    if (content == 'sidebar-open') {
      this.renderer.addClass(this.maintoggledrop.nativeElement, 'sidebar-open');
    } else {
      this.renderer.removeClass(this.maintoggledrop.nativeElement, 'sidebar-open');
    }
  }

  //#region Delete Header checkbox

  toggleAllCheckboxes(event) {
    this.isChecked = event.target.checked;
    this.isButtonEnabled = !this.isChecked;
    this.blockValues.forEach(item => item.checked = this.isChecked);
  }

  isAllCheckBoxChecked() {
    return this.blockValues.length > 0 && this.blockValues.every(item => item.checked);
  }

  //#endregion

  GetAllBlockStatus() {
    this.blockservice.getAllBlockStatus()?.then((res) => {
      if (res.StatusCode == 200) {
        this.blockStatusValue = res.Data.BlockStatus;
      }
      else if (res.StatusCode == 401 || res.StatusCode == 400) {
        this.SpinnerService.hide();
      }
      else {
        this.SpinnerService.hide();
        this.secondPopupOpen = true;
        this.errorMessage = res.ErrorMessage ? res.ErrorMessage : "Connection Error : The database seems to be offline. Please try again later.";
        this.displayErrorModal = "block";
        this.renderer.addClass(document.body, 'modal-open');
        this.addDiv();
      }
    });
  }

  toggleSingleCheckbox(id: number, isChecked: boolean) {
    this.deleteId = id;
    this.anyCheckboxSelected = this.blockValues.some(block => block.checked);
    this.isButtonEnabled = !this.anyCheckboxSelected;
  }
  previewBlock(blockid: number) {
    this.blockIdModel = {
      "blockId": blockid
    }
    this.SpinnerService.show();
    this.blockservice.postBlockById(this.blockIdModel)?.then((res) => {
      this.SpinnerService.hide();
      if (res.StatusCode == 200) {
        this.viewJsonArray = res;
        this.ckEditViewContent = res.Data[0].Content;
        this.previewEditorcontent = this._sanitizer.bypassSecurityTrustHtml(this.ckEditViewContent);
        this.editjsonvalue.Content = this.viewJsonArray.Data[0].Content;
      }
      else if (res.StatusCode == 401 || res.StatusCode == 400) {
        this.SpinnerService.hide();
      }
      else {
        this.errorMessage = res.ErrorMessage ? res.ErrorMessage : "Connection Error : The database seems to be offline. Please try again later.";
        this.displayErrorModal = "block";
        this.renderer.addClass(document.body, 'modal-open');
        this.addDiv();
      }
    });
  }

  saveBlockData() {
    this.clearFilterInputs();
    this.table.reset();
    if (this.blockCreateForm.valid == true) {
      this.addBlockJsonValue = {
        "BlockId": 0,
        "BlockName": this.blockCreateForm.controls['BlockName'].value.trim(),
        "Description": this.blockCreateForm.controls['BlockDescription'].value.trim(),
        "Content": this.ckeditiorContetnt,
        "ChangeNotes": this.blockCreateForm.controls['ChangeNotes'].value.trim(),
        "IsDeleted": false,
        "StatusId": this.blockCreateForm.get('Status').value,
        "CreatedOn": "2023-09-07T15:19:53.003Z",
        "ModifiedOn": "2023-09-07T15:19:53.003Z",
        "CreatedBy": this.Username,
        "DeletedBy": "null",
        "DeletedOn": "2023-09-07T15:19:53.003Z",
        "ModifiedBy": "null",
        "ConnectedTemplates": "null"
      }
      this.SpinnerService.show();
      this.blockservice.postSaveBlockData(this.addBlockJsonValue)?.then((data) => {
        this.SpinnerService.hide();
        if (data.StatusCode == 200) {
          this.displayaddblock = "none";
          this.successMessage = "Block added successfully"
          this.clearBlockData();
          this.ckeditiorContetnt = "";
          this.displaySuccessModal = "block";
          this.renderer.addClass(document.body, 'modal-open');
          this.addDiv();
          this.getListBlock();

        }
        else if (data.StatusCode == 204) {
          this.secondPopupOpen = true;
          this.errorMessage = "Block content should not be empty";
          this.displayErrorModal = "block";
          this.renderer.addClass(document.body, 'modal-open');
          this.addDiv();
        }
        else if (data.StatusCode == 400) {
          this.secondPopupOpen = true;
          this.errorMessage = "Block Cannot be Added";
          this.displayErrorModal = "block";
          this.renderer.addClass(document.body, 'modal-open');
          this.addDiv();
        }
        else if (data.StatusCode == 300) {
          this.secondPopupOpen = true;
          this.errorMessage = "Block name already exists, please provide unique block name";
          this.displayErrorModal = "block";
          this.renderer.addClass(document.body, 'modal-open');
          this.addDiv();
        }
        else {
          this.secondPopupOpen = true;
          this.errorMessage = data.ErrorMessage ? data.ErrorMessage : "Connection Error : The database seems to be offline. Please try again later.";
          this.displayErrorModal = "block";
          this.renderer.addClass(document.body, 'modal-open');
          this.addDiv();
        }
        this.previewEditorcontent = '';
      });
    }
  }

  //#region popup modal - error, success, warning

  closeWarningModal() {
    this.displayWarningModal = "none";
    if (this.secondPopupOpen == true) {
      this.secondPopupOpen = false;
    } else {
      this.renderer.removeClass(document.body, 'modal-open');
      let myobj = document.getElementById("demo");
      myobj?.remove();
    }
  }

  closeErrorModal() {
    this.displayErrorModal = "none";
    if (this.secondPopupOpen == true) {
      this.secondPopupOpen = false;
    } else {
      this.renderer.removeClass(document.body, 'modal-open');
      let myobj = document.getElementById("demo");
      myobj?.remove();
    }
  }

  closeSuccessModal() {
    this.displaySuccessModal = "none";
    this.renderer.removeClass(document.body, 'modal-open');
    let myobj = document.getElementById("demo");
    myobj?.remove();
  }

  //#endregion

  previewFullScreenPanel() {
    this.toggleFullScreen = !this.toggleFullScreen;
    // Update tooltip text
    this.tooltipText = this.toggleFullScreen ? 'Exit Full Screen' : 'Full Screen';
  }

  updateBlockData() {
    this.clearFilterInputs();
    this.table.reset();
    if (this.blockEditForm.valid == true) {

      this.blockUpdateJsonValue = {
        "BlockId": this.blockEditForm.controls['ID'].value,
        "BlockName": this.blockEditForm.controls['BlockName'].value.trim(),
        "Description": this.blockEditForm.controls['BlockDescription'].value.trim(),
        "Content": this.editjsonvalue.Content,
        "ChangeNotes": this.blockEditForm.controls['ChangeNotes'].value.trim(),
        "IsDeleted": false,
        "StatusId": this.model.selectedStatus.StatusName == "Active" ? 1 : 2,
        "CreatedOn": "2023-09-07T15:19:53.003Z",
        "ModifiedOn": "2023-09-07T15:19:53.003Z",
        "CreatedBy": this.Username,
        "DeletedBy": "null",
        "DeletedOn": "2023-09-07T15:19:53.003Z",
        "ModifiedBy": this.Username,
        "ConnectedTemplates": "null"
      }
      this.SpinnerService.show();
      this.blockservice.putUpdateBlockData(this.blockUpdateJsonValue)?.then((data) => {
        this.SpinnerService.hide();
        if (data.StatusCode == 200) {
          this.displayeditblock = "none";
          this.successMessage = "Block updated successfully"
          //data.Data.Content = "<html>\n<head>\n\t<title></title>\n</head>\n<body>\n<p><span class=\"card\" style=\"background-color:#a9b533\">##City##</span> block4</p>\n</body>\n</html>\n";
          this.editjsonvalue.Content = "";
          this.clearBlockData();
          this.displaySuccessModal = "block";
          this.renderer.addClass(document.body, 'modal-open');
          this.addDiv();
          this.getListBlock();
        }
        else if (data.StatusCode == 204) {
          this.secondPopupOpen = true;
          this.errorMessage = "Block content should not be empty";
          this.displayErrorModal = "block";
          this.renderer.addClass(document.body, 'modal-open');
          this.addDiv();
        }
        else if (data.StatusCode == 400) {
          this.secondPopupOpen = true;
          this.errorMessage = "Block Cannot be Added";
          this.displayErrorModal = "block";
          this.renderer.addClass(document.body, 'modal-open');
          this.addDiv();
        }
        else if (data.StatusCode == 300) {
          this.secondPopupOpen = true;
          this.errorMessage = "Block name already exists, please provide unique block name";
          this.displayErrorModal = "block";
          this.renderer.addClass(document.body, 'modal-open');
          this.addDiv();
        }
        else {
          this.secondPopupOpen = true;
          this.errorMessage = data.ErrorMessage ? data.ErrorMessage : "Connection Error : The database seems to be offline. Please try again later.";
          this.displayErrorModal = "block";
          this.renderer.addClass(document.body, 'modal-open');
          this.addDiv();
        }
      });
    }
  }
  deleteBlockModal() {
    this.displayDeleteModal = "block";
    this.renderer.addClass(document.body, 'modal-open');
    this.addDiv();
  }
  onCloseDeleteModal() {
    this.displayDeleteModal = "none";
    this.renderer.removeClass(document.body, 'modal-open');
    let myobj = document.getElementById("demo");
    myobj?.remove();
    this.isChecked = false;
    this.blockValues.forEach(item => item.checked = false);
    this.isButtonEnabled = true;
  }
  onDeleteblock() {
    this.displayDeleteModal = "none";
    this.selectedDeleteProducts = this.blockValues.filter(block => block.checked).map(block => block.BlockId);
    if (this.selectedDeleteProducts.length == 1) {
      this.totalDeleteId = this.selectedDeleteProducts;
      var deleteData = {
        "BlockId": this.totalDeleteId,
        "DeletedBy": this.Username,
        "IsDeleted": true,
        "ModifiedOn": "2023-09-08T14:54:53.451Z"
      }
      this.SpinnerService.show();
      this.blockservice.deleteBlockData(deleteData)?.then((res) => {
        this.SpinnerService.hide();
        if (res.StatusCode == 200) {
          if (res.Status == true) {
            this.previewEditorcontent = "";
            this.successMessage = "Block(s) deleted successfully"
            this.displaySuccessModal = "block";
            this.renderer.addClass(document.body, 'modal-open');
            this.addDiv();
            this.getListBlock();
          }
        }
        else if (res.StatusCode == 406) {
          if (res.Status == false) {
            this.previewEditorcontent = "";
            this.warningMessage = "Block(s) used in the templates are not allowed to delete";
            this.displayWarningModal = "block";
            this.renderer.addClass(document.body, 'modal-open');
            this.addDiv();
            this.getListBlock();

          }
        }
        else if (res.StatusCode == 401 || res.StatusCode == 400) {
          this.SpinnerService.hide();
        }
        else {
          this.errorMessage = res.ErrorMessage ? res.ErrorMessage : "Connection Error : The database seems to be offline. Please try again later.";
          this.displayErrorModal = "block";
          this.renderer.addClass(document.body, 'modal-open');
          this.addDiv();
        }
      });
      this.isButtonEnabled = true;
    }

    else {
      var totalDeleteIdArray: any = [];

      for (let i = 0; i < this.selectedDeleteProducts.length; i++) {
        this.totalDeleteId = this.selectedDeleteProducts[i];
        totalDeleteIdArray.push(this.totalDeleteId);
      }
      var deleteData = {
        "BlockId": totalDeleteIdArray,
        "DeletedBy": this.Username,
        "IsDeleted": true,
        "ModifiedOn": "2023-09-08T14:54:53.451Z"
      }
      this.SpinnerService.show();
      this.blockservice.deleteBlockData(deleteData)?.then((res) => {
        this.SpinnerService.hide();
        if (res.StatusCode == 200) {
          if (res.Status == true) {
            this.previewEditorcontent = "";
            this.successMessage = "Block(s) deleted successfully"
            this.displaySuccessModal = "block";
            this.renderer.addClass(document.body, 'modal-open');
            this.addDiv();
            this.getListBlock();
          }
        }
        else if (res.StatusCode == 406) {
          if (res.Status == false) {
            this.previewEditorcontent = "";
            this.warningMessage = "Block(s) used in the templates are not deleted & not used block(s) alone are deleted";
            this.displayWarningModal = "block";
            this.renderer.addClass(document.body, 'modal-open');
            this.addDiv();
            this.getListBlock();
          }
        }
        else if (res.StatusCode == 401 || res.StatusCode == 400) {
          this.SpinnerService.hide();
        }
        else {
          this.errorMessage = res.ErrorMessage ? res.ErrorMessage : "Connection Error : The database seems to be offline. Please try again later.";
          this.displayErrorModal = "block";
          this.renderer.addClass(document.body, 'modal-open');
          this.addDiv();
        }
      });
      this.isButtonEnabled = true;
    }


  }

  getListBlock() {
    this.blockservice.getListblockdata()?.then((res) => {
      if (res.StatusCode == 200) {
        this.SpinnerService.hide();
        this.blockValues = res.Data;
        this.isButtonEnabled = true;
        this.selectAllCheckBoxDisabled = this.blockValues.length === 0;
      }
      else if (res.StatusCode == 401 || res.StatusCode == 400) {
        this.SpinnerService.hide();
      }
      else {
        this.SpinnerService.hide();
        this.errorMessage = res.ErrorMessage ? res.ErrorMessage : "Connection Error : The database seems to be offline. Please try again later.";
        this.displayErrorModal = "block";
        this.renderer.addClass(document.body, 'modal-open');
        this.addDiv();
      }
    });
  }
  minLengthWithoutLeadingSpaces(minLength: number) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;

      if (value && value.trim().length < minLength) {
        return { minLengthWithoutLeadingSpaces: true };
      }

      return null;
    };
  }

  clearFilterInputs() {
    // Loop through all filter inputs and set their values to an empty string
    const filterInputs = document.querySelectorAll('.p-column-filter') as NodeListOf<HTMLInputElement>;
    filterInputs.forEach(input => {
      input.value = '';
    });

    // Clear the filter for all columns in the p-table
    this.table.filterGlobal('', 'contains');
  }

}

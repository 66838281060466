import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ServicelinksService } from '../shared/servicelinks.service';
import { DataService } from 'src/app/services/data.service';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FieldService {
  getheadersSub: Subscription;
  getHeaders: any;
  postHeaders: any;
  postheadersSub: Subscription;

  constructor(private servicelinks: ServicelinksService, private http: HttpClient, public dataService: DataService) {
    this.getheadersSub = this.dataService.getHeadService.subscribe(message => this.getHeaders = message);
    this.postheadersSub = this.dataService.postHeaderService.subscribe(message => this.postHeaders = message);
  }


  async getListActiveFieldData(): Promise<any> {
    //get
    let url = this.servicelinks.fieldListActiveUrl;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise((resolve, reject) => {
      this.http.get(url, { headers: this.getHeaders }).subscribe((response: any) => {
        localStorage.removeItem("RefreshToken");
        resolve(response);
      }, error => {
        if (error.status == 401 || error.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.get(url, { headers: this.getHeaders }).subscribe((response: any) => {
              resolve(response);
            }, error => {
              resolve(error);
            });
          }, 5000);
        } else {
          resolve(error);
        }
      });
    }
    );
  }

  async getListData(): Promise<any> {
    //get
    let url = this.servicelinks.listUrl;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise((resolve, reject) => {
      this.http.get(url, { headers: this.getHeaders }).subscribe((response: any) => {
        localStorage.removeItem("RefreshToken");
        resolve(response);
      }, error => {
        if (error.status == 401 || error.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.get(url, { headers: this.getHeaders }).subscribe((response: any) => {
              resolve(response);
            }, error => {
              resolve(error);
            });
          }, 5000);
        } else {
          resolve(error);
        }
      });
    }
    );
  }

  async getAllFieldStatus(): Promise<any> {
    //get
    let url = this.servicelinks.fieldstatusUrl;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise((resolve, reject) => {
      this.http.get(url, { headers: this.getHeaders }).subscribe((response: any) => {
        localStorage.removeItem("RefreshToken");
        resolve(response);
      }, error => {
        if (error.status == 401 || error.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.get(url, { headers: this.getHeaders }).subscribe((response: any) => {
              resolve(response);
            }, error => {
              resolve(error);
            });
          }, 5000);
        } else {
          resolve(error);
        }
      });
    }
    );
  }

  async getListIdData(): Promise<any> {
    //get
    let url = this.servicelinks.fieldidlistUrl;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise((resolve, reject) => {
      this.http.get(url, { headers: this.getHeaders }).subscribe((response: any) => {
        localStorage.removeItem("RefreshToken");
        resolve(response);
      }, error => {
        if (error.status == 401 || error.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.get(url, { headers: this.getHeaders }).subscribe((response: any) => {
              resolve(response);
            }, error => {
              resolve(error);
            });
          }, 5000);
        } else {
          resolve(error);
        }
      });
    }
    );
  }

  async getMddStatus(): Promise<any> {
    let url = this.servicelinks.fieldmddUrl;
    let refreshtoken = localStorage.getItem("RefreshToken");
    return new Promise((resolve, reject) => {
      this.http.get(url, { headers: this.getHeaders }).subscribe((response: any) => {
        resolve(response);
      }, error => {
        if (error.status == 401 || error.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.get(url, { headers: this.getHeaders }).subscribe((response: any) => {
              localStorage.removeItem("RefreshToken");
              resolve(response);
            }, error => {
              resolve(error);
            })
          }, 5000);
        }
        resolve(error);
      });
    }
    );
  }

  async postSaveFieldData(data: any): Promise<any> {
    //post
    let url = this.servicelinks.saveFieldUrl;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, this.postHeaders).subscribe(res => {
        localStorage.removeItem("RefreshToken");
        resolve(res);
      }, err => {
        if (err.status == 401 || err.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.post(url, data, this.postHeaders).subscribe(res => {
              localStorage.removeItem("RefreshToken");
              resolve(res);
            }, err => {
              resolve(err);
            });
          }, 5000);
        } else {
          resolve(err);
        }
      });
    });
  }

  async postFieldId(id: any): Promise<any> {
    //post
    let url = this.servicelinks.fieldidUrl;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise<any>((resolve, reject) => {
      this.http.post(url, id, this.postHeaders).subscribe(res => {
        localStorage.removeItem("RefreshToken");
        resolve(res);
      }, err => {
        if (err.status == 401 || err.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.post(url, id, this.postHeaders).subscribe(res => {
              localStorage.removeItem("RefreshToken");
              resolve(res);
            }, err => {
              resolve(err);
            });
          }, 5000);
        } else {
          resolve(err);
        }
      });
    });
  }

  async putUpdateFieldData(data: any): Promise<any> {
    //put
    let url = this.servicelinks.updateUrl;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise<any>((resolve, reject) => {
      this.http.put(url, data, this.postHeaders).subscribe(res => {
        localStorage.removeItem("RefreshToken");
        resolve(res);
      }, err => {
        if (err.status == 401 || err.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.put(url, data, this.postHeaders).subscribe(res => {
              localStorage.removeItem("RefreshToken");
              resolve(res);
            }, err => {
              resolve(err);
            });
          }, 5000);
        } else {
          resolve(err);
        }
      });
    });
  }


  async deleteFieldData(data: any): Promise<any> {
    //delete
    let url = this.servicelinks.deleteUrl;
    let refreshtoken = localStorage.getItem("RefreshToken");
    return new Promise<any>((resolve, reject) => {
      this.http.put(url, data, this.postHeaders).subscribe(res => {
        localStorage.removeItem("RefreshToken");
        resolve(res);
      }, err => {
        if (err.status == 401 || err.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.put(url, data, this.postHeaders).subscribe(res => {
              localStorage.removeItem("RefreshToken");
              resolve(res);
            }, err => {
              resolve(err);
            });
          }, 5000);
        } else {
          resolve(err);
        }
      });
    });
  }

}

<section>
    <div class="wrapper">
        <app-header [screenNameValue]="ScreenName" (addClassEvent)="addClassInDashboard($event)"></app-header>

        <main class="main-content p-2">
            <section class="container-fluid">
                <ngx-spinner size="medium" [type]="typeSelected">
                    <p style="color: white">{{percentageMessage}}</p>
                </ngx-spinner>
                <div>
                    <form id="bulkletterform" [formGroup]="BulkletterForm">
                        <div class="bg-white px-4 py-3 rounded mb-2 mb-md-4">
                            <div class="accordion accordion-flush" id="basic-details">
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button id="collapseButton" #collapseButton
                                            class="accordion-button collapsed p-0 fs-6 fw-semibold highlight shadow-none bg-white collapsed"
                                            type="button" data-bs-toggle="collapse"
                                            data-bs-target="#flush-collapseOneBasicDetail" (click)="collapseFilter()"
                                            aria-expanded="false" aria-controls="flush-collapseOneBasicDetail">
                                            Basic Details
                                        </button>
                                    </h2>
                                    <div id="collapseOne" #collapseOne class="accordion-collapse collapse show"
                                        data-bs-parent="#basic-details">
                                        <div class="accordion-body">
                                            <div class="row">
                                                <div class="col-12 col-md-6 col-lg-1">
                                                    <div class="mb-2 mb-md-3 mb-lg-4">
                                                        <label for="exampleFormControlInput1"
                                                            class="form-label mb-1">Batch ID
                                                        </label>
                                                        <input type="text" readonly formControlName="BatchID"
                                                            [(ngModel)]="count" class="form-control"
                                                            id="exampleFormControlInput1" placeholder="">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-2">
                                                    <div class="mb-2 mb-md-3 mb-lg-4">
                                                        <label for="exampleFormControlInput1"
                                                            class="form-label mb-1">Admin
                                                        </label>
                                                        <input type="text" readonly formControlName="Admin"
                                                            [(ngModel)]="this.Username" class="form-control"
                                                            id="exampleFormControlInput1" placeholder="Admin">
                                                    </div>
                                                </div>

                                                <div class="col-12 col-md-6 col-lg-3">
                                                    <div class="mb-2 mb-md-3 mb-lg-4">
                                                        <label for="exampleFormControlInput1"
                                                            class="form-label mb-1">Partner
                                                            (PIC) - (At
                                                            least 1)
                                                            <span class="err-color">*</span></label>

                                                        <!-- <ng-select [items]="categories" bindLabel="name" placeholder="Select Category"
                                                appendTo="body" [multiple]="true" [(ngModel)]="selected">
                                            </ng-select> -->
                                                        <ng-multiselect-dropdown name="Partner" class="myPartner"
                                                            formControlName="PartnerName"
                                                            (ngModelChange)='onChangePartner()'
                                                            [placeholder]="'Select Partner'" [(ngModel)]="selectedItems"
                                                            [settings]="dropdownSettings" [data]="partnerStatusValue">


                                                        </ng-multiselect-dropdown>
                                                    </div>
                                                </div>


                                                <div class="col-12 col-md-6 col-lg-4">
                                                    <div class="mb-2 mb-md-3 mb-lg-4">
                                                        <label for="exampleFormControlInput1"
                                                            class="form-label mb-1">Template Name
                                                            <span class="err-color">*</span></label>
                                                        <ng-select [(ngModel)]="model.selectedtemplate"
                                                            [items]="templatedynamicnames"
                                                            [readonly]="isReadtemplatesName"
                                                            formControlName="Templatename" (ngModelChange)='onChange()'
                                                            bindLabel="name" bindValue="name" [searchable]="true"
                                                            [clearable]="false" placeholder="Select Template">
                                                        </ng-select>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-2">
                                                    <div class="mb-2 mb-md-3 mb-lg-3">
                                                        <label for="exampleFormControlInput1"
                                                            class="form-label mb-1">Return Type Code
                                                            <span class="err-color">*</span></label>
                                                        <ng-multiselect-dropdown name="ReturnType" class="myPartner"
                                                            formControlName="ReturnType"
                                                            (ngModelChange)='onChangeReturnType()'
                                                            [placeholder]="'Select Code'"
                                                            [(ngModel)]="selectedcodeItems"
                                                            [settings]="dropdownReturnTypeSettings"
                                                            [data]="ReturntypeValue">
                                                        </ng-multiselect-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-lg-end gap-3 flex-wrap">
                                                <button type="button" [hidden]="Discardgoback"
                                                    (click)="backToBatchList()" class="btn p-btn-outline">Discard & Go
                                                    Back to Batch List
                                                </button>
                                                <button type="button" [hidden]="BacktoDashboard"
                                                    (click)="backToDashboard()" class="btn p-btn-outline">Back To
                                                    Dashboard</button>
                                                <button type="button" [hidden]="Clearbulkletter" (click)="getClear()"
                                                    class="btn p-btn-outline">Clear</button>
                                                <button type="button" [hidden]="loadClientDetails"
                                                    (click)="getFetchClientdetails()" class="btn p-btn"
                                                    [disabled]="!BulkletterForm.valid">Load Client
                                                    Details</button>
                                                <button type="button" [hidden]="reloadClientDetails"
                                                    (click)="editFetchClientdetails()" class="btn p-btn"
                                                    [disabled]="!(isreloadavail && BulkletterForm.valid)">Load Client
                                                    Details</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </form>
                    <div *ngIf="this.Batchletterlist == true"
                        class="bg-white px-4 py-3 rounded mb-2 mb-md-4 bulk-letter-table">
                        <div class="d-flex justify-content-between flex-wrap mb-2">

                            <!-- <div class="mb-md-3 mb-lg-3">
                                <input type="text" class="form-control" id="exampleFormControlInput1"
                                    placeholder="Search by Client ID/Name">
                            </div> -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive position-relative">
                            <p-table #dt [value]="clientData" styleClass="p-datatable-gridlines" dataKey="id"
                                [rows]="10" [resizableColumns]="true" sortMode="multiple" [scrollable]="true"
                                scrollHeight="1000px" [showCurrentPageReport]="true" [paginator]="true"
                                [(first)]="firstpage" currentPageReportTemplate="{first} to {last} of {totalRecords}"
                                [globalFilterFields]="['ClientId','ClientName','PartnerName','Office','SignatoryEmailId','SignatoryFirstName', 'ReturnTypeCode']"
                                (onFilter)="updateAndFilter($event)">
                                <ng-template pTemplate="colgroup">
                                    <colgroup>
                                        <!-- <col style="width:38%"> -->
                                        <col style="width:10%">
                                        <col style="width:30%">
                                        <col style="width:50%">
                                        <col style="width:35%">
                                        <col style="width:35%">
                                        <col style="width:44%">
                                        <col style="width:52%">
                                        <col style="width:48%">
                                        <col style="width:24%">



                                    </colgroup>
                                </ng-template>


                                <ng-template pTemplate="caption">

                                    <div class="table-header d-flex justify-content-between align-items-center">
                                        <h5 class="highlight mb-0 fs-6 fw-semibold">Bulk Letter List </h5>
                                        <div>
                                            <button type="button" (click)="bulkClientDeleteModal()"
                                                class="btn p-btn  me-3" [disabled]="isButtonEnabled">Remove
                                                Clients</button>
                                            <button type="button" #Addclient (click)="addclientmodal()"
                                                class="btn p-btn  me-3">Add Client</button>
                                            <button type="button" class="btn p-btn me-3" data-bs-toggle="modal"
                                                data-bs-target="#add-attachment" (click)="editAttachment()"> Edit
                                                Attachments</button>
                                            <span class="p-input-icon-left">
                                                <i class="pi pi-search"></i>
                                                <input pInputText type="text"
                                                    (input)="updateAndFilter($event.target.value)"
                                                    placeholder="Search by column values" class="shadow-none" />
                                            </span>
                                        </div>
                                    </div>
                                </ng-template>

                                <ng-template pTemplate="header">
                                    <tr>
                                        <!-- <th pResizableColumn pSortableColumn="RowId">Row ID
                                            <p-sortIcon field="RowId">
                                            </p-sortIcon>
                                        </th> -->
                                        <th class="text-center"><input type="checkbox" style="width: 13px;"
                                                #selectAllCheckbox (change)="toggleAllCheckboxes($event)"
                                                [checked]="isAllCheckBoxChecked()"
                                                [disabled]="selectAllCheckBoxDisabled" /></th>
                                        <th pResizableColumn pSortableColumn="ClientId">Client ID<p-sortIcon
                                                field="ClientId">
                                            </p-sortIcon>
                                        </th>
                                        <th pResizableColumn pSortableColumn="ClientName">Client Name<p-sortIcon
                                                field="ClientName">
                                            </p-sortIcon>
                                        </th>
                                        <th pSortableColumn="PartnerName"> Partner (PIC)<p-sortIcon field="PartnerName">
                                            </p-sortIcon>
                                        </th>
                                        <th pResizableColumn pSortableColumn="Office">Office<p-sortIcon field="Office">
                                            </p-sortIcon>
                                        </th>
                                        <th pResizableColumn pSortableColumn="ReturnTypeCode">ReturnTypeCode<p-sortIcon
                                                field="ReturnTypeCode">
                                            </p-sortIcon>
                                        </th>
                                        <th pResizableColumn pSortableColumn="SignatoryFirstName">Signatory First
                                            Name<p-sortIcon field="SignatoryFirstName">
                                            </p-sortIcon>
                                        </th>
                                        <th pResizableColumn pSortableColumn="SignatoryEmailId">Signatory Email
                                            ID<p-sortIcon field="SignatoryEmailId">
                                            </p-sortIcon>
                                        </th>
                                        <th>Action</th>
                                    </tr>
                                    <!-- grid searchbox -->
                                    <!-- <tr>

                                        <th> <input pInputText type="text"
                                                (input)="dt.filter($event.target.value, 'RowId', 'contains')"
                                                class="p-column-filter"></th>
                                        <th>
                                            <input pInputText type="text"
                                                (input)="dt.filter($event.target.value, 'ClientId', 'contains')"
                                                class="p-column-filter">
                                        </th>
                                        <th> <input pInputText type="text"
                                                (input)="dt.filter($event.target.value, 'ClientName', 'contains')"
                                                class="p-column-filter"></th>
                                        <th> <input pInputText type="text"
                                                (input)="dt.filter($event.target.value, 'OfficeName', 'contains')"
                                                class="p-column-filter"></th>
                                        <th> <input pInputText type="text"
                                                (input)="dt.filter($event.target.value, 'EmailID', 'contains')"
                                                class="p-column-filter"></th>
                                        <th> <input pInputText type="text"
                                                (input)="dt.filter($event.target.value, 'SigningPerson', 'contains')"
                                                class="p-column-filter"></th>
                                        <th> <input pInputText type="text"
                                                (input)="dt.filter($event.target.value, 'Partners', 'contains')"
                                                class="p-column-filter"></th>
                                        <th></th>
                                    </tr> -->

                                </ng-template>
                                <ng-template pTemplate="body" let-client>

                                    <tr>
                                        <td class="text-center"> <input type="checkbox" [value]="client.BulkLettersId"
                                                [(ngModel)]="client.checked"
                                                (change)="toggleSingleCheckbox(client.BulkLettersId, client.checked)">
                                        </td>
                                        <td>{{client.ClientId}}</td>
                                        <td>{{client.ClientName}}</td>
                                        <td>{{client.PartnerName}}</td>
                                        <td>{{client.Office}}</td>
                                        <td>{{client.ReturnTypeCode}}</td>
                                        <td>{{client.SignatoryFirstName }}</td>
                                        <td>{{client.SignatoryEmailId}}</td>

                                        <td class="text-center align-middle">
                                            <div class="custom-icon"
                                                (click)="showMenu($event, menu, client.BulkLettersId,client.ClientId,client.ClientName,client.PartnerId,client.SignatoryEmailId)">
                                                <i class="pi pi-ellipsis-v"></i>
                                            </div>
                                            <p-menu #menu [popup]="true" [class]="menuClicked ? 'custom-menu' : ''"
                                                [model]="items">
                                            </p-menu>
                                        </td>
                                    </tr>



                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="12" style="text-align: center;">No engagement letters found</td>
                                    </tr>
                                </ng-template>

                            </p-table>
                            <div class="d-flex align-items-center justify-content-between flex-wrap excel-btn">
                                <div>
                                    <button type="button" class="link-color me-3" (click)="getexportdata()"><img
                                            class="me-2" src="assets/images/download-icon.png"
                                            alt="Download-icon">Download the above details as excel</button>
                                    <input class="form-control" type="file" hidden="true" (change)="processFile($event)"
                                        accept=".xlsx, .xls, .csv" id="file" name="file">
                                    <button type="button" class="link-color" data-bs-toggle="modal"
                                        data-bs-target="#upload-files" (click)="openFileDialog($event)"><img
                                            class="me-2" src="assets/images/uploadbulk-icon.png"
                                            alt="Download-icon">Upload bulk letter details</button>
                                </div>
                            </div>

                        </div>
                        <div class="d-flex justify-content-end flex-wrap gap-3 mt-2 mt-lg-3">

                            <button type="button" (click)="saveDraft()" #Savedraftbtn class="btn p-btn-outline">Save as
                                Draft</button>
                            <button type="button" (click)="createBulkLetterConfirmModal()" class="btn p-btn">Create Bulk
                                letter</button>
                        </div>

                    </div>
                    <!-- </div> -->

                </div>
            </section>
        </main>
    </div>
</section>
<div [innerHTML]="trustedHtml"></div>
<!-- Edit field Modal -->
<div class="modal" tabindex="-1" id="bulk-letter-edit-field" aria-modal="true" role="dialog"
    [ngStyle]="{'display':displayEditField}" [class.shadow-popup]="secondPopupOpen">
    <div class="modal-dialog  modal-dialog-centered modal-dialog-scrollable modal-xl">
        <div class="modal-content p-4">
            <div class="modal-header border-0 p-0">
                <h5 class="modal-title fs-16 highlight fw-semibold m-0">Edit Fields </h5>
                <button type="button" class="btn-close shadow-none" (click)="editFieldscloseModal()"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <p><span class="err-color">Note: </span>All the fields are mandatory</p>
            <div class="modal-body p-0">
                <form id="staticfieldsform" [formGroup]="StaticFieldsForm">
                    <div class="p-3 border rounded" id="ClientIfovalues">
                        <div class="row mb-2">
                            <span class="d-block mb-2 fw-bold">1.Client Details:</span>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Client ID
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" class="form-control" id="ClientID"
                                        placeholder="Client ID" name="ClientID" readonly
                                        [formControl]="StaticFieldsForm.controls['ClientID']"
                                        [(ngModel)]="this.PartnerValues.ClientID">
                                </div>
                            </div>
                            <div id="mddformbody" #mddformbody class="row">

                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Signatory Email ID
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" class="form-control" id="SignatoryEmailId"
                                        placeholder="Signatory Email ID" name="SignatoryEmailId"
                                        [(ngModel)]="this.PartnerValues.SignatoryEmailId"
                                        [formControl]="StaticFieldsForm.controls['SignatoryEmailId']">
                                    <ng-container *ngFor="let validation of validation_messages.SignatoryEmailId">
                                        <div class="errorvl"
                                            *ngIf="StaticFieldsForm.get('SignatoryEmailId').hasError(validation.type) && (StaticFieldsForm.get('SignatoryEmailId').dirty || StaticFieldsForm.get('SignatoryEmailId').touched)">
                                            <label class="errorcolor" *ngIf="!condition"> {{ validation.message
                                                }}</label>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3" [hidden]="isspouseemailid">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Spouse Email ID
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" class="form-control" id="SpouseEmailId"
                                        placeholder="Spouse Email ID" name="SpouseEmailId"
                                        [(ngModel)]="this.PartnerValues.SpouseEmailId"
                                        [formControl]="StaticFieldsForm.controls['SpouseEmailId']">
                                    <!-- <ng-container *ngFor="let validation of validation_messages.OfficeName">
                                <div class="err-color"
                                    *ngIf="StaticFieldsForm.get('OfficeName').hasError(validation.type) && (StaticFieldsForm.get('OfficeName').dirty || StaticFieldsForm.get('OfficeName').touched)">
                                    <label class="errorcolor "> {{ validation.message }}</label>
                                </div>
                            </ng-container> -->
                                    <ng-container *ngFor="let validation of validation_messages.SpouseEmailId">
                                        <div class="errorvl"
                                            *ngIf="StaticFieldsForm.get('SpouseEmailId').hasError(validation.type) && (StaticFieldsForm.get('SpouseEmailId').dirty || StaticFieldsForm.get('SpouseEmailId').touched)">
                                            <label class="errorcolor" *ngIf="!condition"> {{ validation.message
                                                }}</label>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>

                        </div>
                        <hr>
                        <div class="row">
                            <!-- Add title -->
                            <span class="d-block mb-2 fw-bold">2.Child Entities:</span>
                            <div class="col-lg-6">
                                <div class="row">
                                    <div class="col-md-6 col-lg-6">
                                        <div class="mb-2 mb-md-3 mb-lg-4">
                                            <label for="exampleFormControlInput1" class="form-label mb-1">Child Entities
                                            </label>
                                            <input type="text" class="form-control" id="ChildEntities"
                                                name="ChildEntities"
                                                [formControl]="StaticFieldsForm.controls['ChildEntities']"
                                                placeholder="Child Entities">
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-lg-4 d-flex align-items-center">
                                        <button type="button" #addeditclientbtn class="btn p-btn mb-2 mb-md-0"
                                            (click)=" onAddchildEntityOptions()">Add</button>
                                    </div>
                                    <div class="col-lg-8">
                                        <div class="mb-2 mb-md-3 mb-lg-4">
                                            <div class="response_tag_wrapper" *ngIf="addEntityOptions!= ''">
                                                <p *ngFor="let addRes of addEntityOptions; let i=index;"
                                                    class="response_tag">{{addRes}}<a><i class="fa fa-times"
                                                            (click)=" onRemoveEntityOptions(addRes)"
                                                            aria-hidden="true"></i></a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="row" id="officedetails">
                            <span class="d-block mb-2 fw-bold">3.Office Details:</span>

                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Partner ID
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" name="PartnerID" id="{{this.PartnerFieldId}}"
                                        required class="form-control" placeholder="Partner ID" readonly
                                        [(ngModel)]="this.PartnerValues.PartnerId"
                                        [formControl]="StaticFieldsForm.controls['PartnerID']">
                                    <!-- <ng-container *ngFor="let validation of validation_messages.PartnerID">
                                        <div class="err-color"
                                            *ngIf="StaticFieldsForm.get('PartnerID').hasError(validation.type) && (StaticFieldsForm.get('PartnerID').dirty || StaticFieldsForm.get('PartnerID').touched)">
                                            <label class="errorcolor "> {{ validation.message }}</label>
                                        </div>
                                    </ng-container> -->
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2 ">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Partner Name
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" class="form-control" name="PartnerName"
                                        [(ngModel)]="this.PartnerValues.PartnerName" id="{{this.PartnerNameFieldId}}"
                                        placeholder="Partner Name"
                                        [formControl]="StaticFieldsForm.controls['PartnerName']">
                                    <!-- <ng-container *ngFor="let validation of validation_messages.PartnerName">
                                        <div class="err-color"
                                            *ngIf="StaticFieldsForm.get('PartnerName').hasError(validation.type) && (StaticFieldsForm.get('PartnerName').dirty || StaticFieldsForm.get('PartnerName').touched)">
                                            <label class="errorcolor "> {{ validation.message }}</label>
                                        </div>
                                    </ng-container> -->
                                </div>
                            </div>

                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Office ID
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" class="form-control"
                                        id="{{this.OfficeFieldId}}" placeholder="Office ID" name="OfficeID"
                                        [(ngModel)]="this.PartnerValues.OfficeId" readonly
                                        [formControl]="StaticFieldsForm.controls['OfficeID']">
                                    <!-- <ng-container *ngFor="let validation of validation_messages.OfficeID">
                                        <div class="err-color"
                                            *ngIf="StaticFieldsForm.get('OfficeID').hasError(validation.type) && (StaticFieldsForm.get('OfficeID').dirty || StaticFieldsForm.get('OfficeID').touched)">
                                            <label class="errorcolor "> {{ validation.message }}</label>
                                        </div>
                                    </ng-container> -->
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Office Name
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" class="form-control"
                                        id="{{this.OfficeNameFieldId}}" placeholder="Office Name" name="OfficeName"
                                        [(ngModel)]="this.PartnerValues.OfficeName"
                                        [formControl]="StaticFieldsForm.controls['OfficeName']">
                                    <!-- <ng-container *ngFor="let validation of validation_messages.OfficeName">
                                        <div class="err-color"
                                            *ngIf="StaticFieldsForm.get('OfficeName').hasError(validation.type) && (StaticFieldsForm.get('OfficeName').dirty || StaticFieldsForm.get('OfficeName').touched)">
                                            <label class="errorcolor "> {{ validation.message }}</label>
                                        </div>
                                    </ng-container> -->
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Office Address
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" class="form-control"
                                        id="{{this.OfficeAddressFieldId}}" placeholder="Office Address"
                                        name="OfficeAddress" [(ngModel)]="this.PartnerValues.Address"
                                        [formControl]="StaticFieldsForm.controls['OfficeAddress']">
                                    <!-- <ng-container *ngFor="let validation of validation_messages.OfficeAddress">
                                        <div class="err-color"
                                            *ngIf="StaticFieldsForm.get('OfficeAddress').hasError(validation.type) && (StaticFieldsForm.get('OfficeAddress').dirty || StaticFieldsForm.get('OfficeAddress').touched)">
                                            <label class="errorcolor "> {{ validation.message }}</label>
                                        </div>
                                    </ng-container> -->
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Office City
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" class="form-control"
                                        id="{{this.OfficeCityFieldId}}" placeholder="Office City" name="OfficeCity"
                                        [(ngModel)]="this.PartnerValues.City"
                                        [formControl]="StaticFieldsForm.controls['OfficeCity']">
                                    <!-- <ng-container *ngFor="let validation of validation_messages.OfficeCity">
                                        <div class="err-color"
                                            *ngIf="StaticFieldsForm.get('OfficeCity').hasError(validation.type) && (StaticFieldsForm.get('OfficeCity').dirty || StaticFieldsForm.get('OfficeCity').touched)">
                                            <label class="errorcolor "> {{ validation.message }}</label>
                                        </div>
                                    </ng-container> -->
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Office State
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" class="form-control"
                                        id="{{this.OfficeStateFieldId}}" placeholder="Office State" name="OfficeState"
                                        [(ngModel)]="this.PartnerValues.State"
                                        [formControl]="StaticFieldsForm.controls['OfficeState']">
                                    <!-- <ng-container *ngFor="let validation of validation_messages.OfficeState">
                                        <div class="err-color"
                                            *ngIf="StaticFieldsForm.get('OfficeState').hasError(validation.type) && (StaticFieldsForm.get('OfficeState').dirty || StaticFieldsForm.get('OfficeState').touched)">
                                            <label class="errorcolor "> {{ validation.message }}</label>
                                        </div>
                                    </ng-container> -->
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Office Zip
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" class="form-control"
                                        id="{{this.OfficeZipCodeFieldId}}" placeholder="Office Zip" name="OfficeZip"
                                        [(ngModel)]="this.PartnerValues.ZipCode"
                                        [formControl]="StaticFieldsForm.controls['OfficeZip']">
                                    <!-- <ng-container *ngFor="let validation of validation_messages.OfficeZip">
                                        <div class="err-color"
                                            *ngIf="StaticFieldsForm.get('OfficeZip').hasError(validation.type) && (StaticFieldsForm.get('OfficeZip').dirty || StaticFieldsForm.get('OfficeZip').touched)">
                                            <label class="errorcolor "> {{ validation.message }}</label>
                                        </div>
                                    </ng-container> -->
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Office Phone
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" class="form-control"
                                        id="{{this.OfficePhoneNumberFieldId}}" placeholder="Office Phone"
                                        name="OfficePhone" [(ngModel)]="this.PartnerValues.PhoneNumber"
                                        [formControl]="StaticFieldsForm.controls['OfficePhone']">
                                    <!-- <ng-container *ngFor="let validation of validation_messages.OfficePhone">
                                        <div class="err-color"
                                            *ngIf="StaticFieldsForm.get('OfficePhone').hasError(validation.type) && (StaticFieldsForm.get('OfficePhone').dirty || StaticFieldsForm.get('OfficePhone').touched)">
                                            <label class="errorcolor "> {{ validation.message }}</label>
                                        </div>
                                    </ng-container> -->
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Jurisdiction
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" class="form-control"
                                        id="{{this.jurisdictionFieldId}}" placeholder="Jurisdiction" name="Jurisdiction"
                                        [(ngModel)]="this.PartnerValues.jurisdiction"
                                        [formControl]="StaticFieldsForm.controls['Jurisdiction']">
                                    <!-- <ng-container *ngFor="let validation of validation_messages.OfficePhone">
                                        <div class="err-color"
                                            *ngIf="StaticFieldsForm.get('OfficePhone').hasError(validation.type) && (StaticFieldsForm.get('OfficePhone').dirty || StaticFieldsForm.get('OfficePhone').touched)">
                                            <label class="errorcolor "> {{ validation.message }}</label>
                                        </div>
                                    </ng-container> -->
                                </div>
                            </div>
                            <!-- <div class="col-12 col-md-6 col-lg-4">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Citrin Name
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" class="form-control"
                                        id="exampleFormControlInput1" placeholder="Citrin Name" name="CitrinName"
                                        [formControl]="StaticFieldsForm.controls['CitrinName']">
                                    <ng-container *ngFor="let validation of validation_messages.CitrinName">
                                        <div class="err-color"
                                            *ngIf="StaticFieldsForm.get('CitrinName').hasError(validation.type) && (StaticFieldsForm.get('CitrinName').dirty || StaticFieldsForm.get('CitrinName').touched)">
                                            <label class="errorcolor "> {{ validation.message }}</label>
                                        </div>
                                    </ng-container>
                                </div>
                            </div> -->
                            <!-- <div class="col-12 col-md-6 col-lg-4">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Citrin Website
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" class="form-control"
                                        id="exampleFormControlInput1" placeholder="Citrin Website" name="CitrinWebsite"
                                        [formControl]="StaticFieldsForm.controls['CitrinWebsite']">
                                    <ng-container *ngFor="let validation of validation_messages.CitrinWebsite">
                                        <div class="err-color"
                                            *ngIf="StaticFieldsForm.get('CitrinWebsite').hasError(validation.type) && (StaticFieldsForm.get('CitrinWebsite').dirty || StaticFieldsForm.get('CitrinWebsite').touched)">
                                            <label class="errorcolor "> {{ validation.message }}</label>
                                        </div>
                                    </ng-container>
                                </div>
                            </div> -->
                        </div>
                        <hr>
                        <div>
                            <span class="d-block mb-2 fw-bold">4.Other Details:</span>
                            <div class="row">
                                <!-- <div class="col-md-6 col-lg-3 mb-2">
                                    <label for="" class="control-label mb-1">1.7216 Available?<span
                                            class="err-color">*</span></label>
                                    <div class="response_question">
                                        <div class="form-check form-check-inline">
                                            <input type="radio" name="Details7216" formControlName="Details7216"
                                                value="Yes" id="inlineRadio1" class="form-check-input">
                                            <label for="inlineRadio1" class="form-check-label">Yes</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input type="radio" name="Details7216" formControlName="Details7216"
                                                value="No" id="inlineRadio2" class="form-check-input"><label
                                                for="inlineRadio2" class="form-check-label">No</label>
                                        </div>
                                    </div>
                                </div> -->
                                <!-- <div class="col-md-6 col-lg-6 mb-2">
                                    <label for="" class="control-label mb-1">2.How Many Client Signatures?<span
                                            class="err-color">*</span></label>
                                    <div class="response_question">
                                        <div class="form-check form-check-inline">
                                            <input type="radio" name="ClientSignatures"
                                                formControlName="ClientSignatures" value="1 Client Signatures"
                                                id="inlineRadio3" class="form-check-input">
                                            <label for="inlineRadio3" class="form-check-label">1 Client
                                                Signatures</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input type="radio" name="ClientSignatures"
                                                formControlName="ClientSignatures" value="2 Client Signatures"
                                                id="inlineRadio4" class="form-check-input"><label for="inlineRadio4"
                                                class="form-check-label">2
                                                Client Signatures</label>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="col-12 col-md-6 col-lg-3">
                                    <label for="" class="control-label mb-1">E-Signing?<span
                                            class="err-color">*</span></label>
                                    <div class="response_question ">
                                        <div class="form-check form-check-inline">
                                            <input type="radio" id="inlineRadio5" name="Esigning"
                                                (change)="onItemChange($event.target.value)" formControlName="Esigning"
                                                value="true" class="form-check-input">
                                            <label for="inlineRadio5" class="form-check-label">Yes</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input type="radio" name="Esigning" id="inlineRadio6"
                                                (change)="onItemChange($event.target.value)" formControlName="Esigning"
                                                value="false" class="form-check-input"><label for="inlineRadio6"
                                                class="form-check-label">No</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-3">
                                    <div class="mb-2 ">
                                        <label for="exampleFormControlInput1" class="form-label mb-1">Return Type Code
                                            <span class="err-color">*</span></label>
                                        <input type="text" autocomplete="off" class="form-control" id="3"
                                            placeholder="ReturnTypeCode" name="ReturnTypeCode" readonly
                                            [formControl]="StaticFieldsForm.controls['ReturnTypeCode']"
                                            [(ngModel)]="this.returntypecodebind">
                                        <!-- <ng-container *ngFor="let validation of validation_messages.PartnerName">
                                            <div class="err-color"
                                                *ngIf="StaticFieldsForm.get('PartnerName').hasError(validation.type) && (StaticFieldsForm.get('PartnerName').dirty || StaticFieldsForm.get('PartnerName').touched)">
                                                <label class="errorcolor "> {{ validation.message }}</label>
                                            </div>
                                        </ng-container> -->
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="mt-3 mt-md-3 text-end sticky-bottom bg-white">
                            <button type="button" (click)="editFieldscloseModal()"
                                class="btn p-btn-outline">Close</button>
                            <button type="button" (click)="updateFields()" class="btn p-btn ms-3">Update</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- Add client Modal -->
<div class="modal" tabindex="-1" id="bulk-letter-edit-field" aria-modal="true" role="dialog"
    [ngStyle]="{'display':displayaddclient}" [class.shadow-popup]="secondPopupOpen">
    <div class="modal-dialog  modal-dialog-centered modal-dialog-scrollable modal-xl">
        <div class="modal-content p-4">
            <div class="modal-header border-0 p-0">
                <h5 class="modal-title fs-16 highlight fw-semibold m-0">Add New Client </h5>
                <button type="button" class="btn-close shadow-none" (click)="addclientscloseModal()"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <p><span class="err-color">Note: </span>All the fields are mandatory</p>
            <div class="modal-body p-0">
                <form id="staticfieldsform" [formGroup]="StaticFieldsClientForm">
                    <div class="p-3 border rounded" id="ClientIfovalues">
                        <div class="row mb-2">
                            <span class="d-block mb-2 fw-bold">1.Client Details:</span>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div id="ClientId" class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Client ID
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" name="ClientID" required class="form-control"
                                        placeholder="Client ID"
                                        [formControl]="StaticFieldsClientForm.controls['ClientID']">
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Signatory Email ID
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" class="form-control" id="SignatoryEmailId"
                                        placeholder="Signatory Email ID" name="SignatoryEmailId"
                                        [formControl]="StaticFieldsClientForm.controls['SignatoryEmailId']">
                                    <ng-container *ngFor="let validation of validation_messages.SignatoryEmailId">
                                        <div class="errorvl"
                                            *ngIf="StaticFieldsClientForm.get('SignatoryEmailId').hasError(validation.type) && (StaticFieldsClientForm.get('SignatoryEmailId').dirty || StaticFieldsClientForm.get('SignatoryEmailId').touched)">

                                            <label class="errorcolor" *ngIf="!condition">
                                                {{ validation.message }}</label>
                                        </div>
                                    </ng-container>
                                    <!-- <ng-container *ngFor="let validation of validation_messages.OfficeName">
                                        <div class="err-color"
                                            *ngIf="StaticFieldsForm.get('OfficeName').hasError(validation.type) && (StaticFieldsForm.get('OfficeName').dirty || StaticFieldsForm.get('OfficeName').touched)">
                                            <label class="errorcolor "> {{ validation.message }}</label>
                                        </div>
                                    </ng-container> -->
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3" [hidden]="isspouseemailid">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Spouse Email ID
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" class="form-control" id="SpouseEmailId"
                                        placeholder="Spouse Email ID" name="SpouseEmailId"
                                        [formControl]="StaticFieldsClientForm.controls['SpouseEmailId']">
                                    <!-- <ng-container *ngFor="let validation of validation_messages.OfficeName">
                                        <div class="err-color"
                                            *ngIf="StaticFieldsForm.get('OfficeName').hasError(validation.type) && (StaticFieldsForm.get('OfficeName').dirty || StaticFieldsForm.get('OfficeName').touched)">
                                            <label class="errorcolor "> {{ validation.message }}</label>
                                        </div>
                                    </ng-container> -->
                                    <ng-container *ngFor="let validation of validation_messages.SpouseEmailId">
                                        <div class="errorvl"
                                            *ngIf="StaticFieldsClientForm.get('SpouseEmailId').hasError(validation.type) && (StaticFieldsClientForm.get('SpouseEmailId').dirty || StaticFieldsClientForm.get('SpouseEmailId').touched)">
                                            <label class="errorcolor" *ngIf="!condition">
                                                {{ validation.message }}</label>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div id="addclientformbody" #addclientformbody class="row">


                            </div>
                            <div id="addformbody" #addformbody class="row">

                            </div>


                        </div>
                        <hr>
                        <div class="row">
                            <!-- Add title -->
                            <span class="d-block mb-2 fw-bold">2.Child Entities:</span>
                            <div class="col-lg-6">
                                <div class="row">
                                    <div class="col-md-6 col-lg-6">
                                        <div class="mb-2 mb-md-3 mb-lg-4">
                                            <label for="exampleFormControlInput1" class="form-label mb-1">Child Entities
                                            </label>
                                            <input type="text" class="form-control" id="ChildEntities"
                                                name="ChildEntities"
                                                [formControl]="StaticFieldsClientForm.controls['ChildEntities']"
                                                placeholder="Child Entities">
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-lg-4 d-flex align-items-center">
                                        <button type="button" #addclientbtn class="btn p-btn mb-2 mb-md-0"
                                            (click)=" onEditchildEntityOptions()">Add</button>
                                    </div>
                                    <div class="col-lg-8">
                                        <div class="mb-2 mb-md-3 mb-lg-4">
                                            <div class="response_tag_wrapper" *ngIf="addEntityOptions!= ''">
                                                <p *ngFor="let addRes of addEntityOptions; let i=index;"
                                                    class="response_tag">{{addRes}}<a><i class="fa fa-times"
                                                            (click)=" onEditRemoveEntityOptions(addRes)"
                                                            aria-hidden="true"></i></a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="row" id="officeadddetails">
                            <span class="d-block mb-2 fw-bold">3.Office Details:</span>

                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Partner ID
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" name="PartnerID" id="PartnerId" required
                                        class="form-control" placeholder="Partner ID" readonly
                                        [formControl]="StaticFieldsClientForm.controls['PartnerID']">
                                    <!-- <ng-container *ngFor="let validation of validation_messages.PartnerID">
                                        <div class="err-color"
                                            *ngIf="StaticFieldsForm.get('PartnerID').hasError(validation.type) && (StaticFieldsForm.get('PartnerID').dirty || StaticFieldsForm.get('PartnerID').touched)">
                                            <label class="errorcolor "> {{ validation.message }}</label>
                                        </div>
                                    </ng-container> -->
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2 ">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Partner Name
                                        <span class="err-color">*</span></label>
                                    <!-- <input type="text" autocomplete="off" class="form-control" name="PartnerName"
                                        [(ngModel)]="this.PartnerValues.PartnerName" id="exampleFormControlInput1"
                                        placeholder="Partner Name"
                                        [formControl]="StaticFieldsForm.controls['PartnerName']"> -->
                                    <ng-select formControlName="PartnerName" bindLabel="PartnerName" name="PartnerName"
                                        id="PartnerName" [formControl]="StaticFieldsClientForm.controls['PartnerName']"
                                        [(ngModel)]="model.selectedDatatype"
                                        (change)="PopulateClientData(model.selectedDatatype);" placeholder="Select"
                                        [virtualScroll]="true" required [searchable]="true" [clearable]="false">

                                        <ng-option *ngFor="let clients of fileteredpartner" value={{clients.PartnerId}}>

                                            {{clients.PartnerName}}
                                        </ng-option>
                                    </ng-select>
                                    <!-- <ng-container *ngFor="let validation of validation_messages.PartnerName">
                                        <div class="err-color"
                                            *ngIf="StaticFieldsForm.get('PartnerName').hasError(validation.type) && (StaticFieldsForm.get('PartnerName').dirty || StaticFieldsForm.get('PartnerName').touched)">
                                            <label class="errorcolor "> {{ validation.message }}</label>
                                        </div>
                                    </ng-container> -->
                                </div>
                            </div>


                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Office ID
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" class="form-control" id="OfficeId"
                                        placeholder="Office ID" name="OfficeID"
                                        [formControl]="StaticFieldsClientForm.controls['OfficeID']">
                                    <!-- <ng-container *ngFor="let validation of validation_messages.OfficeID">
                                        <div class="err-color"
                                            *ngIf="StaticFieldsForm.get('OfficeID').hasError(validation.type) && (StaticFieldsForm.get('OfficeID').dirty || StaticFieldsForm.get('OfficeID').touched)">
                                            <label class="errorcolor "> {{ validation.message }}</label>
                                        </div>
                                    </ng-container> -->
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Office Name
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" class="form-control" id="Officename"
                                        placeholder="Office Name" name="OfficeName"
                                        [formControl]="StaticFieldsClientForm.controls['OfficeName']">
                                    <!-- <ng-container *ngFor="let validation of validation_messages.OfficeName">
                                        <div class="err-color"
                                            *ngIf="StaticFieldsForm.get('OfficeName').hasError(validation.type) && (StaticFieldsForm.get('OfficeName').dirty || StaticFieldsForm.get('OfficeName').touched)">
                                            <label class="errorcolor "> {{ validation.message }}</label>
                                        </div>
                                    </ng-container> -->
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Office Address
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" class="form-control" id="Officeaddress"
                                        placeholder="Office Address" name="OfficeAddress"
                                        [formControl]="StaticFieldsClientForm.controls['OfficeAddress']">
                                    <!-- <ng-container *ngFor="let validation of validation_messages.OfficeAddress">
                                        <div class="err-color"
                                            *ngIf="StaticFieldsForm.get('OfficeAddress').hasError(validation.type) && (StaticFieldsForm.get('OfficeAddress').dirty || StaticFieldsForm.get('OfficeAddress').touched)">
                                            <label class="errorcolor "> {{ validation.message }}</label>
                                        </div>
                                    </ng-container> -->
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Office City
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" class="form-control" id="Officecity"
                                        placeholder="Office City" name="OfficeCity"
                                        [formControl]="StaticFieldsClientForm.controls['OfficeCity']">
                                    <!-- <ng-container *ngFor="let validation of validation_messages.OfficeCity">
                                        <div class="err-color"
                                            *ngIf="StaticFieldsForm.get('OfficeCity').hasError(validation.type) && (StaticFieldsForm.get('OfficeCity').dirty || StaticFieldsForm.get('OfficeCity').touched)">
                                            <label class="errorcolor "> {{ validation.message }}</label>
                                        </div>
                                    </ng-container> -->
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Office State
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" class="form-control" id="Officestate"
                                        placeholder="Office State" name="OfficeState"
                                        [formControl]="StaticFieldsClientForm.controls['OfficeState']">
                                    <!-- <ng-container *ngFor="let validation of validation_messages.OfficeState">
                                        <div class="err-color"
                                            *ngIf="StaticFieldsForm.get('OfficeState').hasError(validation.type) && (StaticFieldsForm.get('OfficeState').dirty || StaticFieldsForm.get('OfficeState').touched)">
                                            <label class="errorcolor "> {{ validation.message }}</label>
                                        </div>
                                    </ng-container> -->
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Office Zip
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" class="form-control" id="Officezip"
                                        placeholder="Office Zip" name="OfficeZip"
                                        [formControl]="StaticFieldsClientForm.controls['OfficeZip']">
                                    <!-- <ng-container *ngFor="let validation of validation_messages.OfficeZip">
                                        <div class="err-color"
                                            *ngIf="StaticFieldsForm.get('OfficeZip').hasError(validation.type) && (StaticFieldsForm.get('OfficeZip').dirty || StaticFieldsForm.get('OfficeZip').touched)">
                                            <label class="errorcolor "> {{ validation.message }}</label>
                                        </div>
                                    </ng-container> -->
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Office Phone
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" class="form-control" id="Officephone"
                                        placeholder="Office Phone" name="OfficePhone"
                                        [formControl]="StaticFieldsClientForm.controls['OfficePhone']">
                                    <!-- <ng-container *ngFor="let validation of validation_messages.OfficePhone">
                                        <div class="err-color"
                                            *ngIf="StaticFieldsForm.get('OfficePhone').hasError(validation.type) && (StaticFieldsForm.get('OfficePhone').dirty || StaticFieldsForm.get('OfficePhone').touched)">
                                            <label class="errorcolor "> {{ validation.message }}</label>
                                        </div>
                                    </ng-container> -->
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Jurisdiction
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" class="form-control" id="Jurisdiction"
                                        placeholder="Jurisdiction" name="Jurisdiction"
                                        [formControl]="StaticFieldsClientForm.controls['Jurisdiction']">
                                    <!-- <ng-container *ngFor="let validation of validation_messages.OfficePhone">
                                        <div class="err-color"
                                            *ngIf="StaticFieldsForm.get('OfficePhone').hasError(validation.type) && (StaticFieldsForm.get('OfficePhone').dirty || StaticFieldsForm.get('OfficePhone').touched)">
                                            <label class="errorcolor "> {{ validation.message }}</label>
                                        </div>
                                    </ng-container> -->
                                </div>
                            </div>
                            <!-- <div class="col-12 col-md-6 col-lg-4">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Citrin Name
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" class="form-control"
                                        id="exampleFormControlInput1" placeholder="Citrin Name" name="CitrinName"
                                        [formControl]="StaticFieldsForm.controls['CitrinName']">
                                    <ng-container *ngFor="let validation of validation_messages.CitrinName">
                                        <div class="err-color"
                                            *ngIf="StaticFieldsForm.get('CitrinName').hasError(validation.type) && (StaticFieldsForm.get('CitrinName').dirty || StaticFieldsForm.get('CitrinName').touched)">
                                            <label class="errorcolor "> {{ validation.message }}</label>
                                        </div>
                                    </ng-container>
                                </div>
                            </div> -->
                            <!-- <div class="col-12 col-md-6 col-lg-4">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Citrin Website
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" class="form-control"
                                        id="exampleFormControlInput1" placeholder="Citrin Website" name="CitrinWebsite"
                                        [formControl]="StaticFieldsForm.controls['CitrinWebsite']">
                                    <ng-container *ngFor="let validation of validation_messages.CitrinWebsite">
                                        <div class="err-color"
                                            *ngIf="StaticFieldsForm.get('CitrinWebsite').hasError(validation.type) && (StaticFieldsForm.get('CitrinWebsite').dirty || StaticFieldsForm.get('CitrinWebsite').touched)">
                                            <label class="errorcolor "> {{ validation.message }}</label>
                                        </div>
                                    </ng-container>
                                </div>
                            </div> -->
                        </div>
                        <hr>
                        <div>
                            <span class="d-block mb-2 fw-bold">4.Other Details:</span>
                            <div class="row">
                                <!-- <div class="col-md-6 col-lg-3 mb-2">
                                    <label for="" class="control-label mb-1">1.7216 Available?<span
                                            class="err-color">*</span></label>
                                    <div class="response_question">
                                        <div class="form-check form-check-inline">
                                            <input type="radio" name="Details7216" formControlName="Details7216"
                                                value="Yes" id="inlineRadio1" class="form-check-input">
                                            <label for="inlineRadio1" class="form-check-label">Yes</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input type="radio" name="Details7216" formControlName="Details7216"
                                                value="No" id="inlineRadio2" class="form-check-input"><label
                                                for="inlineRadio2" class="form-check-label">No</label>
                                        </div>
                                    </div>
                                </div> -->
                                <!-- <div class="col-md-6 col-lg-6 mb-2">
                                    <label for="" class="control-label mb-1">2.How Many Client Signatures?<span
                                            class="err-color">*</span></label>
                                    <div class="response_question">
                                        <div class="form-check form-check-inline">
                                            <input type="radio" name="ClientSignatures"
                                                formControlName="ClientSignatures" value="1 Client Signatures"
                                                id="inlineRadio3" class="form-check-input">
                                            <label for="inlineRadio3" class="form-check-label">1 Client
                                                Signatures</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input type="radio" name="ClientSignatures"
                                                formControlName="ClientSignatures" value="2 Client Signatures"
                                                id="inlineRadio4" class="form-check-input"><label for="inlineRadio4"
                                                class="form-check-label">2
                                                Client Signatures</label>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="col-12 col-md-6 col-lg-3">
                                    <label for="" class="control-label mb-1">E-Signing?<span
                                            class="err-color">*</span></label>
                                    <div class="response_question ">
                                        <div class="form-check form-check-inline">
                                            <input type="radio" id="inlineRadio7" name="Esigningclient"
                                                (change)="onItemChange($event.target.value)"
                                                formControlName="Esigningclient" value="true" class="form-check-input">
                                            <label for="inlineRadio7" class="form-check-label">Yes</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input type="radio" name="Esigningclient" id="inlineRadio8"
                                                (change)="onItemChange($event.target.value)"
                                                formControlName="Esigningclient" value="false"
                                                class="form-check-input"><label for="inlineRadio8"
                                                class="form-check-label">No</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-3">
                                    <div class="mb-2 ">
                                        <label for="exampleFormControlInput1" class="form-label mb-1">Return Type Code
                                            <span class="err-color">*</span></label>

                                        <ng-select formControlName="ReturnTypeCode" bindLabel="ReturnTypeCode"
                                            name="ReturnTypeCode" id="ReturnTypeCode" placeholder="Select"
                                            [formControl]="StaticFieldsClientForm.controls['ReturnTypeCode']"
                                            [(ngModel)]="model.selectedreturntype"
                                            (change)="Populatereturntype(model.selectedreturntype);"
                                            [virtualScroll]="true" required [searchable]="true" [clearable]="false">

                                            <ng-option *ngFor="let returntypes of fileteredreturntype"
                                                value={{returntypes.ReturnType}}>

                                                {{returntypes.ReturnType}}
                                            </ng-option>
                                        </ng-select>

                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="mt-3 mt-md-3 text-end sticky-bottom bg-white">
                            <button type="button" (click)="addclientscloseModal()"
                                class="btn p-btn-outline">Close</button>
                            <button type="button" (click)="addnewclient()" class="btn p-btn ms-3">Add</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- Error Modal -->
<div class="modal" tabindex="-1" id="error" [ngStyle]="{'display':displayErrorModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="error">
            <div class="modal-header error-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Error</h5>
                <button type="button" (click)="closeErrorModalexcel()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/error-icon.png" class="mb-3" alt="warning">
                <p *ngIf="this.excelerrorchecking >0" class="fs-16" style="word-wrap:break-word;text-align:center;">
                    {{errorMessage}}<br>{{errorclientid}}</p>
                <p *ngIf="this.excelerrorchecking <=0 " class="fs-16" style="word-wrap:break-word;text-align:center;">
                    {{errorMessage}}</p>
                <button type="button" (click)="closeErrorModalexcel()" class="error-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>
<!-- Error Modal Attachment -->
<div class="modal" tabindex="-1" id="attachmenterror" [ngStyle]="{'display':displayErrorModalattachment}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="attachmenterror">
            <div class="modal-header error-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Error</h5>
                <button type="button" (click)="closeErrorModal()" class="btn-close shadow-none" data-bs-dismiss="modal"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/error-icon.png" class="mb-3" alt="warning">
                <p class="fs-16"> {{errorMessage}}</p>
                <button type="button" (click)="closeErrorModal()" class="error-btn" data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>
<!-- BacktoDashboard Modal -->
<div class="modal" tabindex="-1" id="backToDashboard" [ngStyle]="{'display':displayBacktoDashboard}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="backToDashboard">
            <div class="modal-header">
                <h5 class="modal-title fs-18 fw-semibold highlight">Confirm Redirect</h5>
                <button type="button" class="btn-close shadow-none" (click)="BacktoDahboardNo()"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <p class="s-text fw-medium mb-0">Are you sure want to discard and go back to dashboard?</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn p-btn-outline" (click)="BacktoDahboardNo()">No</button>
                <button type="button" class="btn p-btn ms-2" (click)="BacktoDahboardYes()">Yes</button>
            </div>
        </div>
    </div>
</div>

<!-- Error Modal -->
<div class="modal" tabindex="-1" id="clienterror" [ngStyle]="{'display':displaynewClientErrorModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="clienterror">
            <div class="modal-header error-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Error</h5>
                <button type="button" (click)="closenewClientErrorModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/error-icon.png" class="mb-3" alt="warning">
                <p class="s-text fw-medium mb-0">Already same Client details are available in this batch, please provide
                    new client details and then submit</p>

                <button type="button" (click)="closenewClientErrorModal()" class="error-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>
<!-- Error Modal -->
<div class="modal" tabindex="-1" id="fielderror" [ngStyle]="{'display':displayFieldErrorModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="fielderror">
            <div class="modal-header error-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Error</h5>
                <button type="button" (click)="closeFieldErrorModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/error-icon.png" class="mb-3" alt="warning">
                <p class="fs-16" style="word-wrap:break-word;text-align: center;">
                    <span *ngIf="this.emptyFieldarr!=''" class="d-block text-center">Please provide input to
                        mandatory
                        field(s) - <span style="word-wrap:break-word;">{{emptyFieldarr}}</span>
                    </span><br>
                    <span class="d-block">{{signatorybotherrormessage}}</span>
                    <span class="d-block">{{signatoryspouseerrormessage}}</span>
                    <span class="d-block">{{signatorymailerrormessage}}</span>
                    <span class="d-block">{{feesErrorMessage}}</span>
                </p>
                <button type="button" (click)="closeFieldErrorModal()" class="error-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>
<!-- Delete Modal -->
<div class="modal" tabindex="-1" id="deleteModal" [ngStyle]="{'display':displayDeleteModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="deleteModal">
            <div class="modal-header">
                <h5 class="modal-title fw-semibold highlight">Confirm Delete</h5>
                <button type="button" (click)="onCloseDeleteModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <p class="s-text fw-medium mb-0">{{deletePopupMessage}}</p>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="onCloseDeleteModal()" class="btn p-btn-outline"
                    data-bs-dismiss="modal">No</button>
                <button type="button" (click)="deleteBulkLetterById()" class="btn p-btn ms-2">Yes</button>
            </div>
        </div>
    </div>
</div>
<!-- create bulk letter confirm modal -->
<div class="modal" tabindex="-1" id="createBulkletterConfirmPopup"
    [ngStyle]="{'display':displayCreateBulkletterConfirmPopup}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="createBulkletterConfirmPopup">
            <div class="modal-header">
                <h5 class="modal-title fw-semibold highlight">Confirm</h5>
                <button type="button" (click)="onCloseCreateBulkletterConfirmModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <p class="s-text fw-medium mb-0">{{CreateBulkletterConfirmPopupMessage}}</p>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="onCloseCreateBulkletterConfirmModal()" class="btn p-btn-outline"
                    data-bs-dismiss="modal">No</button>
                <button type="button" (click)="createbulkletter()" class="btn p-btn ms-2">Yes</button>
            </div>
        </div>
    </div>
</div>
<!-- Client Bulk Delete Modal -->
<div class="modal" tabindex="-1" id="bulkClientDeleteModal" [ngStyle]="{'display':displayBulkClientDeleteModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="bulkClientDeleteModal">
            <div class="modal-header">
                <h5 class="modal-title fw-semibold highlight">Confirm Delete</h5>
                <button type="button" (click)="onCloseBulkClientDeleteModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <p class="s-text fw-medium mb-0">{{bulkClientdeletePopupMessage}}</p>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="onCloseBulkClientDeleteModal()" class="btn p-btn-outline"
                    data-bs-dismiss="modal">No</button>
                <button type="button" (click)="deleteBulkClientLetterById()" class="btn p-btn ms-2">Yes</button>
            </div>
        </div>
    </div>
</div>
<!-- Common Error Modal -->
<div class="modal" tabindex="-1" id="commonerror" [ngStyle]="{'display':displayCommonErrorModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="commonerror">
            <div class="modal-header error-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Error</h5>
                <button type="button" (click)="closeDeleteErrorModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/error-icon.png" class="mb-3" alt="warning">
                <p class="fs-16">{{errorMessage}}</p>
                <button type="button" (click)="closeDeleteErrorModal()" class="error-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>
<!-- warning Modal -->
<div class="modal" tabindex="-1" id="warning" [ngStyle]="{'display':displayWarningModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="warning">
            <div class="modal-header warning-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Warning</h5>
                <button type="button" (click)="closeWarningModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/warning.png" class="mb-3" alt="warning">
                <p class="fs-16">{{warningMessage}}</p>
                <p style="word-wrap:break-word;text-align:center;">{{ClientIdErrors}}</p>
                <button type="button" (click)="closeWarningModal()" class="warning-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>
<!-- Success Modal -->
<div class="modal" tabindex="-1" id="success" [ngStyle]="{'display':displaySuccessModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="success">
            <div class="modal-header success-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Success</h5>
                <button type="button" (click)="closeSuccessModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/success-icon.png" class="mb-3" alt="warning">
                <p class="fs-16">{{successMessage}}</p>
                <button type="button" (click)="closeSuccessModal()" class="success-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>

<!-- Success Modal -->
<div class="modal" tabindex="-1" id="discardsuccess" [ngStyle]="{'display':displaydiscardSuccessModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="discardsuccess">
            <div class="modal-header success-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Success</h5>
                <button type="button" (click)="closediscardSuccessModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/success-icon.png" class="mb-3" alt="warning">
                <p class="fs-16">{{successMessage}}</p>
                <button type="button" (click)="closediscardSuccessModal()" class="success-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>
<!-- Create Bulk Success Modal -->
<div class="modal" tabindex="-1" id="createsuccess" [ngStyle]="{'display':displaycreateSuccessModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="createsuccess">
            <div class="modal-header success-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Success</h5>
                <button type="button" (click)="closeSuccessModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/success-icon.png" class="mb-3" alt="warning">
                <p class="fs-16">{{successMessage}}</p>
                <p class="fs-16">{{LettersCount}}</p>
                <button type="button" (click)="okSuccessModalcreate()" class="success-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>

<!-- PreviewSurvey Modal -->
<div class="modal" id="pagetemplate" [ngStyle]="{'display':displayhide}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="model-action">

                <a class=" action_icon ml-3" data-dismiss="modal"><img src="assets/images/close.svg" class="img-fluid"
                        alt="close" /></a>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12">
                            <h5 class="model_title">Preview Template</h5>
                        </div>
                    </div>
                    <div class="row">

                        <div class="col-12 ">
                            <div class="template_preview_panel docpreview">
                                <div class="preview_header">
                                    <h5> Preview Panel</h5>

                                </div>
                                <div class="preview_body h-100">

                                    <div id="content" #surveyContent>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 text-center mt-3">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Preview Modal -->
<div class="modal" id="preview_doc" [ngStyle]="{'display':displayPreview}">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header border-0 justify-content-end">
                <button type="button" class="btn-close mx-3 mt-2 shadow-none" data-bs-dismiss="modal"
                    (click)="closePreview()"></button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12">
                            <div class="template_preview_panel doc_preview">
                                <div class="preview_body h-100">
                                    <div *ngIf="this.pdfSrc" style="height: 100%;">
                                        <pdf-viewer class="customtaxlayerremove" [src]="pdfSrc" [stick-to-page]="true"
                                            [show-borders]="true" [render-text]="true"
                                            style="display: block;width:100%;height:calc(100vh - 180px);position: relative;">
                                        </pdf-viewer>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- savedraft Modal -->
<div class="modal" tabindex="-1" id="saveDraftModal" [ngStyle]="{'display':displaysaveDraft}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="saveDraftModal">
            <div class="modal-header">
                <h5 class="modal-title fs-18 fw-semibold highlight">Confirm Save & Redirect</h5>
                <button type="button" class="btn-close shadow-none" (click)="saveDraftClose()"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <p class="s-text fw-medium mb-0">Are you sure want to save the details and go back?</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn p-btn-outline" (click)="saveDraftNo()">No</button>
                <button type="button" class="btn p-btn ms-2" (click)="saveDraftYes()">Yes</button>
            </div>
        </div>
    </div>
</div>

<!-- Success Modal -->
<div class="modal" tabindex="-1" id="savedraftsuccess" [ngStyle]="{'display':displaySuccesssavedraftModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="savedraftsuccess">
            <div class="modal-header success-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Success</h5>
                <button type="button" (click)="closeSuccesssavedraftModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/success-icon.png" class="mb-3" alt="warning">
                <p class="fs-16">{{successMessagesavedraft}}</p>
                <button type="button" (click)="okSuccessModal()" class="success-btn" data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>
<!--  Console log error Modal-->
<div class="modal" tabindex="-1" id="consoleLogerror" [ngStyle]="{'display':displayConsoleLogerror}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="consoleLogerror">
            <div class="modal-header error-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Error</h5>
                <button type="button" (click)="ondefaultLogError()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/error-icon.png" class="mb-3" alt="warning">
                <div class="fs-16">
                    <h5>Sorry, something went wrong.</h5><br>
                    <h5>To continue please logout, close the browser, login again and resume the work on
                        this letter.</h5>
                    <h5>If any problem occurs again in this letter, please take a full screen screenshot
                        showing complete browser along with date time and share with IT Support.</h5>
                </div>
                <button type="button" (click)="ondefaultLogError()" class="error-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>

<!-- Add field Modal -->
<div class="modal" tabindex="-1" id="add-attachment" [ngStyle]="{'display':displayAddAttachment}"
    [class.shadow-popup]="secondPopupOpen">
    <div class="modal-dialog modal-lg modal-dialog-centered upload-attachment">
        <div class="modal-content p-4">
            <div class="modal-header border-0 p-0">
                <h5 class="modal-title fs-16 highlight fw-semibold m-0 mb-md-2">Upload Attachments
                    <span class="fs-12 label-color fw-regular">(Maximum of “5” attachments can be
                        added)</span>
                </h5>
                <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"
                    (click)="closeAttachmentPopup()"></button>
            </div>

            <div class="modal-body p-0">
                <div class="p-4 border rounded h-100">
                    <div class="text-end">
                        <input type="file" id="upload-btn" class="btn add-btn uploadLink visually-hidden"
                            (change)="onFileSelected($event)" accept=".pdf"
                            [disabled]="this.uploadedFiles?.length >= 5">
                        <label for="upload-btn" class="btn add-btn uploadLink"
                            [ngClass]="{'disabled-link': this.uploadedFiles?.length >= 5}">
                            <img class="me-1" src="assets/images/plus-icon.png" alt="Add-icon">Add
                        </label>
                    </div>
                    <div class="pdf-upload d-flex flex-column justify-content-center align-items-center"
                        *ngIf="uploadedFiles?.length === 0 || uploadedFiles?.length === null">
                        <p class="highlight fs-5">No PDF Attached Yet</p>
                        <p>Click <label for="upload-btn" class="link-color uploadLink text-decoration-none">
                                Add
                                <input type="file" id="upload-btn" class="visually-hidden"
                                    (change)="onFileSelected($event)">
                            </label> to
                            Upload the PDF</p>
                    </div>
                    <div class="files-list py-3">
                        <ul class="m-0 list-unstyled">
                            <li *ngFor="let file of uploadedFiles; let i = index" class="files border mb-3 py-1 px-3">
                                <div class="row">
                                    <div class="col-md-9">
                                        <p class="s-text fs-14 fw-semibold mb-2">{{ file.Filename }}</p>
                                    </div>
                                    <div class="col-md-3 d-flex align-items-centre justify-content-end">
                                        <div class="d-flex justify-content-end">
                                            <a class="link-color text-decoration-none fw-medium"
                                                (click)="openAttachmentPreview(file)">Preview</a>
                                            <a class="link-color text-decoration-none fw-medium ms-3"
                                                (click)="removeFile(i)">Remove</a>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="modal-footer border-0 pb-0 pt-3 px-0">
                <div>
                    <button type="button" class="btn p-btn-outline me-3" data-bs-dismiss="modal"
                        (click)="closeAttachmentPopup()">Close</button>
                    <button type="button" class="btn p-btn" (click)="submitUploadedFile()">Submit</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="preview_doc" [ngStyle]="{'display':displayPreviewdocument}">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header border-0 justify-content-end">
                <button type="button" class="btn-close mx-3 mt-2 shadow-none" data-bs-dismiss="modal"
                    (click)="closeAttachmentPreview()"></button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12">
                            <div class="template_preview_panel doc_preview">
                                <div class="preview_body h-100">
                                    <pdf-viewer #docPdfViewer [src]="this.pdfsrcdoc" [stick-to-page]="true"
                                        [show-borders]="true" [render-text]="true"
                                        style="display: block;width:100%;height:calc(100vh - 180px);position: relative;">
                                    </pdf-viewer>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- update Success Modal -->
<div class="modal" tabindex="-1" id="success" [ngStyle]="{'display':displayupdateSuccessModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="success">
            <div class="modal-header success-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Success</h5>
                <button type="button" (click)="updateSuccessModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/success-icon.png" class="mb-3" alt="warning">
                <p class="fs-16">
                    {{updatesuccessMessage}}
                </p>
                <button type="button" (click)="updateSuccessModal()" class="success-btn"
                    data-bs-dismiss="modal">Ok</button>

            </div>
        </div>
    </div>
</div>
<!-- Idle time confirmation popup -->
<div class="modal" tabindex="-1" id="backLogModal" [ngStyle]="{'display':displayIdleConfirm}"
    [class.shadow-popup]="secondPopupOpen">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="backLogModal">
            <div class="modal-header">
                <h5 class="modal-title fw-semibold highlight">Confirm Redirect</h5>
                <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"
                    (click)="closeIdlePopup()"></button>
            </div>
            <div class="modal-body">
                <p class="s-text fw-medium mb-0">You have been inactive after creating a batch of letters. Please press
                    "Continue in this screen" or "Close" to continue to use this batch, otherwise this batch will be
                    closed and redirected to dashboard in 10minutes.</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn p-btn-outline" data-bs-dismiss="modal"
                    (click)="closeIdlePopup()">Continue in this screen</button>
                <button type="button" class="btn p-btn ms-2" (click)="SaveAndRedirect('click')">Save & redirect</button>
            </div>
        </div>
    </div>
</div>

<!-- Discard & Go Back to Batch List  Modal -->
<div class="modal" tabindex="-1" id="discardGoBack" [ngStyle]="{'display':displaydiscardgoback}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="discardGoBack">
            <div class="modal-header">
                <h5 class="modal-title fs-18 fw-semibold highlight">Confirm Redirect</h5>
                <button type="button" class="btn-close shadow-none" (click)="DiscardbatchlistNo()"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <p class="s-text fw-medium mb-0">Are you sure want to discard & go back to batch list? <br><br>Note:
                    Discarding and going back will delete this batch.
                </p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn p-btn-outline" (click)="DiscardbatchlistNo()">No</button>
                <button type="button" class="btn p-btn ms-2" (click)="BacktodiscardgobackYes()">Yes</button>
            </div>
        </div>
    </div>
</div>
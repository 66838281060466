import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ServicelinksService } from '../shared/servicelinks.service';
import { DataService } from 'src/app/services/data.service';
import { Subscription } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class LoginService {
  getheadersSub: Subscription;
  getHeaders: any;
  postHeaders: any;
  postheadersSub: Subscription;

  constructor(private servicelinks: ServicelinksService, private http: HttpClient, public dataService: DataService) {
    this.getheadersSub = this.dataService.getHeadService.subscribe(message => this.getHeaders = message);
    this.postheadersSub = this.dataService.postHeaderService.subscribe(message => this.postHeaders = message);
  }

  getScreenPermissions(data: any): Promise<any> {
    let url = this.servicelinks.getScreenPermissionsurl;
    let refreshtoken = localStorage.getItem("RefreshToken");


    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, this.postHeaders).subscribe(res => {
        resolve(res);
      }, err => {
        if (err.status == 401) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            //this.getScreenPermissions(data);
            this.http.post(url, data, this.postHeaders).subscribe(res => {
              resolve(res);
            }, err => {
              resolve(err);
            });
          }, 5000);
        }
        else {
          resolve(err);
        }
      });
    });


    // if (refreshtoken != null) {
    //   return new Promise<any>((resolve, reject) => {
    //     this.http.post(url, data, this.postHeaders).subscribe(res => {
    //       resolve(res);
    //     }, err => {
    //       resolve(err);
    //     });
    //   });
    // }
    // else {
    //   return new Promise<any>((resolve, reject) => {
    //     this.http.post(url, data, this.postHeaders).subscribe(res => {
    //       resolve(res);
    //     }, err => {
    //       if (err.status == 401) {
    //         this.dataService.refreshtoken();
    //         setTimeout(() => {
    //           this.getScreenPermissions(data);
    //         }, 5000);
    //       }
    //       else {
    //         resolve(err);
    //       }
    //     });
    //   });

    // }
  }

}

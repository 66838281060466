<section>
    <div class="wrapper">
        <app-header [screenNameValue]="ScreenName" (addClassEvent)="addClassInDashboard($event)"></app-header>
        <main class="main-content p-4" style="height: auto;" id="maintoggledrop" #maintoggledrop>
            <div class="d-inline-flex">
                <span class="err-color">Note:</span>
                <p class="label-color ms-1">All the fields are mandatory.
                </p>
            </div>
            <section class="main_content">
                <ngx-spinner size="medium" [type]="typeSelected">
                    <p style="color: white">{{percentageMessage}}</p>
                </ngx-spinner>
                <form [formGroup]="editTemplateForm">
                    <div class="template" id="addtemplate">
                        <div class=" bg-white px-4 py-3 rounded mb-2 mb-md-4">
                            <h5 class="highlight mb-lg-3 fs-18 fw-semibold">Edit Templates</h5>
                            <div class="row">
                                <div class="col-12 col-md-6 col-lg-3">
                                    <div class="mb-2 mb-md-3 mb-lg-4">
                                        <label for="exampleFormControlInput1" class="form-label mb-1">ID
                                        </label>
                                        <input type="number" formControlName="TemplateId" class="form-control"
                                            id="exampleFormControlInput1" placeholder="" value="{{this.editTemplateId}}"
                                            disabled>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-3">
                                    <div class="mb-2 mb-md-3 mb-lg-4">
                                        <label for="exampleFormControlInput1" class="form-label mb-1">Department
                                            <span class="err-color">*</span></label>
                                        <ng-select [items]="departmentList" formControlName="Department" class=""
                                            bindLabel="Department" bindValue="DepartmentId" placeholder="Select"
                                            [(ngModel)]="selectedDepartment" name="carss" [clearable]="false"
                                            (ngModelChange)="getEngageType($event)">
                                        </ng-select>
                                        <ng-container *ngFor="let validation of validation_messages.Department">
                                            <div class="errorvl"
                                                *ngIf="editTemplateForm.get('Department').hasError(validation.type) && (editTemplateForm.get('Department').dirty || editTemplateForm.get('Department').touched)">
                                                <label class="errorcolor"> {{ validation.message }}</label>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-3">
                                    <div class="mb-2 mb-md-3 mb-lg-4">
                                        <label for="exampleFormControlInput1" class="form-label mb-1">Type
                                            <span class="err-color">*</span></label>
                                        <ng-select [items]="engageTypeList" formControlName="Type" class=""
                                            bindLabel="EngageTypeName" bindValue="EngageTypeId" placeholder="Select"
                                            [(ngModel)]="selectedType" name="carss" [clearable]="false">
                                        </ng-select>
                                        <ng-container *ngFor="let validation of validation_messages.Type">
                                            <div class="errorvl"
                                                *ngIf="editTemplateForm.get('Type').hasError(validation.type) && (editTemplateForm.get('Type').dirty || editTemplateForm.get('Type').touched)">
                                                <label class="errorcolor"> {{ validation.message }}</label>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-3">
                                    <div class="mb-2 mb-md-3 mb-lg-4">
                                        <label for="exampleFormControlInput1" class="form-label mb-1">Template Name

                                            <span class="err-color">*</span></label>
                                        <input type="text" formControlName="TemplateName" class="form-control"
                                            id="exampleFormControlInput1" placeholder="Enter Template Name">
                                        <ng-container *ngFor="let validation of validation_messages.TemplateName">
                                            <div class="errorvl"
                                                *ngIf="editTemplateForm.get('TemplateName').hasError(validation.type) && (editTemplateForm.get('TemplateName').dirty || editTemplateForm.get('TemplateName').touched)">
                                                <label class="errorcolor"> {{ validation.message }}</label>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-3">
                                    <div class="mb-2 mb-md-3 mb-lg-4">
                                        <label for="exampleFormControlInput1" class="form-label mb-1">Template
                                            Description
                                            <span class="err-color">*</span></label>
                                        <textarea type="text" formControlName="Description"
                                            class="form-control input-md no-resize" id="exampleFormControlInput1"
                                            placeholder="Enter Template Description"></textarea>
                                        <ng-container *ngFor="let validation of validation_messages.Description">
                                            <div class="errorvl"
                                                *ngIf="editTemplateForm.get('Description').hasError(validation.type) && (editTemplateForm.get('Description').dirty || editTemplateForm.get('Description').touched)">
                                                <label class="errorcolor"> {{ validation.message }}</label>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-3">
                                    <div class="mb-2 mb-md-3 mb-lg-4">
                                        <label for="exampleFormControlInput1" class="form-label mb-1">Change Notes
                                            <span class="err-color">*</span></label>
                                        <textarea type="text" formControlName="ChangeNotes"
                                            class="form-control input-md no-resize" id="exampleFormControlInput1"
                                            placeholder="Enter Change Notes"></textarea>
                                        <ng-container *ngFor="let validation of validation_messages.ChangeNotes">
                                            <div class="errorvl"
                                                *ngIf="editTemplateForm.get('ChangeNotes').hasError(validation.type) && (editTemplateForm.get('ChangeNotes').dirty || editTemplateForm.get('ChangeNotes').touched)">
                                                <label class="errorcolor"> {{ validation.message }}</label>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-3">
                                    <div class="mb-2 mb-md-3 mb-lg-4">
                                        <label for="exampleFormControlInput1" class="form-label mb-1">Status
                                            <span class="err-color">*</span></label>
                                        <ng-select [items]="statusList" formControlName="Status" class=""
                                            bindLabel="StatusName" bindValue="StatusId" placeholder="Select"
                                            [(ngModel)]="selectedStatus" name="carss" [clearable]="false">
                                        </ng-select>
                                        <ng-container *ngFor="let validation of validation_messages.Status">
                                            <div class="errorvl"
                                                *ngIf="editTemplateForm.get('Status').hasError(validation.type) && (editTemplateForm.get('Status').dirty || editTemplateForm.get('Status').touched)">
                                                <label class="errorcolor"> {{ validation.message }}</label>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-3">
                                    <div class="mb-2 mb-md-3 mb-lg-4">
                                        <label for="exampleFormControlInput1" class="form-label mb-1">Attachment Count
                                            <span class="err-color">*</span></label>
                                        <input type="number" formControlName="AttachmentCount" class="form-control"
                                            id="exampleFormControlInput1" placeholder="Enter Attachment Count" readonly
                                            disabled>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-3">
                                    <div class="mb-2 mb-md-3 mb-lg-4">
                                        <label for="exampleFormControlInput1" class="form-label mb-1">Current Template
                                            Version
                                            <span class="err-color">*</span></label>
                                        <input type="number" formControlName="CurrentTemplateVersion"
                                            class="form-control" id="exampleFormControlInput1"
                                            placeholder="Enter Current Template Version" readonly disabled>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-3">
                                    <div class="mb-2 mb-md-3 mb-lg-4">
                                        <label for="exampleFormControlInput1" class="form-label mb-1">7216 Available?
                                            <span class="err-color">*</span></label>
                                        <div class="d-flex gap-3">
                                            <input id="male" type="radio" class="custom-control-input" [value]="true"
                                                name="Available7216" formControlName="Is7216Available">
                                            <label class="custom-control-label" for="male">Yes</label>
                                            <input id="male" type="radio" class="custom-control-input" [value]="false"
                                                name="Available7216" formControlName="Is7216Available">
                                            <label class="custom-control-label" for="male">No</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12 col-md-6 col-lg-4">
                                    <div class="mb-2 mb-md-3 mb-lg-4">
                                        <label for="exampleFormControlInput1" class="form-label mb-1">How Many Client
                                            Signatures?
                                            <span class="err-color">*</span></label>
                                        <div class="d-inline-flex gap-3">
                                            <input id="male" type="radio" class="custom-control-input" [value]="1"
                                                name="ClientSignature" formControlName="ClientSignature">
                                            <label class="custom-control-label" for="male">1 Client Signatures</label>
                                            <input id="male" type="radio" class="custom-control-input" [value]="2"
                                                name="ClientSignature" formControlName="ClientSignature">
                                            <label class="custom-control-label" for="male">2 Client Signatures</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 col-lg-12">
                                <div class="bg-white rounded px-4 py-3">
                                    <div class="row">
                                        <div class="col-md-9">
                                            <h5 class="highlight mb-lg-3 fs-18 fw-semibold">Assembled Blocks</h5>
                                        </div>
                                        <div class="col-md-3">
                                            <h5 class="highlight mb-lg-3 me-5 fs-18 fw-semibold text-center">List of
                                                Blocks</h5>
                                        </div>

                                    </div>
                                    <div class="block-list">
                                        <section class="survey_section">
                                            <div class="container-fluid p-0">
                                                <div id="surveyCreatorContainer">

                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-md-12 col-lg-4 mt-2 mt-lg-2">
                            <div class="bg-white rounded px-4 py-3">
                                <h5 class="highlight mb-lg-3 fs-18 fw-semibold">List of Blocks</h5>
                                <div class="block-list">
                                    <ul class="list-unstyled p-0 m-0">
                                        <li class="py-2 "><span class="me-2"><img
                                                    src="./assets/images/vertical-drag-icon.png"
                                                    alt="Drag-icon"></span>Tax-Entity 1.01
                                        </li>
                                        <li class="py-2 "><span class="me-2"><img
                                                    src="./assets/images/vertical-drag-icon.png" alt="Drag-icon"></span>
                                            Uni - Foreign Acct - 1.08
                                        </li>
                                        <li class="py-2 "><span class="me-2"><img
                                                    src="./assets/images/vertical-drag-icon.png" alt="Drag-icon"></span>
                                            Uni - Record Retention-1.01
                                        </li>
                                        <li class="py-2 "><span class="me-2"><img
                                                    src="./assets/images/vertical-drag-icon.png"
                                                    alt="Drag-icon"></span>Tax-Entity 1.01
                                        </li>
                                        <li class="py-2 "><span class="me-2"><img
                                                    src="./assets/images/vertical-drag-icon.png" alt="Drag-icon"></span>
                                            Uni - Foreign Acct - 1.08
                                        </li>
                                        <li class="py-2 "><span class="me-2"><img
                                                    src="./assets/images/vertical-drag-icon.png" alt="Drag-icon"></span>
                                            Uni - Record Retention-1.01
                                        </li>
                                        <li class="py-2 "><span class="me-2"><img
                                                    src="./assets/images/vertical-drag-icon.png"
                                                    alt="Drag-icon"></span>Tax-Entity 1.01
                                        </li>
                                        <li class="py-2 "><span class="me-2"><img
                                                    src="./assets/images/vertical-drag-icon.png" alt="Drag-icon"></span>
                                            Uni - Foreign Acct - 1.08
                                        </li>
                                        <li class="py-2 "><span class="me-2"><img
                                                    src="./assets/images/vertical-drag-icon.png" alt="Drag-icon"></span>
                                            Uni - Record Retention-1.01
                                        </li>


                                    </ul>

                                </div>
                            </div>
                        </div> -->
                        </div>
                    </div>
                    <div class="mt-3 mt-lg-4 d-flex flex-wrap justify-content-lg-end gap-3">
                        <button type="button" class="btn p-btn-outline" (click)="openBackToGrid()">Back To Grid</button>
                        <button type="button" class="btn p-btn-outline" (click)="listOfBlocks()">List of
                            Blocks</button>
                        <button type="button" class="btn p-btn-outline" data-bs-toggle="modal"
                            data-bs-target="#add-attachment" (click)="editAttachment()"> Edit Attachments</button>
                        <button type="button" class="btn p-btn-outline"
                            (click)="templatePreview('preview')">Preview</button>
                        <button type="button" class="btn p-btn" (click)="updateTemplate()"
                            [disabled]="!editTemplateForm?.valid">Update</button>
                    </div>
                </form>
            </section>

            <!-- Logout Modal -->
            <div class="modal" tabindex="-1" id="logOutModal">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title fw-semibold highlight">Confirm Logout</h5>
                            <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <p class="s-text fw-medium mb-0">Are you Sure want to Logout</p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn p-btn-outline" data-bs-dismiss="modal">No</button>
                            <button type="button" class="btn p-btn ms-2">Yes</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Add field Modal -->
            <div class="modal" tabindex="-1" id="add-attachment" [ngStyle]="{'display':displayEditAttachment}"
                [class.shadow-popup]="secondPopupOpen">
                <div class="modal-dialog modal-lg modal-dialog-centered upload-attachment">
                    <div class="modal-content p-4">
                        <div class="modal-header border-0 p-0">
                            <h5 class="modal-title fs-16 highlight fw-semibold m-0 mb-md-2">Upload Attachments
                                <span class="fs-12 label-color fw-regular">(Maximum of “5” attachments can be
                                    added)</span>
                            </h5>
                            <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal"
                                aria-label="Close" (click)="Closeattachment()"></button>
                        </div>
                        <div class="d-inline-flex">
                            <span class="err-color">Note:</span>
                            <ul class="ps-4">
                                <li class="label-color">All attachments will be sent with all letters created
                                    through this template.
                                </li>
                                <li class="label-color">There will be an option to make few exceptions while
                                    generating the batch of
                                    letters.</li>
                            </ul>
                        </div>
                        <div class="modal-body p-0">
                            <div class="p-4 border rounded h-100">
                                <div class="text-end">
                                    <input type="file" id="upload-btn" class="btn add-btn uploadLink visually-hidden"
                                        (change)="onFileSelected($event)" accept=".pdf"
                                        [disabled]="this.uploadedFiles?.length >= 5">
                                    <label for="upload-btn" class="btn add-btn uploadLink"
                                        [ngClass]="{'disabled-link': this.uploadedFiles?.length >= 5}">
                                        <img class="me-1" src="assets/images/plus-icon.png" alt="Add-icon">Add
                                    </label>
                                </div>
                                <div class="pdf-upload d-flex flex-column justify-content-center align-items-center"
                                    *ngIf="uploadedFiles?.length === 0">
                                    <p class="highlight fs-5">No PDF Attached Yet</p>
                                    <p>Click <label for="upload-btn" class="link-color uploadLink text-decoration-none">
                                            Add
                                            <input type="file" id="upload-btn" class="visually-hidden"
                                                (change)="onFileSelected($event)">
                                        </label> to
                                        Upload the PDF</p>
                                </div>
                                <div class="files-list py-3">
                                    <ul class="m-0 list-unstyled">
                                        <li *ngFor="let file of uploadedFiles; let i = index"
                                            class="files border mb-3 py-1 px-3">
                                            <div class="row">
                                                <div class="col-md-9">
                                                    <p class="s-text fs-14 fw-semibold mb-2">{{ file.Filename }}</p>
                                                </div>
                                                <div class="col-md-3 d-flex align-items-centre justify-content-end">
                                                    <div class="d-flex justify-content-end">
                                                        <a class="link-color text-decoration-none fw-medium"
                                                            (click)="openAttachmentPreview(file)">Preview</a>
                                                        <a class="link-color text-decoration-none fw-medium ms-3"
                                                            (click)="removeFile(i)">Remove</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer border-0 pb-0 pt-3 px-0">
                            <div>
                                <button type="button" class="btn p-btn-outline me-3" data-bs-dismiss="modal"
                                    (click)="Closeattachment()">Close</button>
                                <button type="button" (click)="submitUploadedFile()" class="btn p-btn">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
    <div [innerHTML]="trustedHtml"></div>
</section>

<div class="modal" id="preview_doc" [ngStyle]="{'display':displayPreviewdocument}">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header border-0 justify-content-end">
                <button type="button" class="btn-close mx-3 mt-2 shadow-none" data-bs-dismiss="modal"
                    (click)="closePreview()"></button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12">
                            <div class="template_preview_panel doc_preview">
                                <div class="preview_body h-100">
                                    <pdf-viewer #docPdfViewer [src]="this.pdfsrcdoc" [stick-to-page]="true"
                                        [show-borders]="true" [render-text]="true"
                                        style="display: block;width:100%;height:calc(100vh - 180px);position: relative;">
                                    </pdf-viewer>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal" tabindex="-1" id="backLogModal" [ngStyle]="{'display':displayBack}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title fw-semibold highlight">Confirm Close</h5>
                <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"
                    (click)="cancelBackToGrid()"></button>
            </div>
            <div class="modal-body">
                <p class="s-text fw-medium mb-0">Are you sure want to discard and close?</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn p-btn-outline" data-bs-dismiss="modal"
                    (click)="cancelBackToGrid()">No</button>
                <button type="button" class="btn p-btn ms-2" (click)="backToGrid()">Yes</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="editletter" [ngStyle]="{'display':displayTemplatePreview}">
    <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content" style="height: 100%;">
            <div class="modal-header justify-content-end border-0">
                <button type="button" (click)="closeTemplatePreview()" class="btn-close mx-3 mt-2"
                    data-bs-dismiss="modal"></button>
            </div>
            <div class="modal-body">
                <div class="container-fluid" style="height: 100%;">
                    <div class="row" style="height: 100%;">

                        <div class="col-12 col-sm-12 col-md-12" style="height: 100%;">
                            <div class="" style="height: 100%;">

                                <div class="preview_body" style="height: 100%;">

                                    <div *ngIf="this.pdfSrc" style="height: 100%;">
                                        <iframe id="stream_iframe" [src]="pdfSrc" width="100%" webkitallowfullscreen
                                            mozallowfullscreen allowfullscreen style="height: 100%;"></iframe>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal" tabindex="-1" id="error" [ngStyle]="{'display':displayErrorModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header error-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Error</h5>
                <button type="button" (click)="closeErrorModal()" class="btn-close shadow-none" data-bs-dismiss="modal"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/error-icon.png" class="mb-3" alt="warning">
                <p class="fs-16">{{errorMessage}}</p>
                <button type="button" (click)="closeErrorModal()" class="error-btn" data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" tabindex="-1" id="success" [ngStyle]="{'display':displaySuccessModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header success-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Success</h5>
                <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"
                    (click)="closeSuccessModal()"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/success-icon.png" class="mb-3" alt="warning">
                <p class="fs-16">{{successMessage}}</p>
                <button type="button" class="success-btn" data-bs-dismiss="modal"
                    (click)="closeSuccessModal()">Ok</button>
            </div>
        </div>
    </div>
</div>
<!-- Warning Modal -->
<div class="modal" tabindex="-1" id="warning" [ngStyle]="{'display':displayWarningModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header warning-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Warning</h5>
                <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"
                    (click)="closeWarningModal()"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/warning.png" class="mb-3" alt="warning">
                <p class="fs-16">{{warningMessage}}</p>
                <button type="button" class="warning-btn" data-bs-dismiss="modal"
                    (click)="closeWarningModal()">Ok</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="pagetemplate" [ngStyle]="{'display':displayhide}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="model-action">

                <a class=" action_icon ml-3" data-dismiss="modal"><img src="assets/images/close.svg" class="img-fluid"
                        alt="close" /></a>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12">
                            <h5 class="model_title">Preview Template</h5>
                        </div>
                    </div>
                    <div class="row">

                        <div class="col-12 ">
                            <div class="template_preview_panel docpreview">
                                <div class="preview_header">
                                    <h5> Preview Panel</h5>

                                </div>
                                <div class="preview_body h-100">

                                    <div id="content" #content>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 text-center mt-3">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Success Modal Upload-->
<div class="modal" tabindex="-1" id="success" [ngStyle]="{'display':displaySuccessModalUpload}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header success-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Success</h5>
                <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"
                    (click)="closeSuccessModalUpload()"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/success-icon.png" class="mb-3" alt="warning">
                <p class="fs-16">{{successMessageUpload}}</p>
                <button type="button" class="success-btn" data-bs-dismiss="modal"
                    (click)="closeSuccessModalUpload()">Ok</button>
            </div>
        </div>
    </div>
</div>

<!-- listOfBlocks -->

<div class="modal" tabindex="-1" id="add-attachment" [ngStyle]="{'display':displayListsOfBlocks}"
    [class.shadow-popup]="secondPopupOpen">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content p-4">
            <div class="modal-header border-0 p-0">
                <h5 class="modal-title fs-16 highlight fw-bold m-0 mb-md-2">List of Blocks (Block Name)</h5>
                <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"
                    (click)="closelistOfBlocks()"></button>
            </div>
            <div class="row">
                <div class="col-md-3 col-lg-4 fw-bold">Template Id:<span style="color:blue">&nbsp;{{templateId}}</span>
                </div>
                <div class="col-md-3 col-lg-4 fw-bold">Template Name:<span
                        style="color:blue">&nbsp;{{templateName}}</span>
                </div>
                <div class="col-md-3 col-lg-4 fw-bold">Template Version: <span
                        style="color:blue">&nbsp;{{CurrentTemplateVersion}}</span>
                </div>
            </div><br>
            <div class="modal-body p-0">
                <div class="p-3 border rounded" id="editTempBlockLists"
                    style="min-height: 200px; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                    <div class="col-md-6 col-lg-12">
                        <div *ngIf="tempBlocklist?.length > 10" style="max-height: 200px; overflow: auto;">
                            <ol>
                                <li *ngFor="let tempList of tempBlocklist">{{tempList.name}}</li>
                            </ol>
                        </div>
                        <div *ngIf="tempBlocklist?.length <= 10">
                            <ol>
                                <li *ngFor="let tempList of tempBlocklist">{{tempList.name}}</li>
                            </ol>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-12 d-flex justify-content-center align-items-center">
                        <div class="mb-2 mb-md-3 mb-lg-4">
                            <div style="text-align:center"
                                *ngIf="tempBlocklist?.length === 0 || tempBlocklist?.length === null">
                                <p class="highlight fs-5">No Blocks selected Yet</p>
                            </div>
                        </div>
                    </div>
                </div><br>
                <button type="button" style="float: right;" (click)=" closelistOfBlocks()" class="btn p-btn-outline"
                    data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
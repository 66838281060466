import { Component, OnInit, ViewChild, ElementRef, Renderer2, HostListener } from '@angular/core';
import { Table } from 'primeng/table';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from "@angular/router";
import { TemplateService } from 'src/app/services/template.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormGroup } from '@angular/forms';
@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.css']
})
export class TemplatesComponent implements OnInit {
  //Backbutton disable
  @HostListener('window:beforeunload', ['$event']) unloadNotification($event: any) {
    $event.returnValue = true;
  }

  first = 0;
  rows = 5;
  ScreenName: string = 'Templates';
  trustedHtml: SafeHtml;
  templateListForGrid = [];
  typeSelected: string;

  @ViewChild('dt') table: Table;
  @ViewChild('maintoggledrop', { static: false }) maintoggledrop: ElementRef;
  @ViewChild("delete", { static: false }) delete: ElementRef;

  // Modal variables
  displayDelete = 'none';
  displaySuccessModal = 'none';
  displayWarningModal = 'none';
  displayErrorModal = 'none;'
  successMessage;
  warningMessage;
  errorMessage;

  //Delete variables
  deleteId;
  totalDeleteId;
  Username: any;
  selectedTempID: any;

  constructor(public sanitizer: DomSanitizer, private renderer: Renderer2, private router: Router,
    public templateService: TemplateService, private spinnerServive: NgxSpinnerService) {
    this.typeSelected = 'timer';
  }

  ngOnInit(): void {
    console.log = function () { };
    this.getTemplateList();
  }

  public addDiv() {
    this.trustedHtml = this.sanitizer.bypassSecurityTrustHtml("<div id='demo' class='modal-backdrop show'></div>")
  }

  createTemplate() {
    this.router.navigate(['/templatecreate']);
  }

  editTemplate(Id) {
    this.router.navigate(['/edittemplate', Id]);
  }

  addClassInDashboard(content: string) {
    if (content == 'sidebar-open') {
      this.renderer.addClass(this.maintoggledrop.nativeElement, 'sidebar-open');
    } else {
      this.renderer.removeClass(this.maintoggledrop.nativeElement, 'sidebar-open');
    }
  }

  getTemplateList() {
    this.spinnerServive.show();
    this.templateService.getTemplateList()?.then(res => {
      if (res.StatusCode == 200) {
        this.templateListForGrid = res.Data;
        this.spinnerServive.hide();
      } else {
        this.errorMessage = res.ErrorMessage ? res.ErrorMessage : "Connection Error : The database seems to be offline. Please try again later.";
        this.displayErrorModal = "block";
        this.renderer.addClass(document.body, 'modal-open');
        this.addDiv();
      }
    })
  }

  //#region Delete
  onChangedCheckBox(id: number, isChecked: boolean) {
    this.deleteId = id;
    if (isChecked == true && this.templateListForGrid.length > 0) {
      this.renderer.removeClass(this.delete.nativeElement, 'disabled');
    }
  }

  Iddeletebtncheck(ids: any) {
    const selectedProducts = this.templateListForGrid.filter(template => template.checked).map(template => template.Id);
    for (let i = 0; i < selectedProducts.length; i++) {
      this.totalDeleteId = selectedProducts[i];
      if (selectedProducts.length == 1 || this.templateListForGrid.length == 0) {
        this.renderer.addClass(this.delete.nativeElement, 'disabled');
      }
    }
  }

  onChangecheck(isChecked: boolean) {
    if (isChecked == true && this.templateListForGrid.length > 0) {
      this.renderer.removeClass(this.delete.nativeElement, 'disabled');
    }
    else {
      this.renderer.addClass(this.delete.nativeElement, 'disabled');
    }
  }

  next() {
    this.first = this.first + this.rows;
  }

  prev() {
    this.first = this.first - this.rows;
  }

  reset() {
    this.first = 0;
  }

  isLastPage(): boolean {
    return this.templateListForGrid ? this.first === (this.templateListForGrid.length - this.rows) : true;
  }

  isFirstPage(): boolean {
    return this.templateListForGrid ? this.first === 0 : true;
  }

  checkAllCheckBox(ev) {
    this.templateListForGrid.forEach(x => x.checked = ev.target.checked);
  }

  isAllCheckBoxChecked() {
    if (this.templateListForGrid.length != 0) {
      return this.templateListForGrid.every(template => template.checked);
    }
  }

  onDeletetemplate() {
    this.selectedTempID = this.templateListForGrid.filter(template => template.checked).map(template => template.TemplateId);
    if (this.selectedTempID.length == 1) {
      this.totalDeleteId = this.selectedTempID;
      this.Username = localStorage.getItem("UserName");
      var deleteData = {
        "TemplateId": 0,
        "TemplateName": "string",
        "TemplateDescription": "string",
        "DepartmentId": 0,
        "DepartmentName": "string",
        "EngageTypeId": 0,
        "StatusId": 0,
        "ChangeNotes": "string",
        "IsDeleted": true,
        "CreatedOn": "2023-09-14T08:54:53.487Z",
        "ModifiedOn": "2023-09-14T08:54:53.487Z",
        "CreatedBy": "string",
        "ModifiedBy": "string",
        "DeletedBy": this.Username,
        "TemplateVersionId": 0,
        "TemplateLogic": "string",
        "VersionNumber": 0,
        "MasterTemplateId": 0,
        "AttachmentCount": 0,
        "AttachmentJson": "string",
        "Is7216Available": true,
        "ClientSignatureCount": 0,
        "DeleteId": this.totalDeleteId,
        "TemplateHtml": "string"
      }

      this.templateService.deleteTemplate(deleteData)?.then(res => {
        if (res.StatusCode == 200 && res.Data == true) {
          this.displayDelete = 'none';
          this.successMessage = "Template(s) deleted successfully";
          this.displaySuccessModal = 'block';
          this.templateListForGrid.forEach(x => x.checked = false);
          this.renderer.addClass(this.delete.nativeElement, 'disabled');
          this.getTemplateList();
        } else if (res.StatusCode == 406 && res.Data == false) {
          this.displayDelete = 'none';
          this.warningMessage = "Template(s) used in the engagement letters are not allowed to delete";
          this.displayWarningModal = 'block';
          this.templateListForGrid.forEach(x => x.checked = false);
          this.renderer.addClass(this.delete.nativeElement, 'disabled');
          this.getTemplateList();
        } else {
          this.errorMessage = res.ErrorMessage ? res.ErrorMessage : "Connection Error : The database seems to be offline. Please try again later.";
          this.displayErrorModal = "block";
          this.renderer.addClass(document.body, 'modal-open');
          this.addDiv();
        }
      });
    } else {
      var totalDeleteIdArray: any = [];

      for (let i = 0; i < this.selectedTempID.length; i++) {
        this.totalDeleteId = this.selectedTempID[i];
        totalDeleteIdArray.push(this.totalDeleteId);
      }

      var deleteData = {
        "TemplateId": 0,
        "TemplateName": "string",
        "TemplateDescription": "string",
        "DepartmentId": 0,
        "DepartmentName": "string",
        "EngageTypeId": 0,
        "StatusId": 0,
        "ChangeNotes": "string",
        "IsDeleted": true,
        "CreatedOn": "2023-09-14T08:54:53.487Z",
        "ModifiedOn": "2023-09-14T08:54:53.487Z",
        "CreatedBy": "string",
        "ModifiedBy": "string",
        "DeletedBy": this.Username,
        "TemplateVersionId": 0,
        "TemplateLogic": "string",
        "VersionNumber": 0,
        "MasterTemplateId": 0,
        "AttachmentCount": 0,
        "AttachmentJson": "string",
        "Is7216Available": true,
        "ClientSignatureCount": 0,
        "DeleteId": totalDeleteIdArray,
        "TemplateHtml": "string"
      }
      this.templateService.deleteTemplate(deleteData)?.then(res => {
        if (res.StatusCode == 200 && res.Data == true) {
          this.displayDelete = 'none';
          this.successMessage = "Template(s) deleted successfully";
          this.displaySuccessModal = 'block';
          this.templateListForGrid.forEach(x => x.checked = false);
          this.renderer.addClass(this.delete.nativeElement, 'disabled');
          this.getTemplateList();
        } else if (res.StatusCode == 406 && res.Data == false) {
          this.displayDelete = 'none';
          this.warningMessage = "Template(s) used in the engagement letter are not deleted & not used template(s) alone are deleted";
          this.displayWarningModal = 'block';
          this.templateListForGrid.forEach(x => x.checked = false);
          this.renderer.addClass(this.delete.nativeElement, 'disabled');
          this.getTemplateList();
        } else {
          this.errorMessage = res.ErrorMessage ? res.ErrorMessage : "Connection Error : The database seems to be offline. Please try again later.";
          this.displayErrorModal = "block";
          this.renderer.addClass(document.body, 'modal-open');
          this.addDiv();
        }

      });
    }

  }

  onCloseDelete() {
    this.displayDelete = "none";
    this.renderer.removeClass(document.body, 'modal-open');
    let myobj = document.getElementById("demo");
    myobj?.remove();

    this.templateListForGrid.forEach(x => x.checked = false);
    this.renderer.addClass(this.delete.nativeElement, 'disabled');
  }

  openDelete() {
    this.displayDelete = "block";
    this.renderer.addClass(document.body, 'modal-open');
    this.addDiv();
  }
  //#endregion

  //#region Modal 
  closeSuccessModal() {
    this.displaySuccessModal = "none";
    this.renderer.removeClass(document.body, 'modal-open');
    let myobj = document.getElementById("demo");
    myobj?.remove();
  }

  closeWarningModal() {
    this.displayWarningModal = "none";
    this.renderer.removeClass(document.body, 'modal-open');
    let myobj = document.getElementById("demo");
    myobj?.remove();
  }

  closeErrorModal() {
    this.displayErrorModal = "none";
    this.renderer.removeClass(document.body, 'modal-open');
    let myobj = document.getElementById("demo");
    myobj?.remove();
  }
  //#endregion
}

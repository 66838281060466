import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders  } from '@angular/common/http';
import {ServicelinksService} from '../shared/servicelinks.service';


let headers = new HttpHeaders({
  'Content-Type': 'application/json',
  'responseType': 'json'
});
let options = {
  headers: headers
}

@Injectable({
  providedIn: 'root'
})
export class UploadsignatureService {

  constructor(private servicelinks:ServicelinksService,private http:HttpClient) { }

  getsignaturedata(Data: any): Promise<any> {
    let url = this.servicelinks.getsignaturelistUrl;
    console.log(url);
    console.log(Data);
    return new Promise<any>((resolve, reject) => {
      this.http.get(url, Data).subscribe(res =>  {
        console.log(res);
        resolve(res);
      }, err => {
        console.log(err);
        reject(err);
      });
    });
  }
  
  savesignaturedata(data: any): Promise<any> {
    let url = this.servicelinks.addsignatureUrl;
    console.log(url);
    console.log(data);
    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data,options ).subscribe(res => { 
        console.log(res);
        resolve(res);
      }, err => {
        console.log(err);
       reject(err);
      });
    });
  }

  getSignatureById(signatureId: any): Promise<any> {
    console.log(signatureId);
        let url = this.servicelinks.getsignatureById;
        return new Promise<any>((resolve, reject) => {
          this.http.get(`${url}/${signatureId}`)
            .subscribe(res => {
              console.log(res);
              resolve(res);
            }, err => {
              console.log(err);
              reject(err);
            });
        });
     
      }

      uploadlistiddata(Data: any): Promise<any> {
        let url = this.servicelinks.getSignatureIdList;
        console.log(url);
        console.log(Data);
        return new Promise<any>((resolve, reject) => {
          this.http.get(url, Data).subscribe(res =>  {
            console.log(res);
            resolve(res);
          }, err => {
            console.log(err);
            reject(err);
          });
        });
      }

  updatesignaturedata(data: any): Promise<any> {
    let url = this.servicelinks.updatesigantureUrl;
    console.log(url);
    console.log(data);
    return new Promise<any>((resolve, reject) => {
      this.http.put(url, data,options ).subscribe(res => { 
        console.log(res);
        resolve(res);
      }, err => {
        console.log(err);
       reject(err);
      });
    });
  }

  deletesignaturedata(id: any): Promise<any> {  
    let url = this.servicelinks.deletesignatureUrl;
    console.log(url);
    console.log(id);
    return new Promise<any>((resolve, reject) => {
      this.http.delete(url + id).subscribe(res => { 
        console.log(res);
        resolve(res);
      }, err => {
        console.log(err);
       reject(err);
      });
    });
  }

}

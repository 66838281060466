<section>
    <div class="wrapper">
        <app-header [screenNameValue]="ScreenName" (addClassEvent)="addClassInDashboard($event)"></app-header>
        <main class="main-content p-4" id="maintoggledrop" #maintoggledrop>
            <div class="text-md-end mb-3 mb-lg-4">
                <button #addbutton type="button" (click)="addBlockModal()" class="btn p-btn" data-bs-toggle="modal"
                    data-bs-target="#add-fields">Add</button>
                <button #deletebutton (click)="deleteBlockModal()" type="button" class="btn p-btn ms-2 ms-lg-3"
                    [disabled]="isButtonEnabled">Delete</button>
            </div>
            <section>
                <ngx-spinner size="medium" [type]="typeSelected">
                </ngx-spinner>
                <div>
                    <div class="row">
                        <div class="col-md-12 col-lg-7">
                            <div class="blockpreview bg-white px-4 py-3 rounded">
                                <h5 class="highlight mb-lg-3 fs-18 fw-semibold">List of Blocks</h5>
                                <!--data table -->
                                <div class="table-responsive ">
                                    <p-table #dt [value]="blockValues" styleClass="p-datatable-gridlines" dataKey="id"
                                        [resizableColumns]="true" sortMode="multiple" [scrollable]="true"
                                        scrollHeight="1000px" [filterDelay]="0" [showCurrentPageReport]="true"
                                        [paginator]="true" [rows]="10"
                                        currentPageReportTemplate=" {first} to {last} of {totalRecords} ">
                                        <ng-template pTemplate="colgroup">
                                            <colgroup>
                                                <col style="width:6%">
                                                <col style="width:12%">
                                                <col style="width:40%">
                                                <col style="width:20%">
                                                <col style="width:13%">


                                            </colgroup>
                                        </ng-template>
                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th></th>
                                                <th pResizableColumn pSortableColumn="BlockId">ID<p-sortIcon
                                                        field="BlockId">
                                                    </p-sortIcon>
                                                </th>
                                                <th pResizableColumn pSortableColumn="BlockName">Block Name<p-sortIcon
                                                        field="BlockName">
                                                    </p-sortIcon>
                                                </th>

                                                <th pResizableColumn pSortableColumn="StatusName">Status<p-sortIcon
                                                        field="StatusName">
                                                    </p-sortIcon>
                                                </th>
                                                <th pResizableColumn>Action
                                                </th>

                                            </tr>
                                            <tr>
                                                <th class="text-center"><input type="checkbox" style="width: 13px;"
                                                        (change)="toggleAllCheckboxes($event)"
                                                        [checked]="isAllCheckBoxChecked()"
                                                        [disabled]="selectAllCheckBoxDisabled" /></th>
                                                <th> <input pInputText type="text"
                                                        (input)="dt.filter($event.target.value, 'BlockId', 'contains')"
                                                        class="p-column-filter"></th>
                                                <th>
                                                    <input pInputText type="text"
                                                        (input)="dt.filter($event.target.value, 'BlockName', 'contains')"
                                                        class="p-column-filter">
                                                </th>
                                                <th> <input pInputText type="text"
                                                        (input)="dt.filter($event.target.value, 'StatusName', 'contains')"
                                                        class="p-column-filter"></th>
                                                <th></th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-block>
                                            <tr>
                                                <td class="text-center"> <input type="checkbox" [value]="block.BlockId"
                                                        [(ngModel)]="block.checked"
                                                        (change)="toggleSingleCheckbox(block.BlockId, block.checked)">
                                                </td>
                                                <td>{{block.BlockId}}</td>
                                                <td>{{block.BlockName}}</td>
                                                <td>{{block.StatusName}}</td>
                                                <td><button (click)="editBlockModal(block.BlockId)" pTooltip="Edit"
                                                        class="bg-transparent mx-1" data-bs-toggle="modal"
                                                        data-bs-target="#edit-field"><img src="assets/images/edit.png"
                                                            alt="Edit-icon"></button>
                                                    <button (click)="previewBlock(block.BlockId)" pTooltip="Preview"
                                                        class="bg-transparent mx-1" data-bs-toggle="modal"
                                                        data-bs-target="#edit-field"><img src="assets/images/eye.svg"
                                                            alt="Eye-icon" width="16" height="16"></button>
                                                </td>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="emptymessage">
                                            <tr>
                                                <td colspan="8" style="text-align: center;">No blocks found</td>
                                            </tr>
                                        </ng-template>
                                    </p-table>


                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-5 mt-3 mt-lg-0">
                            <div class="blockpreview px-4 py-4 bg-white rounded"
                                [ngClass]="toggleFullScreen ? 'panel-fullscreen' : ''">
                                <div class="preview-header mb-3 d-flex justify-content-between align-items-center">
                                    <h5 class="highlight fs-16 m-0 fw-semibold">Block Content Preview</h5>
                                    <button #fullscreen type="button" (click)="previewFullScreenPanel()"
                                        [pTooltip]="tooltipText" class="bg-transparent"><img
                                            src="assets/images/maximize-icon.png" alt="Fullscreen-icon"></button>
                                </div>
                                <div class="preview-body px-4 py-3">
                                    <p class="m-0" *ngIf="!previewEditorcontent">Select any block to preview
                                    <p>
                                    <p class="m-0" [innerHTML]="previewEditorcontent" *ngIf="previewEditorcontent"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </div>
</section>
<!-- Add field Modal -->
<div class="modal" tabindex="-1" id="add-fields" [class.shadow-popup]="secondPopupOpen"
    [ngStyle]="{'display':displayaddblock}">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content fieldtable p-3">
            <div class="modal-header border-0 p-0">
                <h5 class="modal-title fs-16 highlight fw-semibold m-0">Add New Block </h5>
                <button type="button" (click)="closeAddBlock()" class="btn-close shadow-none" data-bs-dismiss="modal"
                    aria-label="Close"></button>
            </div>
            <p><span class="err-color">Note: </span>All the fields are mandatory</p>
            <div class="modal-body p-0">
                <div class="p-2 border rounded">
                    <form [formGroup]="blockCreateForm">
                        <div class="row m-0">
                            <div class="col-12 col-md-6 col-lg-4">
                                <div class="mb-2 mb-md-2 mb-lg-2">
                                    <label for="blockid" class="form-label mb-1">ID
                                        <span class="err-color">*</span></label>
                                    <input type="text" value="{{this.newBlockId}}" disabled class="form-control"
                                        id="blockid" name="blockid" placeholder="">
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-4">
                                <div class="mb-2 mb-md-2 mb-lg-2">
                                    <label for="BlockName" class="form-label mb-1">Block Name
                                        <span class="err-color">*</span></label>
                                    <input type="text" class="form-control" name="BlockName" id="BlockName"
                                        placeholder="Enter Block Name" autocomplete="off"
                                        [formControl]="blockCreateForm.controls['BlockName']"
                                        class="form-control input-md" focusOnce />
                                    <ng-container *ngFor="let validation of validation_messages.BlockName">
                                        <div class="errorvl errorvlfname "
                                            *ngIf="blockCreateForm.get('BlockName').hasError(validation.type) && (blockCreateForm.get('BlockName').dirty || blockCreateForm.get('BlockName').touched)">
                                            <label class="errorcolor">{{ validation.type ===
                                                'minLengthWithoutLeadingSpaces' ? validation.message :
                                                validation.message }}</label>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-4">
                                <div class="mb-2 mb-md-2 mb-lg-2">
                                    <label class="form-label mb-1" for="status">
                                        Status<span class="err-color">*</span></label>
                                    <ng-select [items]="this.blockStatusValue" bindValue="StatusId"
                                        bindLabel="StatusName" formControlName="Status" placeholder="Select Status"
                                        [searchable]="false" [clearable]="false">
                                    </ng-select>
                                    <ng-container *ngFor="let validation of validation_messages.Status">
                                        <div class="errorvl"
                                            *ngIf="blockCreateForm.get('Status').hasError(validation.type) && (blockCreateForm.get('Status').dirty || blockCreateForm.get('Status').touched)">
                                            <label class="errorcolor "> {{ validation.message }}</label>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="row m-0">
                            <div class="col-12 col-md-6 col-lg-4">
                                <div class="mb-2 mb-md-2 mb-lg-2">
                                    <div class="row m-0">
                                        <div class="col-md-12 ">
                                            <label for="addlistfield" class="form-label mb-1">List of Fields
                                                <span class="err-color">*</span></label>
                                            <p-table [rowHover]="true" #dt1 [value]="fieldValues" dataKey="id"
                                                [scrollable]="true" class="listField" [filterDelay]="0"
                                                [globalFilterFields]="['FieldName']">
                                                <ng-template pTemplate="caption">
                                                    <div class="table-header border-0 p-0">
                                                        <span class="p-input-icon-left">
                                                            <input pInputText type="text" id="addlistfield"
                                                                (input)="dt1.filterGlobal($event.target.value, 'contains')"
                                                                placeholder='Search Fields' class="form-control ps-2" />
                                                        </span>
                                                    </div>
                                                </ng-template>
                                                <ng-template pTemplate="header">
                                                    <tr>
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="body" let-field>
                                                    <tr>
                                                        <td>
                                                            <ul id="mandatoryfields" class="list-unstyled drag_list m-0"
                                                                (paste)="onPaste( $event )"
                                                                (dragstart)="onDragStart($event)">
                                                                <li>
                                                                    <div class="drag" draggable="true">
                                                                        {{ field.FieldName }}
                                                                    </div>
                                                                </li>

                                                            </ul>

                                                        </td>
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="emptymessage">
                                                    <tr>
                                                        <td class="fs-14" colspan="8">No Fields to List
                                                        </td>
                                                    </tr>
                                                </ng-template>
                                            </p-table>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="col-12 col-md-8 col-lg-8">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="mb-2 mb-md-2 mb-lg-2">
                                            <label for="changenotes" class="form-label mb-1">Change Notes
                                                <span class="err-color">*</span></label>
                                            <textarea class="form-control no-resize" id="changenotes" required
                                                [formControl]="blockCreateForm.controls['ChangeNotes']"
                                                placeholder="Enter Change Notes"></textarea>
                                            <ng-container *ngFor="let validation of validation_messages.ChangeNotes">
                                                <div class="errorvl"
                                                    *ngIf="blockCreateForm.get('ChangeNotes').hasError(validation.type) && (blockCreateForm.get('ChangeNotes').dirty || blockCreateForm.get('ChangeNotes').touched)">
                                                    <label class="errorcolor"> {{ validation.message }}</label>
                                                </div>
                                            </ng-container>
                                        </div>

                                    </div>
                                    <div class="col-lg-6">
                                        <div class="mb-2 mb-md-2 mb-lg-2">
                                            <label for="blockdescription" class="form-label mb-1">Block Description
                                                <span class="err-color">*</span></label>
                                            <textarea class="form-control no-resize" id="blockdescription"
                                                [formControl]="blockCreateForm.controls['BlockDescription']"
                                                placeholder="Enter Block Description"></textarea>
                                            <ng-container
                                                *ngFor="let validation of validation_messages.BlockDescription">
                                                <div class="errorvl"
                                                    *ngIf="blockCreateForm.get('BlockDescription').hasError(validation.type) && (blockCreateForm.get('BlockDescription').dirty || blockCreateForm.get('BlockDescription').touched)">
                                                    <label class="errorcolor"> {{ validation.message }}</label>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                                <div class="">
                                    <div class="mb-2 mb-md-2 mb-lg-2">
                                        <label for="blockcontent" class="form-label mb-1">Block Content
                                            <span class="err-color">*</span></label>
                                        <ckeditor [formControl]="blockCreateForm.controls['Content']"
                                            bodyClass='document-editor' tagName="textarea" [config]="config"
                                            (ready)="onReady( $event)" (change)="onChange( $event)"
                                            (focus)="onFocus( $event)" (paste)="onPaste( $event )"
                                            (dragStart)="onDragStart( $event )">
                                        </ckeditor>
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div class="col-12 ">
                            <div class="mt-2 mt-md-2 text-md-end">
                                <button type="button" class="btn p-btn-outline"
                                    (click)="clearBlockData()">Clear</button>
                                <button type="button" class="btn p-btn ms-3" (click)="saveBlockData()"
                                    [disabled]="!blockCreateForm.valid">Save</button>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Edit Block Modal -->
<div class="modal" tabindex="-1" id="add-fields" [class.shadow-popup]="secondPopupOpen"
    [ngStyle]="{'display':displayeditblock}">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content fieldtable p-3">
            <div class="modal-header border-0 p-0">
                <h5 class="modal-title fs-16 highlight fw-semibold m-0">Edit Blocks </h5>
                <button type="button" (click)="closeEditblockModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <p><span class="err-color">Note: </span>All the fields are mandatory</p>
            <div class="modal-body p-0">
                <div class="p-2 border rounded">
                    <form [formGroup]="blockEditForm">
                        <div class="row m-0">
                            <div class="col-12 col-md-6 col-lg-4">
                                <div class="mb-2 mb-md-2 mb-lg-2">
                                    <label for="blockid" class="form-label mb-1">ID
                                        <span class="err-color">*</span></label>
                                    <input type="text" class="form-control" id="blockid"
                                        [formControl]="blockEditForm.controls['ID']" [ngModel]="this.editjsonvalue.Id"
                                        disabled placeholder="">
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-4">
                                <div class="mb-2 mb-md-2 mb-lg-2">
                                    <label for="BlockName" class="form-label mb-1">Block Name
                                        <span class="err-color">*</span></label>
                                    <input type="text" class="form-control" id="BlockName" [readonly]="isReadBlockName"
                                        [disabled]="isReadBlockName" [(ngModel)]="this.editjsonvalue.Name"
                                        [formControl]="blockEditForm.controls['BlockName']" autocomplete="off"
                                        placeholder="Enter Block Name">
                                    <ng-container *ngFor="let validation of validation_messages.BlockName">
                                        <div class="errorvl errorvlfname "
                                            *ngIf="blockEditForm.get('BlockName').hasError(validation.type) && (blockEditForm.get('BlockName').dirty || blockEditForm.get('BlockName').touched)">
                                            <label class="errorcolor "> {{ validation.message }}</label>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-4">
                                <div class="mb-2 mb-md-2 mb-lg-2">
                                    <label for="status" class="form-label mb-1">Status
                                        <span class="err-color">*</span></label>
                                    <ng-select [items]="this.blockStatusValue" [readonly]="isReadstatusOnly"
                                        [disabled]="isReadstatusOnly" bindLabel="StatusName"
                                        [formControl]="blockEditForm.controls['Status']" [(ngModel)]="this.statusData"
                                        formControlName="Status" placeholder="Select Status"
                                        [(ngModel)]="model.selectedStatus" [searchable]="false" [clearable]="false">
                                    </ng-select>
                                    <ng-container *ngFor="let validation of validation_messages.Status">
                                        <div class="errorvl"
                                            *ngIf="blockEditForm.get('Status').hasError(validation.type) && (blockEditForm.get('Status').dirty || blockEditForm.get('Status').touched)">
                                            <label class="errorcolor "> {{ validation.message }}</label>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="row m-0">
                            <div class="col-12 col-md-6 col-lg-4">
                                <div class="mb-2 mb-md-2 mb-lg-2">
                                    <div class="row m-0">
                                        <div class="col-md-12 ">
                                            <label for="editlistfield" class="form-label mb-1">List of Fields
                                                <span class="err-color">*</span></label>
                                            <p-table #dt2 [value]="fieldValues" dataKey="id" [scrollable]="true"
                                                [filterDelay]="0" [globalFilterFields]="['FieldName']">
                                                <ng-template pTemplate="caption">
                                                    <div class="table-header border-0 p-0">
                                                        <span class="p-input-icon-left">
                                                            <input pInputText type="text" id="editlistfield"
                                                                (input)="dt2.filterGlobal($event.target.value, 'contains')"
                                                                placeholder='Search Fields' class="form-control ps-2" />
                                                        </span>
                                                    </div>
                                                </ng-template>
                                                <ng-template pTemplate="header">
                                                    <tr>
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="body" let-field>
                                                    <tr>
                                                        <td>
                                                            <ul id="mandatoryfields" class="list-unstyled drag_list m-0"
                                                                (paste)="onPaste( $event )"
                                                                (dragstart)="onDragStart($event)">
                                                                <li>
                                                                    <div class="drag" draggable="true">
                                                                        {{ field.FieldName }}
                                                                    </div>
                                                                </li>

                                                            </ul>

                                                        </td>
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="emptymessage">
                                                    <tr>
                                                        <td class="fs-14" colspan="8">No Fields to List
                                                        </td>
                                                    </tr>
                                                </ng-template>
                                            </p-table>
                                        </div>
                                        <div class="col-12">
                                            <div class="mt-2 mb-lg-2">
                                                <label for="connectedtemplate" class="form-label mb-1">Connected
                                                    Templates
                                                    <span class="err-color">*</span></label>
                                                <textarea class="form-control no-resize" id="connectedtemplate"
                                                    [formControl]="blockEditForm.controls['ConnectedTemplate']"
                                                    [(ngModel)]="this.editjsonvalue.ConnectedTemplates"
                                                    disabled></textarea>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="col-12 col-md-8 col-lg-8">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="mb-2 mb-md-2 mb-lg-2">
                                            <label for="changenotes" class="form-label mb-1">Change Notes
                                                <span class="err-color">*</span></label>
                                            <textarea class="form-control no-resize" id="changenotes"
                                                [formControl]="blockEditForm.controls['ChangeNotes']"
                                                [(ngModel)]="this.editjsonvalue.ChangeNotes"
                                                placeholder="Enter Change Notes"></textarea>
                                            <ng-container *ngFor="let validation of validation_messages.ChangeNotes">
                                                <div class="errorvl"
                                                    *ngIf="blockEditForm.get('ChangeNotes').hasError(validation.type) && (blockEditForm.get('ChangeNotes').dirty || blockEditForm.get('ChangeNotes').touched)">
                                                    <label class="errorcolor"> {{ validation.message }}</label>
                                                </div>
                                            </ng-container>
                                        </div>

                                    </div>
                                    <div class="col-lg-6">
                                        <div class="mb-2 mb-md-2 mb-lg-2">
                                            <label for="blockdescription" class="form-label mb-1">Block Description
                                                <span class="err-color">*</span></label>
                                            <textarea class="form-control no-resize" id="blockdescription"
                                                [formControl]="blockEditForm.controls['BlockDescription']"
                                                [(ngModel)]="this.editjsonvalue.Description"
                                                placeholder="Enter Block Description"></textarea>
                                            <ng-container
                                                *ngFor="let validation of validation_messages.BlockDescription">
                                                <div class="errorvl"
                                                    *ngIf="blockEditForm.get('BlockDescription').hasError(validation.type) && (blockEditForm.get('BlockDescription').dirty || blockEditForm.get('BlockDescription').touched)">
                                                    <label class="errorcolor"> {{ validation.message }}</label>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                                <div class="">
                                    <div class="mb-2 mb-md-2 mb-lg-2">
                                        <label for="blockcontent" class="form-label mb-1">Block Content
                                            <span class="err-color">*</span></label>
                                        <ckeditor [(data)]="this.editjsonvalue.Content"
                                            [formControl]="blockEditForm.controls['Content']"
                                            bodyClass='document-editor' tagName="textarea" [config]="config"
                                            (ready)="onReady( $event)" (change)="onChange( $event)"
                                            (focus)="onFocus( $event)" (paste)="onPaste( $event )"
                                            (dragStart)="onDragStart( $event )">
                                        </ckeditor>
                                    </div>
                                </div>

                            </div>

                        </div>


                        <div class="col-12 ">
                            <div class="text-md-end">
                                <button type="button" (click)="closeEditblockModal()"
                                    class="btn p-btn-outline">Cancel</button>
                                <button type="button" (click)="updateBlockData()" [disabled]="!blockEditForm.valid "
                                    class="btn p-btn ms-3" disabled>Update</button>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Delete Modal -->
<div class="modal" tabindex="-1" id="deleteModal" [ngStyle]="{'display':displayDeleteModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title fw-semibold highlight">Confirm Delete</h5>
                <button type="button" (click)="onCloseDeleteModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <p class="s-text fw-medium mb-0">Are you sure want to delete the Block(s)?</p>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="onCloseDeleteModal()" class="btn p-btn-outline"
                    data-bs-dismiss="modal">No</button>
                <button type="button" (click)="onDeleteblock()" class="btn p-btn ms-2">Yes</button>
            </div>
        </div>
    </div>
</div>
<!-- warning Modal -->
<div class="modal" tabindex="-1" id="warning" [ngStyle]="{'display':displayWarningModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header warning-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Warning</h5>
                <button type="button" (click)="closeWarningModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/warning.png" class="mb-3" alt="warning">
                <p class="fs-16">{{warningMessage}}</p>
                <button type="button" (click)="closeWarningModal()" class="warning-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>
<!-- Error Modal -->
<div class="modal" tabindex="-1" id="error" [ngStyle]="{'display':displayErrorModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header error-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Error</h5>
                <button type="button" (click)="closeErrorModal()" class="btn-close shadow-none" data-bs-dismiss="modal"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/error-icon.png" class="mb-3" alt="warning">
                <p class="fs-16">{{errorMessage}}</p>
                <button type="button" (click)="closeErrorModal()" class="error-btn" data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>
<!-- Success Modal -->
<div class="modal" tabindex="-1" id="success" [ngStyle]="{'display':displaySuccessModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header success-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Success</h5>
                <button type="button" (click)="closeSuccessModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/success-icon.png" class="mb-3" alt="warning">
                <p class="fs-16">{{successMessage}}</p>
                <button type="button" (click)="closeSuccessModal()" class="success-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>

<div [innerHTML]="trustedHtml"></div>
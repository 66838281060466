<section>
    <div class="wrapper">
        <app-header [screenNameValue]="ScreenName"></app-header>

        <main class="main-content p-4" id="maintoggledrop" #maintoggledrop>
            <div class="text-md-end mb-3 mb-lg-4">

            </div>
            <section class="main_content">
                <!-- <form [formGroup]="accessForm"> -->
                <div class="template" id="addtemplate">
                    <div class=" bg-white px-4 py-3 rounded mb-2 mb-md-4">
                        <div class="row">
                            <div class="col-2">
                                <label for="exampleFormControlInput1" class="form-label mb-1">Group Name
                                </label>
                            </div>
                            <div class="col-4 col-md-2 col-lg-3">
                                <div class="mb-2 mb-md-3 mb-lg-4">
                                    <!-- <ng-select formControlName="GroupName" [clearable]="false" [items]="groupName"
                                            class="" bindLabel="name" bindValue="id" placeholder="Select"
                                            [(ngModel)]="groupName" name="carss" (change)="selectValue($event)">
                                        </ng-select> -->
                                    <ng-select [clearable]="false" [items]="groupName" class="" bindLabel="name"
                                        bindValue="id" placeholder="Select" [(ngModel)]="groupName" name="carss"
                                        (change)="selectValue($event)">
                                    </ng-select>
                                </div>
                            </div>

                        </div>

                        <div class="row" *ngIf="isSelected">
                            <div class="col-2">
                                <label for="exampleFormControlInput1" class="form-label mb-1">Features
                                </label>
                            </div>
                            <div class="col-4 col-md-2 col-lg-3">
                                <!-- <div class="mb-2 mb-md-3 mb-lg-4 mt-2" formArrayName="Feature">
                                        <div class="mb-2" *ngFor="let category of checkBoxName; let i = index">
                                            <ng-container [formGroupName]="i">
                                                <p-checkbox formControlName="isActive" name="group2" value="category"
                                                    [value]="category" [(ngModel)]="selectedCategories"
                                                    [inputId]="category.isChecked"
                                                    (onChange)="featureChecked($event,category(i))">
                                                </p-checkbox>
                                                <label class="ms-2">{{ category.name }}</label>
                                            </ng-container>
                                        </div>
                                    </div> -->
                                <div class="mb-2 mb-md-3 mb-lg-4 mt-2">
                                    <div class="mb-2" *ngFor="let category of checkBoxName; let i = index">
                                        <p-checkbox name="group2" value="category" [value]="category"
                                            [(ngModel)]="selectedCategories" [inputId]="category.isChecked"
                                            (onChange)="featureChecked($event,category(i))">
                                        </p-checkbox>
                                        <label class="ms-2">{{ category.name }}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 ">
                            <div class="mt-3 mt-md-4 text-md-end">
                                <button type="button" class="btn p-btn-outline me-3"
                                    data-bs-dismiss="modal">Clear</button>
                                <button (click)="accessrightsSubmit()" type="button" class="btn p-btn">Submit</button>
                            </div>
                        </div>

                    </div>

                </div>
                <div class="mt-3 mt-lg-4 d-flex flex-wrap justify-content-lg-end gap-3">

                </div>
                <!-- </form> -->
            </section>

            <!-- Logout Modal -->
            <div class="modal" tabindex="-1" id="logOutModal">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title fw-semibold highlight">Confirm Logout</h5>
                            <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <p class="s-text fw-medium mb-0">Are you Sure want to Logout</p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn p-btn-outline" data-bs-dismiss="modal">No</button>
                            <button type="button" class="btn p-btn ms-2">Yes</button>
                        </div>
                    </div>
                </div>
            </div>

        </main>
    </div>

    <div [innerHTML]="trustedHtml"></div>
</section>
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, from } from 'rxjs';
import { Router } from "@angular/router";
import {AuthguardServiceService} from '../services/authguard-service.service';


@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {
  constructor(private Authguardservice: AuthguardServiceService, private router: Router){

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean
  
  {
    if (!this.Authguardservice.gettoken()) {  
      this.router.navigateByUrl(""); 
       return false ;
     
  }  
  return this.Authguardservice.gettoken();  
  }
   }
  


<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />

<section class="cover-page">
    <ngx-spinner size="medium" [type]="typeSelected">
        <p style="color: white">{{percentageMessage}}</p>
    </ngx-spinner>
    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-md-7 left p-0">
                <div class="bg-cover d-flex flex-column justify-content-md-center p-2 p-md-4">
                    <div class="text-center mt-0 mt-md-auto">
                        <img class="img-fluid" src="assets/images/logo-1.png" alt="Logo-1">
                    </div>
                    <footer class="mt-auto">
                        <p class="text-white fs-12 m-0">Copyright
                            2023 Citrin cooperman. All Rights
                            Reserved.
                        </p>
                    </footer>
                </div>
            </div>
            <!-- <div class="col-md-5 right p-0">
                    <div class="h-100 d-flex justify-content-center align-items-center">
                        <div class="text-center">
                            <div class="mb-4">
                                <img src="assets/images/logo-2.png" alt="Logo">
                            </div>
                        </div>
                    </div>
                </div> -->
        </div>
    </div>
</section>
<section>
    <div class="wrapper">
        <app-header [screenNameValue]="ScreenName" (addClassEvent)="addClassInDashboard($event)"></app-header>
        <main class="main-content p-4" id="maintoggledrop" #maintoggledrop>
            <div class="text-md-end mb-3 mb-lg-4">
                <button type="button" class="btn p-btn" data-bs-toggle="modal" data-bs-target="#add-fields"
                    (click)="createTemplate()">Add</button>
                <button #delete type=" button " class="btn p-btn ms-2 ms-lg-3 disabled"
                    (click)="openDelete()">Delete</button>
            </div>
            <ngx-spinner size="medium" [type]="typeSelected">
            </ngx-spinner>
            <!-- data table -->
            <div class=" bg-white px-4 py-3 rounded">
                <h5 class="highlight mb-lg-3 fs-18 fw-semibold">List of Templates</h5>
                <!--data table -->
                <div class="table-responsive">

                    <p-table #dt [rowHover]="true" [value]="templateListForGrid" dataKey="id"
                        styleClass="p-datatable-gridlines" [resizableColumns]="true" sortMode="multiple"
                        [scrollable]="true" scrollHeight="1000px" [paginator]="true" [rows]="10"
                        [showCurrentPageReport]="true" [paginator]="true"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">

                        <ng-template pTemplate="colgroup">
                            <colgroup>
                                <col style="width:4%">
                                <col style="width:5%">
                                <col style="width:19%">
                                <col style="width:19%">
                                <col style="width:16%">
                                <col style="width:15%">
                                <col style="width:12%">
                                <col style="width:9%">

                            </colgroup>
                        </ng-template>


                        <ng-template pTemplate="header">
                            <tr>
                                <th></th>
                                <th pResizableColumn pSortableColumn="TemplateId">ID <p-sortIcon field="TemplateId">
                                    </p-sortIcon><br>
                                </th>
                                <th pResizableColumn pSortableColumn="DepartmentName">Department <p-sortIcon
                                        field="DepartmentName"></p-sortIcon>
                                </th>
                                <th pResizableColumn pSortableColumn="EngageTypeName">Type <p-sortIcon
                                        field="EngageTypeName">
                                    </p-sortIcon>
                                </th>
                                <th pResizableColumn pSortableColumn="TemplateName">Template Name <p-sortIcon
                                        field="TemplateName">
                                    </p-sortIcon>
                                </th>

                                <th pResizableColumn pSortableColumn="AttachmentCount">Attachment Count<p-sortIcon
                                        field="AttachmentCount"></p-sortIcon>
                                </th>

                                <th pResizableColumn pSortableColumn="StatusName">Status <p-sortIcon field="StatusName">
                                    </p-sortIcon>
                                </th>

                                <th pResizableColumn><span>Action</span></th>
                            </tr>
                            <tr>
                                <th class="text-center"> <input type="checkbox" (change)="checkAllCheckBox($event)"
                                        [checked]="isAllCheckBoxChecked()"
                                        (change)="onChangecheck($event.target.checked)" /></th>
                                <th> <input pInputText type="text"
                                        (input)="dt.filter($event.target.value, 'TemplateId', 'contains')"
                                        class="p-column-filter"></th>
                                <th> <input pInputText type="text"
                                        (input)="dt.filter($event.target.value, 'DepartmentName', 'contains')"
                                        class="p-column-filter"></th>
                                <th> <input pInputText type="text"
                                        (input)="dt.filter($event.target.value, 'EngageTypeName', 'contains')"
                                        class="p-column-filter"></th>
                                <th> <input pInputText type="text"
                                        (input)="dt.filter($event.target.value, 'TemplateName', 'contains')"
                                        class="p-column-filter"></th>
                                <th> <input pInputText type="text"
                                        (input)="dt.filter($event.target.value, 'AttachmentCount', 'contains')"
                                        class="p-column-filter"></th>
                                <th> <input pInputText type="text"
                                        (input)="dt.filter($event.target.value, 'StatusName', 'contains')"
                                        class="p-column-filter"></th>
                                <th></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-template>
                            <tr>
                                <!-- <td class="text-center"> -->
                                <!-- <input class="form-check-input" type="checkbox" value="template.TemplateId"
                                        id="flexCheckDefault" (change)="template.checked = !template.checked"
                                        [(ngModel)]="template.checked" (click)="Iddeletebtncheck(template.Id)"
                                        (change)="onChangedCheckBox(template.TemplateId,template.checked)"> -->
                                <td class="text-center"> <input type="checkbox" value="{{template.Id}}"
                                        [(ngModel)]="template.checked" (click)="Iddeletebtncheck(template.Id)"
                                        (change)="onChangedCheckBox(template.TemplateId,template.checked)">
                                </td>
                                <td>{{template.TemplateId}}</td>
                                <td>{{template.DepartmentName}}</td>
                                <td>{{template.EngageTypeName}}</td>
                                <td>{{template.TemplateName}}</td>
                                <td style="width:10%">{{template.AttachmentCount}}</td>
                                <td>{{template.StatusName}}</td>


                                <td class="icon_image"><button class="bg-transparent mx-1 link-color" pTooltip="Edit"
                                        data-bs-toggle="modal" data-bs-target="#edit-field"
                                        (click)="editTemplate(template.TemplateId)"><img class="me-2"
                                            src="./assets/images/edit.png" alt="Edit-icon"></button>
                                    <a (click)="editTemplate(template.TemplateId)" for="upload-btn"
                                        class="link-color uploadLink text-decoration-none">
                                        Edit
                                    </a>
                                </td>
                            </tr>

                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="12" style="text-align: center;">No
                                    templates Found</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>

            <!-- Logout Modal -->
            <div class="modal" tabindex="-1" id="logOutModal">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title fw-semibold highlight">Confirm Logout</h5>
                            <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <p class="s-text fw-medium mb-0">Are you Sure want to Logout</p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn p-btn-outline" data-bs-dismiss="modal">No</button>
                            <button type="button" class="btn p-btn ms-2">Yes</button>
                        </div>
                    </div>
                </div>
            </div>

        </main>

    </div>
    <div [innerHTML]="trustedHtml"></div>
</section>

<div class="modal" tabindex="-1" id="backLogModal" [ngStyle]="{'display':displayDelete}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title fw-semibold highlight"> Delete</h5>
                <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"
                    (click)="onCloseDelete()"></button>
            </div>
            <div class="modal-body">
                <p class="s-text fw-medium mb-0">Are you sure want to delete the Template(s)?</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn p-btn-outline" data-bs-dismiss="modal"
                    (click)="onCloseDelete()">No</button>
                <button type="button" class="btn p-btn ms-2" (click)="onDeletetemplate()">Yes</button>
            </div>
        </div>
    </div>
</div>

<!-- Success Modal -->
<div class="modal" tabindex="-1" id="success" [ngStyle]="{'display':displaySuccessModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header success-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Success</h5>
                <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"
                    (click)="closeSuccessModal()"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/success-icon.png" class="mb-3" alt="warning">
                <p class="fs-16">{{successMessage}}</p>
                <button type="button" class="success-btn" data-bs-dismiss="modal"
                    (click)="closeSuccessModal()">Ok</button>
            </div>
        </div>
    </div>
</div>

<!-- warning Modal -->
<div class="modal" tabindex="-1" id="warning" [ngStyle]="{'display':displayWarningModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header warning-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Warning</h5>
                <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"
                    (click)="closeWarningModal()"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/warning.png" class="mb-3" alt="warning">
                <p class="fs-16">{{warningMessage}}</p>
                <button type="button" class="warning-btn" data-bs-dismiss="modal"
                    (click)="closeWarningModal()">Ok</button>
            </div>
        </div>
    </div>
</div>

<!-- Error Modal -->
<div class="modal" tabindex="-1" id="error" [ngStyle]="{'display':displayErrorModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header error-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Error</h5>
                <button type="button" (click)="closeErrorModal()" class="btn-close shadow-none" data-bs-dismiss="modal"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/error-icon.png" class="mb-3" alt="warning">
                <p class="fs-16">{{errorMessage}}</p>
                <button type="button" (click)="closeErrorModal()" class="error-btn" data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>

<!-- Error Modal -->
<div class="modal" tabindex="-1" id="error" [ngStyle]="{'display':displayErrorModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header error-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Error</h5>
                <button type="button" (click)="closeErrorModal()" class="btn-close shadow-none" data-bs-dismiss="modal"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/error-icon.png" class="mb-3" alt="warning">
                <p class="fs-16">{{errorMessage}}</p>
                <button type="button" (click)="closeErrorModal()" class="error-btn" data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>
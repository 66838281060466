
export class TwoClientSignModel {
    BatchId: number;
    BulkLettersId: number;
    ClientId?: number;
    PartnerId?: number;
    PartnerName?: string;
    ClientName?: string;
    OfficeId?: number;
    Office?: string;
    SignatoryEmailId?: string;
    SignatoryFirstName?: string;
    SignatoryLastName?: string;
    SignatoryTitle?: string;
    SpouseLastName?: string;
    SpouseFirstName?: string;
    SpouseEmailId?: string;
    IsEsigning: boolean = true;
    TaxYear?: string;
    OfficePhoneNumber?: string;
    OfficeZipCode?: string;
    OfficeState?: string;
    OfficeCity?: string;
    OfficeAddress?: string;
    Date?: string;
    Address?: string;
    City?: string;
    State?: string;
    Zip?: string;
    Jurisdiction?: string;
}